import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, Observable, of } from 'rxjs';
import { take, map, shareReplay, finalize, catchError, switchMap, tap } from 'rxjs/operators';
import { NumberService } from 'src/app/services/number.service';
import { VilaMessageService } from 'src/app/services/vila-message.service';
import { RouteParameter } from 'src/app/statics/route-parameter';
import { ApiError } from 'src/web-api/models/api-error.model';
import { BudgetCostsItemGroupLineDto } from 'src/web-api/models/budget/budget-costs-item-group-line-dto.model';
import { BudgetCostsItemLineDto } from 'src/web-api/models/budget/budget-costs-item-line-dto.model';
import { BudgetCostsViewDto } from 'src/web-api/models/budget/budget-costs-view-dto.model';
import { ItemDto } from 'src/web-api/models/item-dto.model';
import { YearAmountAndNumberDto } from 'src/web-api/models/year-amount-and-number-dto.model';
import { BudgetCostsItemGroupLineService } from 'src/web-api/services/budget-costs-item-group-line.service';
import { BudgetCostsItemLineService } from 'src/web-api/services/budget-costs-item-line.service';
import { BudgetCostsViewService } from 'src/web-api/services/budget-costs-view.service';
import { ItemService } from 'src/web-api/services/item.service';
import { YearAmountAndNumberService } from 'src/web-api/services/year-amount-and-number.service';

@Component({
  selector: 'vila-budget-costs-view',
  templateUrl: './budget-costs-view.component.html',
  styleUrls: ['./budget-costs-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetCostsViewComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _budgetCostsItemGroupLineService: BudgetCostsItemGroupLineService,
    private readonly _budgetCostsItemLineService: BudgetCostsItemLineService,
    private readonly _budgetCostsViewService: BudgetCostsViewService,
    private readonly _confirmationService: ConfirmationService,
    private readonly _itemService: ItemService,
    private readonly _numberService: NumberService,
    private readonly _router: Router,
    private readonly _vilaMessageService: VilaMessageService,
    private readonly _yearAmountAndNumberService: YearAmountAndNumberService
  ) { }
  //#endregion

  //#region Public properties
  @ViewChild('budgetCostsHolder') public budgetCostsHolder: ElementRef;

  public budgetCostsView$: Observable<BudgetCostsViewDto>;
  public editingYear: number;
  public isEditing: boolean;
  public isEditingYear: boolean;
  public isLoading: boolean;
  public isSubmitting: boolean;
  public items: ItemDto[];
  public quotationID$: Observable<string>;
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.isLoading = true;

    this.quotationID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.QUOTATION_ID)))
      .pipe(shareReplay(1));

    const integrationGroupID$: Observable<string> = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.INTEGRATION_GROUP_ID)))
      .pipe(shareReplay(1));

    this.budgetCostsView$ =
      forkJoin([this.quotationID$, integrationGroupID$])
        .pipe(switchMap(result => this._budgetCostsViewService.getByQuotationAndIntegrationGroup$(result[0], result[1])))
        .pipe(tap(budgetCostsView => this._setCostsItemLineNames(budgetCostsView.budgetCostsItemGroupLines)))
        .pipe(tap(budgetCostsView => this._sortCostsItemGroupsAndLines(budgetCostsView.budgetCostsItemGroupLines)))
        .pipe(finalize(() => this.isLoading = false))
        .pipe(catchError((apiError: ApiError) => {
          this._vilaMessageService
            .showError(apiError.message ?? $localize`:@@ToastBodyLoadingCostsError:ToastBodyLoadingCostsError text is missing`);

          return of(undefined);
        }))
        .pipe(shareReplay(1));

    integrationGroupID$
      .pipe(switchMap(integrationGroupID => this._itemService.findByIntegrationGroup$(integrationGroupID)))
      .pipe(tap(items => this.items = items))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingItemsError:ToastBodyLoadingItemsError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }
  //#endregion

  //#region General methods
  public disableAddBudgetCostsItemLine(): boolean {
    return this.isSubmitting || this.items == null || this.items.length === 0;
  }

  public getFormattedNumber(number: number): string {
    return this._numberService.getFormattedNumber(number);
  }

  public getTotalAmount(budgetCostsItemGroupLines: BudgetCostsItemGroupLineDto[]): number {
    return budgetCostsItemGroupLines
      .reduce((accumulator, budgetCostsItemGroupLine) => accumulator + this._getBudgetCostsItemGroupLineAmount(budgetCostsItemGroupLine), 0);
  }

  public getTotalNumberOfColumns(years: number[]): number {
    const numberOfStartColumns: number = 2;
    const numberOfColumnsPerYear: number = 2;
    const numberOfEndColumns: number = 3;

    return numberOfStartColumns + numberOfColumnsPerYear * years.length + numberOfEndColumns;
  }

  public onAddBudgetCostsItemLine(budgetCostsView: BudgetCostsViewDto): void {
    const newBudgetCostsItemLine: BudgetCostsItemLineDto = this._budgetCostsItemLineService.create(budgetCostsView.years);

    newBudgetCostsItemLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => yearAmountAndNumber.original = { ...yearAmountAndNumber });
    newBudgetCostsItemLine.original = { ...newBudgetCostsItemLine };
    newBudgetCostsItemLine.isEditing = true;

    const dummyBudgetCostsItemGroupLine: BudgetCostsItemGroupLineDto = this._budgetCostsItemGroupLineService.create(undefined);

    dummyBudgetCostsItemGroupLine.budgetCostsItemLines = [newBudgetCostsItemLine];

    budgetCostsView.budgetCostsItemGroupLines.push(dummyBudgetCostsItemGroupLine);

    // Go to the bottom of the table.
    const tableScrollableBody: Element = this.budgetCostsHolder.nativeElement.getElementsByClassName("p-datatable-scrollable-body")[0];

    setTimeout(() => tableScrollableBody.scrollTop = tableScrollableBody.scrollHeight);
  }

  public onReturnToBudget(): void {
    this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
  }
  //#endregion

  //#region Year methods
  public disableYearEditInitButton(budgetCostsView: BudgetCostsViewDto): boolean {
    // The year can only be edited when a single year is shown and no data has been entered.
    return this.isEditing
      || budgetCostsView.years.length > 1
      || budgetCostsView.budgetCostsItemGroupLines.length > 0;
  }

  public getYearTotalAmount(budgetCostsItemGroupLines: BudgetCostsItemGroupLineDto[], yearIndex: number): number {
    return budgetCostsItemGroupLines
      .reduce((accumulator, budgetCostsItemGroupLine) => accumulator +
        this._getYearTotalAmount(budgetCostsItemGroupLine.budgetCostsItemLines, yearIndex), 0);
  }

  public onAddYear(budgetCostsView: BudgetCostsViewDto): void {
    const newYear: number = budgetCostsView.years[budgetCostsView.years.length - 1] + 1;

    budgetCostsView.years.push(newYear);

    budgetCostsView.budgetCostsItemGroupLines
      .forEach(budgetCostsItemGroupLine => budgetCostsItemGroupLine.budgetCostsItemLines
        .forEach(budgetCostsItemLine => {
          const newYearAmountAndNumber: YearAmountAndNumberDto =
            this._yearAmountAndNumberService.create(newYear, budgetCostsItemGroupLine.id ?? budgetCostsItemLine.itemID);

          if (budgetCostsItemLine.isEditing) {
            newYearAmountAndNumber.original = { ...newYearAmountAndNumber };
          }

          budgetCostsItemLine.yearAmountAndNumbers.push(newYearAmountAndNumber);
        }));
  }

  public onYearEditConfirm(budgetCostsView: BudgetCostsViewDto): void {
    // The year can only be edited when a single year is shown, so only the first entry of the respective arrays have to be edited.
    budgetCostsView.years[0] = this.editingYear;

    budgetCostsView.budgetCostsItemGroupLines
      .forEach(budgetCostsItemGroupLine => budgetCostsItemGroupLine.budgetCostsItemLines
        .forEach(budgetCostsItemLine => budgetCostsItemLine.yearAmountAndNumbers[0].year = this.editingYear));

    this.isEditingYear = false;
  }

  public onYearEditInit(budgetCostsView: BudgetCostsViewDto): void {
    this.editingYear = budgetCostsView.years[0];

    this.isEditingYear = true;
  }

  public showYearEditConfirmButton(budgetCostsView: BudgetCostsViewDto): boolean {
    return this.isEditingYear && budgetCostsView.years.length === 1;
  }
  //#endregion

  //#endregion Budget costs item line methods
  public disableRowEditConfirmButton(
    budgetCostsItemLine: BudgetCostsItemLineDto,
    budgetCostsItemLines: BudgetCostsItemLineDto[],
    budgetCostsItemGroupLines: BudgetCostsItemGroupLineDto[]
  ): boolean {
    return this.isSubmitting
      || !this._isChanged(budgetCostsItemLine)
      || !this._hasUniqueName(budgetCostsItemLine, budgetCostsItemLines, budgetCostsItemGroupLines)
      || this._isNew(budgetCostsItemLine)
      && !this._areBaseValuesFilled(budgetCostsItemLine);
  }

  public getBudgetCostsItemLineAmount(budgetCostsItemLine: BudgetCostsItemLineDto): number {
    return budgetCostsItemLine.yearAmountAndNumbers
      .reduce((accumulator, yearAmountAndNumber) => accumulator + yearAmountAndNumber.amount, 0);
  }

  public isEditingNewBudgetCostsItemLine(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return this._isNew(budgetCostsItemLine) && budgetCostsItemLine.isEditing;
  }

  public onItemChanged(item: ItemDto, budgetCostsItemLine: BudgetCostsItemLineDto): void {
    budgetCostsItemLine.itemID = item ? item.id : undefined;
    budgetCostsItemLine.name = item ? item.name : undefined;
  }

  public onRowEditCancel(budgetCostsItemLine: BudgetCostsItemLineDto, budgetCostsItemLines: BudgetCostsItemLineDto[]): void {
    if (this._isNew(budgetCostsItemLine)) {
      this._removeFromArray(budgetCostsItemLine, budgetCostsItemLines);
    } else {
      this._resetFromOriginals(budgetCostsItemLine);

      budgetCostsItemLine.isEditing = false;
    }

    this.isEditing = false;
  }

  public onRowEditConfirm(
    budgetCostsItemLine: BudgetCostsItemLineDto,
    quotationID: string,
    budgetCostsItemGroupLines: BudgetCostsItemGroupLineDto[],
    budgetCostsItemGroupLine: BudgetCostsItemGroupLineDto
  ): void {
    this.isSubmitting = true;
    budgetCostsItemLine.isSubmitting = true;

    const yearAmountAndNumbersToUpsert: YearAmountAndNumberDto[] = budgetCostsItemLine.yearAmountAndNumbers;

    const isAddingNewBudgetCostsItemLine: boolean = this._isNew(budgetCostsItemLine);

    yearAmountAndNumbersToUpsert.forEach(yearAmountAndNumber => {
      yearAmountAndNumber.name = budgetCostsItemLine.name;

      if (isAddingNewBudgetCostsItemLine) {
        yearAmountAndNumber.itemID = budgetCostsItemLine.itemID;
      }
    });

    this._yearAmountAndNumberService.upsert$(budgetCostsItemLine.yearAmountAndNumbers, quotationID)
      .pipe(tap(upsertedYearAmountAndNumbers => {
        if (isAddingNewBudgetCostsItemLine) {
          const existingBudgetCostsItemGroupLine: BudgetCostsItemGroupLineDto =
            budgetCostsItemGroupLines.find(budgetCostsItemGroupLine => budgetCostsItemGroupLine.id === budgetCostsItemLine.itemID);

          if (existingBudgetCostsItemGroupLine != null) {
            existingBudgetCostsItemGroupLine.budgetCostsItemLines.push(budgetCostsItemLine);
          } else {
            const newBudgetCostsItemGroupLine: BudgetCostsItemGroupLineDto =
              this._budgetCostsItemGroupLineService.create(budgetCostsItemLine.itemID);

            newBudgetCostsItemGroupLine.budgetCostsItemLines = [budgetCostsItemLine];

            budgetCostsItemGroupLines.push(newBudgetCostsItemGroupLine);
          }

          this._removeFromArray(budgetCostsItemGroupLine, budgetCostsItemGroupLines);
        }

        upsertedYearAmountAndNumbers = upsertedYearAmountAndNumbers
          .sort((yearAmountAndNumberA, yearAmountAndNumberB) => yearAmountAndNumberA.year - yearAmountAndNumberB.year);

        yearAmountAndNumbersToUpsert.forEach((yearAmountAndNumber, index) => yearAmountAndNumber.id = upsertedYearAmountAndNumbers[index].id);

        this._initialiseOriginals(budgetCostsItemLine);

        this.onRowEditCancel(budgetCostsItemLine, budgetCostsItemGroupLine.budgetCostsItemLines);
      }))
      .pipe(finalize(() => {
        this.isSubmitting = false;
        budgetCostsItemLine.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        let errorMessage: string;

        if (apiError.message != null) {
          errorMessage = apiError.message;
        } else {
          errorMessage = isAddingNewBudgetCostsItemLine
            ? $localize`:@@ToastBodyAddingBudgetCostsItemLineError:ToastBodyAddingBudgetCostsItemLineError text is missing`
            : $localize`:@@ToastBodyUpdatingBudgetCostsItemLineError:ToastBodyUpdatingBudgetCostsItemLineError text is missing`;
        }

        this._vilaMessageService.showError(errorMessage);

        return of(undefined);
      }))
      .subscribe();;
  }

  public onRowEditDelete(budgetCostsItemLine: BudgetCostsItemLineDto, budgetCostsItemLines: BudgetCostsItemLineDto[], quotationID: string): void {
    this._confirmationService.confirm({
      header: $localize`:@@ConfirmHeaderDeleteData:ConfirmHeaderDeleteData text is missing`,
      message: $localize`:@@ConfirmBodyDeleteBudgetCostsItemLine:ConfirmBodyDeleteBudgetCostsItemLine text is missing`,
      accept: () => {
        this.isSubmitting = true;
        budgetCostsItemLine.isSubmitting = true;

        const yearAmountAndNumberIDsToDelete: string[] = budgetCostsItemLine.yearAmountAndNumbers
          .filter(yearAmountAndNumber => yearAmountAndNumber.id != null)
          .map(yearAmountAndNumber => yearAmountAndNumber.id);

        this._yearAmountAndNumberService.delete$(yearAmountAndNumberIDsToDelete, quotationID)
          .pipe(tap(() => this._removeFromArray(budgetCostsItemLine, budgetCostsItemLines)))
          .pipe(finalize(() => {
            this.isSubmitting = false;
            budgetCostsItemLine.isSubmitting = false;
          }))
          .pipe(catchError((apiError: ApiError) => {
            this._vilaMessageService
              .showError(apiError.message ?? $localize`:@@ToastBodyDeletingBudgetCostsItemLineError:ToastBodyDeletingBudgetCostsItemLineError text is missing`);

            return of(undefined);
          }))
          .subscribe();
      }
    });
  }

  public onRowEditInit(budgetCostsItemLine: BudgetCostsItemLineDto): void {
    this.isEditing = true;
    budgetCostsItemLine.isEditing = true;

    this._initialiseOriginals(budgetCostsItemLine);
  }

  public showRowEditCancelButton(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return budgetCostsItemLine.isEditing;
  }

  public showRowEditConfirmButton(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return budgetCostsItemLine.isEditing && !budgetCostsItemLine.isSubmitting;
  }

  public showRowEditDeleteButton(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return !budgetCostsItemLine.isEditing && !budgetCostsItemLine.isSubmitting;
  }

  public showRowEditInitButton(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return !budgetCostsItemLine.isEditing;
  }

  public showRowEditSpinnerButton(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return budgetCostsItemLine.isSubmitting;
  }
  //#endregion

  //#region Year amount and number methods
  public canOpenRemarks(yearAmountAndNumber: YearAmountAndNumberDto, budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return budgetCostsItemLine.isEditing || (yearAmountAndNumber.remarks && yearAmountAndNumber.remarks.length > 0);
  }

  public onAmountInputChanged(yearAmountAndNumber: YearAmountAndNumberDto, number: number): void {
    yearAmountAndNumber.amount = number;
  }
  //#endregion

  //#region Private methods
  private _areBaseValuesFilled(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return budgetCostsItemLine.itemID != null
      && budgetCostsItemLine.yearAmountAndNumbers.some(yearAmountAndNumber => yearAmountAndNumber.amount > 0);
  }

  private _getBudgetCostsItemGroupLineAmount(budgetCostsItemGroupLine: BudgetCostsItemGroupLineDto): number {
    return budgetCostsItemGroupLine.budgetCostsItemLines
      .reduce((accumulator, budgetCostsItemLine) => accumulator + this.getBudgetCostsItemLineAmount(budgetCostsItemLine), 0);
  }

  private _hasUniqueName(
    budgetCostsItemLineToCheck: BudgetCostsItemLineDto,
    budgetCostsItemLines: BudgetCostsItemLineDto[],
    budgetCostsItemGroupLines: BudgetCostsItemGroupLineDto[]
  ): boolean {
    let result: boolean;

    if (this._isNew(budgetCostsItemLineToCheck)) {
      result = !budgetCostsItemGroupLines
        .find(budgetCostsItemGroupLine => budgetCostsItemGroupLine.id === budgetCostsItemLineToCheck.itemID)?.budgetCostsItemLines
        .some(budgetCostsItemLine => budgetCostsItemLine.name === budgetCostsItemLineToCheck.name);
    } else {
      result = budgetCostsItemLines
        .every(budgetCostsItemLine => budgetCostsItemLine === budgetCostsItemLineToCheck
          || budgetCostsItemLine.name !== budgetCostsItemLineToCheck.name);
    }

    return result;
  }

  private _initialiseOriginals(budgetCostsItemLine: BudgetCostsItemLineDto): void {
    budgetCostsItemLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => yearAmountAndNumber.original = { ...yearAmountAndNumber });

    budgetCostsItemLine.original = { ...budgetCostsItemLine };
  }

  private _resetFromOriginals(budgetCostsItemLine: BudgetCostsItemLineDto): void {
    budgetCostsItemLine.name = budgetCostsItemLine.original.name;

    budgetCostsItemLine.original = undefined;

    budgetCostsItemLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => {
      yearAmountAndNumber.amount = yearAmountAndNumber.original.amount;
      yearAmountAndNumber.remarks = yearAmountAndNumber.original.remarks;

      yearAmountAndNumber.original = undefined;
    });
  }

  private _isChanged(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return budgetCostsItemLine.name != budgetCostsItemLine.original.name
      || budgetCostsItemLine.yearAmountAndNumbers
        .some(yearAmountAndNumber => yearAmountAndNumber.remarks != yearAmountAndNumber.original.remarks
          || yearAmountAndNumber.amount !== yearAmountAndNumber.original.amount);
  }

  private _isNew(budgetCostsItemLine: BudgetCostsItemLineDto): boolean {
    return budgetCostsItemLine.yearAmountAndNumbers.every(yearAmountAndNumber => yearAmountAndNumber.id == null);
  }

  private _sortCostsItemGroupsAndLines(costsItemGroups: BudgetCostsItemGroupLineDto[]): void {
    costsItemGroups
      .forEach(costsItemGroup => costsItemGroup.budgetCostsItemLines = costsItemGroup.budgetCostsItemLines
        .sort((costsItemA, costsItemB) => costsItemA.name.localeCompare(costsItemB.name)));

    costsItemGroups = costsItemGroups.sort((costsItemGroupA, costsItemGroupB) => costsItemGroupA.id.localeCompare(costsItemGroupB.id));
  }

  private _setCostsItemLineNames(costsItemGroups: BudgetCostsItemGroupLineDto[]): void {
    costsItemGroups
      .forEach(costsItemGroup => costsItemGroup.budgetCostsItemLines
        .forEach(budgetCostsItemLine => {
          const firstYearAmountAndNumber = budgetCostsItemLine.yearAmountAndNumbers[0];

          if (firstYearAmountAndNumber != null) {
            budgetCostsItemLine.name = firstYearAmountAndNumber.name
          }
        }));
  }

  private _getYearTotalAmount(budgetCostsItemLines: BudgetCostsItemLineDto[], yearIndex: number): number {
    return budgetCostsItemLines
      .reduce((accumulator, budgetCostsItemLine) => accumulator + budgetCostsItemLine.yearAmountAndNumbers[yearIndex].amount, 0);
  }

  private _removeFromArray(itemToRemove: object, arrayToRemoveItemFrom: object[]): void {
    const indexToRemove: number = arrayToRemoveItemFrom.findIndex(item => item === itemToRemove);

    arrayToRemoveItemFrom.splice(indexToRemove, 1);
  }
  //#endregion
}
