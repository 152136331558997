import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { ApplicationInsightsDto } from '../models/application-insights-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class ApplicationInsightsService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _applicationInsightsControllerUrl: string = "/api/ApplicationInsights";

  private _applicationInsights: ApplicationInsightsDto;
  //#endregion

  //#region Public methods
  public get(): ApplicationInsightsDto {
    return this._applicationInsights;
  }

  public fillInstance(): Promise<void> {
    const result$: Observable<void> = this._httpClient.get<ApplicationInsightsDto>(this._applicationInsightsControllerUrl)
      .pipe(tap(applicationInsights => this._applicationInsights = applicationInsights))
      .pipe(switchMap(() => of<void>()));

    return super.catchErrorResponse(result$).toPromise<void>();
  }
  //#endregion
}
