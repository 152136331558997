import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, Observable, of } from 'rxjs';
import { take, switchMap, finalize, shareReplay, catchError, tap, map } from 'rxjs/operators';
import { ApiError } from 'src/web-api/models/api-error.model';
import { BudgetEmployeeLineDto } from 'src/web-api/models/budget/budget-employee-line-dto.model';
import { EmployeeDto } from 'src/web-api/models/employee-dto.model';
import { BudgetPersonnelViewDto } from 'src/web-api/models/budget/budget-personnel-view-dto.model';
import { YearAmountAndNumberDto } from 'src/web-api/models/year-amount-and-number-dto.model';
import { BudgetEmployeeLineService } from 'src/web-api/services/budget-employee-line.service';
import { EmployeeService } from 'src/web-api/services/employee.service';
import { BudgetPersonnelViewService } from 'src/web-api/services/budget-personnel-view.service';
import { YearAmountAndNumberService } from 'src/web-api/services/year-amount-and-number.service';
import { NumberService } from '../../services/number.service';
import { VilaMessageService } from '../../services/vila-message.service';
import { RouteParameter } from '../../statics/route-parameter';
import { EmployeeLineService } from '../../../web-api/services/employee-line.service';

@Component({
  selector: 'vila-budget-personnel-view',
  templateUrl: './budget-personnel-view.component.html',
  styleUrls: ['./budget-personnel-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BudgetPersonnelViewComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _confirmationService: ConfirmationService,
    private readonly _budgetEmployeeLineService: BudgetEmployeeLineService,
    private readonly _employeeService: EmployeeService,
    private readonly _employeeLineService: EmployeeLineService,
    private readonly _numberService: NumberService,
    private readonly _personnelService: BudgetPersonnelViewService,
    private readonly _router: Router,
    private readonly _vilaMessageService: VilaMessageService,
    private readonly _yearAmountAndNumberService: YearAmountAndNumberService
  ) { }
  //#endregion

  //#region Private properties
  private _employees: EmployeeDto[] = [];
  //#endregion

  //#region Public properties
  @ViewChild('budgetPersonnelHolder') public budgetPersonnelHolder: ElementRef;

  public budgetPersonnelView$: Observable<BudgetPersonnelViewDto>;
  public editingYear: number;
  public isEditing: boolean;
  public isEditingYear: boolean;
  public isLoading: boolean;
  public isSubmitting: boolean;
  public quotationID$: Observable<string>;
  public unusedEmployees: EmployeeDto[] = [];
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.isLoading = true;

    this.quotationID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.QUOTATION_ID)))
      .pipe(shareReplay(1));

    this.budgetPersonnelView$ = this.quotationID$
      .pipe(switchMap(quotationID => this._personnelService.getByQuotation$(quotationID)))
      .pipe(tap(budgetPersonnelView => budgetPersonnelView.budgetEmployeeLines = this._getSortedEmployees(budgetPersonnelView.budgetEmployeeLines)))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingPersonnelError:ToastBodyLoadingPersonnelError text is missing`);

        return of(undefined);
      }))
      .pipe(shareReplay(1));

    const employees$: Observable<EmployeeDto[]> = this._employeeService.list$()
      .pipe(tap(employees => this._employees = employees))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingEmployeesError:ToastBodyLoadingEmployeesError text is missing`);

        return of(undefined);
      }));

    forkJoin([employees$, this.budgetPersonnelView$])
      .subscribe(result => {
        const employees: EmployeeDto[] = result[0];
        const personnel: BudgetPersonnelViewDto = result[1];

        if (employees != null && personnel != null) {
          this.unusedEmployees = this._getUnusedEmployees(employees, personnel.budgetEmployeeLines);
        }
      });
  }
  //#endregion

  //#region General methods
  public getBudgetEmployeeLineName(budgetEmployeeLine: BudgetEmployeeLineDto): string {
    return this._employeeLineService.getEmployeeLineName(budgetEmployeeLine);
  }

  public getFormattedNumber(number: number): string {
    return this._numberService.getFormattedNumber(number);
  }

  public getTotalAmount(budgetEmployeeLines: BudgetEmployeeLineDto[]): number {
    return budgetEmployeeLines
      .reduce((accumulator, budgetEmployeeLine) => accumulator + this.getBudgetEmployeeLineAmount(budgetEmployeeLine), 0);
  }

  public getTotalNumberOfColumns(years: number[]): number {
    const numberOfStartColumns: number = 1;
    const numberOfColumnsPerYear: number = 2;
    const numberOfEndColumns: number = 3;

    return numberOfStartColumns + numberOfColumnsPerYear * years.length + numberOfEndColumns;
  }

  public onInputChanged(yearAmountAndNumber: YearAmountAndNumberDto, inputValue: number): void {
    yearAmountAndNumber.number = inputValue;
  }

  public onReturnToBudget(): void {
    this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }
  //#endregion

  //#region Year methods
  public disableYearEditInitButton(personnel: BudgetPersonnelViewDto): boolean {
    // The year can only be edited when a single year is shown and no data has been entered.
    return this.isEditing
      || personnel.years.length > 1
      || personnel.budgetEmployeeLines.length > 0;
  }

  public getYearTotalAmount(budgetEmployeeLines: BudgetEmployeeLineDto[], yearIndex: number): number {
    return budgetEmployeeLines
      .reduce((accumulator, budgetEmployeeLine) => accumulator + budgetEmployeeLine.yearAmountAndNumbers[yearIndex].amount, 0);
  }

  public getYearTotalNumber(budgetEmployeeLines: BudgetEmployeeLineDto[], yearIndex: number): number {
    return budgetEmployeeLines
      .reduce((accumulator, budgetEmployeeLine) => accumulator + budgetEmployeeLine.yearAmountAndNumbers[yearIndex].number, 0);
  }

  public onAddYear(personnel: BudgetPersonnelViewDto): void {
    const newYear: number = personnel.years[personnel.years.length - 1] + 1;

    personnel.years.push(newYear);

    personnel.budgetEmployeeLines
      .forEach(budgetEmployeeLine => {
        const budgetEmployeeLineYearAmountAndNumbers: YearAmountAndNumberDto = this._yearAmountAndNumberService.create(newYear, budgetEmployeeLine.defaultItemID);

        budgetEmployeeLineYearAmountAndNumbers.employeeID = budgetEmployeeLine.id;
        budgetEmployeeLineYearAmountAndNumbers.rateGroupID = budgetEmployeeLine.rateGroupID;

        if (budgetEmployeeLine.isEditing) {
          budgetEmployeeLineYearAmountAndNumbers.original = { ...budgetEmployeeLineYearAmountAndNumbers };
        }

        budgetEmployeeLine.yearAmountAndNumbers.push(budgetEmployeeLineYearAmountAndNumbers);
      });
  }

  public onYearEditConfirm(personnel: BudgetPersonnelViewDto): void {
    // The year can only be edited when a single year is shown, so only the first entry of the respective arrays have to be edited.
    personnel.years[0] = this.editingYear;

    personnel.budgetEmployeeLines
      .forEach(budgetEmployeeLine => budgetEmployeeLine.yearAmountAndNumbers[0].year = this.editingYear);

    this.isEditingYear = false;
  }

  public onYearEditInit(personnel: BudgetPersonnelViewDto): void {
    this.editingYear = personnel.years[0];

    this.isEditingYear = true;
  }

  public showYearEditConfirmButton(personnel: BudgetPersonnelViewDto): boolean {
    return this.isEditingYear && personnel.years.length === 1;
  }
  //#endregion

  //#region Employee budget methods
  public disableRowEditDeleteButton(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return this.isSubmitting
      || budgetEmployeeLine.yearAmountAndNumbers
        .find(yearAmountAndNumber => yearAmountAndNumber.number > 0) == null;
  }

  public disableRowEditConfirmButton(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return this.isSubmitting
      || budgetEmployeeLine.id == null
      || !this._hasChangedYearAmountAndNumbers(budgetEmployeeLine);
  }

  public getBudgetEmployeeLineAmount(budgetEmployeeLine: BudgetEmployeeLineDto): number {
    return budgetEmployeeLine.yearAmountAndNumbers
      .reduce((accumulator, yearAmountAndNumber) => accumulator + yearAmountAndNumber.amount, 0);
  }

  public isEditingNewBudgetEmployeeLine(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return budgetEmployeeLine.isEditing && this._isNewBudgetEmployeeLine(budgetEmployeeLine);
  }

  public onAddBudgetEmployeeLine(personnel: BudgetPersonnelViewDto): void {
    const newBudgetEmployeeLine: BudgetEmployeeLineDto = this._budgetEmployeeLineService.create(personnel.years);

    newBudgetEmployeeLine.isEditing = true;

    personnel.budgetEmployeeLines.push(newBudgetEmployeeLine);

    // Go to the bottom of the table.
    const tableScrollableBody: Element = this.budgetPersonnelHolder.nativeElement.getElementsByClassName("p-datatable-scrollable-body")[0];

    setTimeout(() => tableScrollableBody.scrollTop = tableScrollableBody.scrollHeight);
  }

  public onEmployeeChanged(employee: EmployeeDto, budgetEmployeeLine: BudgetEmployeeLineDto): void {
    budgetEmployeeLine.id = employee.id;
    budgetEmployeeLine.name = employee.name;
    budgetEmployeeLine.rateGroupID = employee.rateGroupID;
    budgetEmployeeLine.defaultItemID = employee.defaultItemID;

    budgetEmployeeLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => {
      yearAmountAndNumber.employeeID = employee.id;
      yearAmountAndNumber.rateGroupID = employee.rateGroupID;
      yearAmountAndNumber.itemID = employee.defaultItemID;
    });
  }

  public onRowEditCancel(budgetEmployeeLine: BudgetEmployeeLineDto, budgetEmployeeLines: BudgetEmployeeLineDto[]): void {
    if (this._isNewBudgetEmployeeLine(budgetEmployeeLine)) {
      this._removeFromArray(budgetEmployeeLine, budgetEmployeeLines);
    } else {
      budgetEmployeeLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => {
        yearAmountAndNumber.number = yearAmountAndNumber.original ? yearAmountAndNumber.original.number : 0;

        yearAmountAndNumber.original = undefined;
      });

      budgetEmployeeLine.isEditing = false;
    }

    this.isEditing = false;
  }

  public onRowEditDelete(budgetEmployeeLine: BudgetEmployeeLineDto, personnel: BudgetPersonnelViewDto, quotationID: string): void {
    this._confirmationService.confirm({
      header: $localize`:@@ConfirmHeaderDeleteData:ConfirmHeaderDeleteData text is missing`,
      message: $localize`:@@ConfirmBodyDeleteBudgetEmployeeLine:ConfirmBodyDeleteBudgetEmployeeLine text is missing`,
      accept: () => {
        this.isSubmitting = true;

        budgetEmployeeLine.isSubmitting = true;

        const yearAmountAndNumberIDsToDelete: string[] = budgetEmployeeLine.yearAmountAndNumbers
          .filter(yearAmountAndNumber => yearAmountAndNumber.id != null)
          .map(yearAmountAndNumber => yearAmountAndNumber.id)

        this._yearAmountAndNumberService
          .delete$(yearAmountAndNumberIDsToDelete, quotationID)
          .pipe(tap(() => {
            this._removeFromArray(budgetEmployeeLine, personnel.budgetEmployeeLines);

            this.unusedEmployees = this._getUnusedEmployees(this._employees, personnel.budgetEmployeeLines);
          }))
          .pipe(finalize(() => {
            this.isSubmitting = false;

            budgetEmployeeLine.isSubmitting = false;
          }))
          .pipe(catchError((apiError: ApiError) => {
            this._vilaMessageService
              .showError(apiError.message ?? $localize`:@@ToastBodyDeletingBudgetEmployeeLineError:ToastBodyDeletingBudgetEmployeeLineError text is missing`);

            return of(undefined);
          }))
          .subscribe();
      }
    });
  }

  public onRowEditConfirm(budgetEmployeeLine: BudgetEmployeeLineDto, personnel: BudgetPersonnelViewDto, quotationID: string): void {
    this.isSubmitting = true;

    budgetEmployeeLine.isSubmitting = true;

    this._upsertYearAmountAndNumbers(budgetEmployeeLine, personnel, quotationID);
  }

  public onRowEditInit(budgetEmployeeLine: BudgetEmployeeLineDto): void {
    this.isEditing = true;

    budgetEmployeeLine.isEditing = true;

    budgetEmployeeLine.yearAmountAndNumbers
      .forEach(yearAmountAndNumber => yearAmountAndNumber.original = { ...yearAmountAndNumber });
  }

  public showRowEditCancelButton(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return budgetEmployeeLine.isEditing;
  }

  public showRowEditDeleteButton(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return !budgetEmployeeLine.isEditing && !budgetEmployeeLine.isSubmitting;
  }

  public showRowEditConfirmButton(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return budgetEmployeeLine.isEditing && !budgetEmployeeLine.isSubmitting;
  }

  public showRowEditInitButton(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return !budgetEmployeeLine.isEditing;
  }

  public showRowEditSpinnerButton(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return budgetEmployeeLine.isSubmitting;
  }
  //#endregion

  //#region Private methods
  private _upsertYearAmountAndNumbers(budgetEmployeeLine: BudgetEmployeeLineDto, personnel: BudgetPersonnelViewDto, quotationID: string): void {
    const yearAmountAndNumbersToUpsert: YearAmountAndNumberDto[] = this._getChangedYearAmountAndNumbers(budgetEmployeeLine);

    const employeeID: string = budgetEmployeeLine.id;

    const isAddingNewEmployeeLine: boolean = this._isNewBudgetEmployeeLine(budgetEmployeeLine);

    this._yearAmountAndNumberService
      .upsert$(yearAmountAndNumbersToUpsert, quotationID)
      .pipe(tap(upsertedYearAmountAndNumbers => {
        upsertedYearAmountAndNumbers = upsertedYearAmountAndNumbers
          .sort((yearAmountAndNumberA, yearAmountAndNumberB) => yearAmountAndNumberA.year - yearAmountAndNumberB.year);

        yearAmountAndNumbersToUpsert.forEach((yearAmountAndNumber, index) => {
          yearAmountAndNumber.id = upsertedYearAmountAndNumbers[index].id;

          yearAmountAndNumber.amount = upsertedYearAmountAndNumbers[index].amount;
        });

        budgetEmployeeLine.isEditing = false;

        this.unusedEmployees = this._getUnusedEmployees(this._employees, personnel.budgetEmployeeLines);

        personnel.budgetEmployeeLines.forEach(budgetEmployeeLine => {
          if (this._isNewBudgetEmployeeLine(budgetEmployeeLine) && budgetEmployeeLine.id === employeeID) {
            budgetEmployeeLine.name = undefined;
            budgetEmployeeLine.id = undefined;
            budgetEmployeeLine.rateGroupID = undefined;

            budgetEmployeeLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => {
              yearAmountAndNumber.employeeID = undefined;
              yearAmountAndNumber.rateGroupID = undefined;
              yearAmountAndNumber.itemID = undefined;
            });
          }
        });

        this.isEditing = false;
      }))
      .pipe(finalize(() => {
        this.isSubmitting = false;

        budgetEmployeeLine.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        let errorMessage: string;

        if (apiError.message != null) {
          errorMessage = apiError.message;
        } else {
          errorMessage = isAddingNewEmployeeLine
            ? $localize`:@@ToastBodyAddingBudgetEmployeeLineError:ToastBodyAddingBudgetEmployeeLineError text is missing`
            : $localize`:@@ToastBodyUpdatingBudgetEmployeeLineError:ToastBodyUpdatingBudgetEmployeeLineError text is missing`;
        }

        this._vilaMessageService.showError(errorMessage);

        return of(undefined);
      }))
      .subscribe();
  }

  private _getSortedEmployees(employees: BudgetEmployeeLineDto[]): BudgetEmployeeLineDto[] {
    return employees.sort((employeeA, employeeB) => employeeA.name.localeCompare(employeeB.name));
  }

  private _getUnusedEmployees(employees: EmployeeDto[], budgetEmployeeLines: BudgetEmployeeLineDto[]): EmployeeDto[] {
    const existingEmployeeIDs: string[] = budgetEmployeeLines
      .filter(budgetEmployeeLine => !this._isNewBudgetEmployeeLine(budgetEmployeeLine))
      .map(budgetEmployeeLine => budgetEmployeeLine.id);

    return employees
      .filter(employee => !existingEmployeeIDs.includes(employee.id))
      .sort((employeeA, employeeB) => employeeA.name.localeCompare(employeeB.name));
  }

  private _isNewBudgetEmployeeLine(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return budgetEmployeeLine.yearAmountAndNumbers.every(yearAmountAndNumber => yearAmountAndNumber.id == null);
  }

  private _hasChangedYearAmountAndNumbers(budgetEmployeeLine: BudgetEmployeeLineDto): boolean {
    return this._getChangedYearAmountAndNumbers(budgetEmployeeLine).length > 0;
  }

  private _getChangedYearAmountAndNumbers(budgetEmployeeLine: BudgetEmployeeLineDto): YearAmountAndNumberDto[] {
    return budgetEmployeeLine.yearAmountAndNumbers
      .filter(yearAmountAndNumber => yearAmountAndNumber.number !== (yearAmountAndNumber.original?.number ?? 0));
  }

  private _removeFromArray(itemToRemove: object, arrayToRemoveItemFrom: object[]): void {
    const indexToRemove: number = arrayToRemoveItemFrom.findIndex(item => item === itemToRemove);

    arrayToRemoveItemFrom.splice(indexToRemove, 1);
  }
  //#endregion
}
