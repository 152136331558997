<div class="p-grid p-dir-col" *ngIf="(quotationID$ | async) as quotationID">
  <div class="p-col">
    <vila-project-header #vilaProjectHeader [quotationID]="quotationID" displayYearsNavigation="true"
                         [disableYearsNavigation]="isEditing || isSubmitting" (onYearChanged)="onYearChanged($event)">
    </vila-project-header>
  </div>

  <div class="p-col" #prognosisPersonnelHolder>
    <vila-loader i18n-message="@@MessageLoadingPersonnel" message="MessageLoadingPersonnel text is missing"
                 *ngIf="isLoading">
    </vila-loader>

    <p-table *ngIf="(prognosisEmployeeLines$ | async) as prognosisEmployeeLines" [value]="prognosisEmployeeLines"
             dataKey="name" [scrollable]="true" scrollHeight="35em">
      <ng-template pTemplate="header">
        <tr class="fixed-width-setter">
          <th class="icon-button-holder single-icon"></th>
          <th class="row-name-holder"></th>
          <th class="number-holder"></th>
          <th class="amount-holder"></th>
          <th class="icon-button-holder single-icon"></th>
          <th class="number-holder"></th>
          <th class="amount-holder"></th>
          <th class="number-holder"></th>
          <th class="amount-holder"></th>
          <th class="icon-button-holder single-icon"></th>
          <th class="icon-button-holder"></th>
        </tr>
        <tr>
          <th colspan="2"></th>
          <th colspan="2" i18n="@@HeaderBudget">HeaderBudget text is missing</th>
          <th></th>
          <th colspan="2" i18n="@@HeaderRealisationUntil">HeaderRealisationUntil text is missing</th>
          <th colspan="2" i18n="@@HeaderPrognosis">HeaderPrognosis text is missing</th>
          <th colspan="2"></th>
        </tr>
        <tr>
          <th colspan="2"></th>
          <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th></th>
          <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th colspan="2"></th>
        </tr>
        <tr class="divider"></tr>
        <tr class="sub-header">
          <th colspan="2" i18n="@@HeaderPersonnel">HeaderPersonnel text is missing</th>
          <th class="number-holder">{{ getFormattedNumber(getBudgetTotalNumber(prognosisEmployeeLines)) }}</th>
          <th class="amount-holder">{{ getFormattedNumber(getBudgetTotalAmount(prognosisEmployeeLines)) }}</th>
          <th></th>
          <th class="number-holder">{{ getFormattedNumber(getRealisationTotalNumber(prognosisEmployeeLines)) }}</th>
          <th class="amount-holder">{{ getFormattedNumber(getRealisationTotalAmount(prognosisEmployeeLines)) }}</th>
          <th class="number-holder">{{ getFormattedNumber(getPrognosisTotalNumber(prognosisEmployeeLines)) }}</th>
          <th class="amount-holder">{{ getFormattedNumber(getPrognosisTotalAmount(prognosisEmployeeLines)) }}</th>
          <th colspan="2"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-prognosisEmployeeLine let-expanded="expanded">
        <tr class="sub-section">
          <td class="icon-button-holder single-icon">
            <p-button type="button" [pRowToggler]="prognosisEmployeeLine"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"
                      [disabled]="disableExpandToggle(prognosisEmployeeLine)"
                      (onClick)="onExpand(prognosisEmployeeLine, prognosisEmployeeLines)">
            </p-button>
          </td>
          <td [ngClass]="{'input-cell' : prognosisEmployeeLine.isEditing, 'blocked' : prognosisEmployeeLine.employeeIsBlocked }"
              class="row-name-holder">
            <ng-container *ngIf="!prognosisEmployeeLine.isEditing">
              {{ getPrognosisEmployeeLineName(prognosisEmployeeLine) }}
            </ng-container>
            <p-dropdown *ngIf="prognosisEmployeeLine.isEditing" [options]="unusedEmployees" optionLabel="name"
                        (onChange)="onEmployeeChanged($event.value, prognosisEmployeeLine)" [disabled]="isSubmitting"
                        [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
          </td>
          <td class="number-holder">{{ getFormattedNumber(getTotalNumber(prognosisEmployeeLine.budgets)) }}</td>
          <td class="amount-holder">{{ getFormattedNumber(getTotalAmount(prognosisEmployeeLine.budgets)) }}</td>
          <td class="icon-button-holder single-icon"></td>
          <td class="number-holder">{{ getFormattedNumber(getTotalNumber(prognosisEmployeeLine.realisations)) }}</td>
          <td class="amount-holder">{{ getFormattedNumber(getTotalAmount(prognosisEmployeeLine.realisations)) }}</td>
          <td class="number-holder">{{ getFormattedNumber(getTotalNumber(prognosisEmployeeLine.prognosises)) }}</td>
          <td class="amount-holder">{{ getFormattedNumber(getTotalAmount(prognosisEmployeeLine.prognosises)) }}</td>
          <td class="icon-button-holder single-icon"></td>
          <td class="icon-button-holder"></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="rowexpansion" let-prognosisEmployeeLine>
        <tr class="sub-section" *ngFor="let quarterIndex of [0, 1, 2, 3]">
          <td></td>
          <td class="quarter-line-name-holder">
            {{ getQuarterName(prognosisEmployeeLine.budgets[quarterIndex]) }}
          </td>
          <td class="number-holder">
            {{ getFormattedNumber(prognosisEmployeeLine.budgets[quarterIndex].number) }}
          </td>
          <td class="amount-holder">
            {{ getFormattedNumber(prognosisEmployeeLine.budgets[quarterIndex].amount) }}
          </td>
          <td class="icon-button-holder single-icon">
            <p-button icon="pi pi-info" type="button" (onClick)="overlayPanelBudget.toggle($event)"
                      [disabled]="!canOpenRemarks(prognosisEmployeeLine.budgets[quarterIndex])"></p-button>
            <p-overlayPanel #overlayPanelBudget appendTo="body">
              {{ prognosisEmployeeLine.budgets[quarterIndex].remarks }}
            </p-overlayPanel>
          </td>
          <td class="number-holder">
            {{ getFormattedNumber(prognosisEmployeeLine.realisations[quarterIndex].number) }}
          </td>
          <td class="amount-holder">
            {{ getFormattedNumber(prognosisEmployeeLine.realisations[quarterIndex].amount) }}
          </td>
          <td class="number-holder"
              [ngClass]="{'input-cell' : prognosisEmployeeLine.prognosises[quarterIndex].isEditing}">
            <p-inputNumber *ngIf="prognosisEmployeeLine.prognosises[quarterIndex].isEditing" [disabled]="isSubmitting"
                           [(ngModel)]="prognosisEmployeeLine.prognosises[quarterIndex].number" locale="nl-NL"
                           (onInput)="onNumberInputChanged(prognosisEmployeeLine.prognosises[quarterIndex], $event.value)"
                           mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="2" [step]="10" [min]="0">
            </p-inputNumber>
            <ng-container *ngIf="!prognosisEmployeeLine.prognosises[quarterIndex].isEditing">
              {{ getFormattedNumber(prognosisEmployeeLine.prognosises[quarterIndex].number) }}
            </ng-container>
          </td>
          <td class="amount-holder">
            {{ getFormattedNumber(prognosisEmployeeLine.prognosises[quarterIndex].amount) }}
          </td>
          <td class="icon-button-holder single-icon">
            <p-button icon="pi pi-info" type="button" (onClick)="overlayPanelPrognosis.toggle($event)"
                      [disabled]="!canOpenRemarks(prognosisEmployeeLine.prognosises[quarterIndex])"></p-button>
            <p-overlayPanel #overlayPanelPrognosis appendTo="body">
              <textarea *ngIf="prognosisEmployeeLine.prognosises[quarterIndex].isEditing" pInputTextarea type="text"
                        [(ngModel)]="prognosisEmployeeLine.prognosises[quarterIndex].remarks" maxlength="255"></textarea>
              <ng-container *ngIf="!prognosisEmployeeLine.prognosises[quarterIndex].isEditing">
                {{ prognosisEmployeeLine.prognosises[quarterIndex].remarks }}
              </ng-container>
            </p-overlayPanel>
          </td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button"
                      *ngIf="showRowEditInitButton(prognosisEmployeeLine.prognosises[quarterIndex])"
                      (onClick)="onRowEditInit(prognosisEmployeeLine.prognosises[quarterIndex])" [disabled]="isSubmitting">
            </p-button>
            <p-button icon="pi pi-check" type="button"
                      *ngIf="showRowEditConfirmButton(prognosisEmployeeLine.prognosises[quarterIndex])"
                      (onClick)="onRowEditConfirm(prognosisEmployeeLine.prognosises[quarterIndex], quotationID)"
                      [disabled]="disableRowEditConfirmButton(prognosisEmployeeLine.prognosises[quarterIndex])">
            </p-button>
            <p-button icon="pi pi-spin pi-spinner"
                      *ngIf="showRowEditSpinnerButton(prognosisEmployeeLine.prognosises[quarterIndex])" class="no-pointer">
            </p-button>
            <p-button icon="pi pi-trash" type="button"
                      *ngIf="showRowEditClearButton(prognosisEmployeeLine.prognosises[quarterIndex])"
                      (onClick)="onRowEditClear(prognosisEmployeeLine.prognosises[quarterIndex], quotationID)"
                      [disabled]="disableRowEditClearButton(prognosisEmployeeLine.prognosises[quarterIndex])">
            </p-button>
            <p-button icon="pi pi-times" type="button"
                      *ngIf="showRowEditCancelButton(prognosisEmployeeLine.prognosises[quarterIndex])"
                      (onClick)="onRowEditCancel(prognosisEmployeeLine.prognosises[quarterIndex])" [disabled]="isSubmitting">
            </p-button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr class="sub-section">
          <td colspan="10"></td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-plus" type="button" (onClick)="onAddPrognosisEmployeeLine(prognosisEmployeeLines)"
                      [disabled]="isSubmitting || unusedEmployees.length === 0"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col">
    <div class="p-d-inline-flex">
      <p-button type="button" (onClick)="onReturnToPrognosis()" [disabled]="isSubmitting || isEditing"
                i18n-label="@@ButtonReturn" label="ButtonReturn text is missing">
      </p-button>
    </div>
  </div>
</div>
