import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RouteSegment } from 'src/app/statics/route-segment';

@Component({
  selector: 'vila-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent implements OnInit {
  //#region Constructor
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router
  ) { }
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this._activatedRoute.queryParamMap
      .subscribe(queryParamMap => {
        const quotationID: string = queryParamMap.get("QuId") || queryParamMap.get("quid");

        const projectID: string = queryParamMap.get("PrId") || queryParamMap.get("prid");

        const referrer: string = queryParamMap.get("referrer");

        const forecastID: string = queryParamMap.get("FoId") || queryParamMap.get("foid");

        if (quotationID != null) {
          this._router.navigate([`${RouteSegment.BUDGET}/${quotationID}`]);
        } else if (projectID != null) {
          this._router.navigate([`${RouteSegment.PROJECT}/${projectID}`]);
        } else if (referrer === "portal-insite-prs/medewerkers"){
          this._router.navigate([RouteSegment.DECLARABILITY])
        } else if (forecastID != null) {
          this._router.navigate([`${RouteSegment.CREATE_BUDGET_BY_FORECAST}/${forecastID}`])
        }
      });
  }
  //#endregion
}
