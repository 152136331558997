import { Injectable } from '@angular/core';
import { BudgetCostsItemLineDto } from '../models/budget/budget-costs-item-line-dto.model';
import { YearAmountAndNumberService } from './year-amount-and-number.service';

@Injectable({ providedIn: 'root' })
export class BudgetCostsItemLineService {
  //#region Constructor
  constructor(
    private readonly _yearAmountAndNumberService: YearAmountAndNumberService
  ) { }
  //#endregion

  //#region Public methods
  public create(years: number[]): BudgetCostsItemLineDto {
    return {
      itemID: undefined,
      name: undefined,
      yearAmountAndNumbers: years.map(year => this._yearAmountAndNumberService.create(year, undefined)),
      original: undefined,
      isEditing: undefined,
      isSubmitting: undefined
    };
  }
  //#endregion
}
