import { Injectable } from '@angular/core';
import { BudgetCostsItemGroupLineDto } from '../models/budget/budget-costs-item-group-line-dto.model';

@Injectable({ providedIn: 'root' })
export class BudgetCostsItemGroupLineService {
  //#region Constructor
  constructor() { }
  //#endregion

  //#region Public methods
  public create(id: string): BudgetCostsItemGroupLineDto {
    return {
      name: undefined,
      id: id,
      budgetCostsItemLines: []
    };
  }
  //#endregion
}
