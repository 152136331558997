import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { ButtonModule } from 'primeng/button';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService } from 'primeng/dynamicdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputMaskModule } from 'primeng/inputmask';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ListboxModule } from 'primeng/listbox';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApplicationInsightsErrorHandler } from '../error-handlers/application-insights.error-handler';
import { ApplicationInsightsService } from '../web-api/services/application-insights.service';
import { BudgetPersonnelViewComponent } from './budget-view/budget-personnel-view/budget-personnel-view.component';
import { BudgetViewComponent } from './budget-view/budget-view.component';
import { CreateBudgetByForecastComponent } from './create-budget-by-forecast/create-budget-by-forecast.component';
import { DeclarabilityViewComponent } from './declarability-view/declarability-view.component';
import { LoadButtonComponent } from './shared-components/load-button/load-button.component';
import { LoaderComponent } from './shared-components/loader/loader.component';
import { PrognosisCostsViewComponent } from './prognosis-view/prognosis-costs-view/prognosis-costs-view.component';
import { PrognosisPersonnelViewComponent } from './prognosis-view/prognosis-personnel-view/prognosis-personnel-view.component';
import { PrognosisViewComponent } from './prognosis-view/prognosis-view.component';
import { ProjectHeaderComponent } from './shared-components/project-header/project-header.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { RedirectComponent } from './shared-components/redirect/redirect.component';
import { XcessAboutBoxComponent } from './shared-components/xcess-about-box/xcess-about-box.component';
import { ProgressDialogComponent } from './shared-components/progress-dialog/progress-dialog.component';
import { DepartmentHeaderComponent } from './declarability-view/department-header/department-header.component';
import { BudgetCostsViewComponent } from './budget-view/budget-costs-view/budget-costs-view.component';

export function initialiseApp(applicationInsightsService: ApplicationInsightsService) {
  return () => applicationInsightsService.fillInstance();
}

@NgModule({
  declarations: [
    AppComponent,
    BudgetPersonnelViewComponent,
    BudgetViewComponent,
    CreateBudgetByForecastComponent,
    DepartmentHeaderComponent,
    DeclarabilityViewComponent,
    LoadButtonComponent,
    LoaderComponent,
    ProgressDialogComponent,
    PrognosisCostsViewComponent,
    PrognosisPersonnelViewComponent,
    PrognosisViewComponent,
    ProjectHeaderComponent,
    ProjectViewComponent,
    RedirectComponent,
    XcessAboutBoxComponent,
    BudgetCostsViewComponent
  ],
  bootstrap: [AppComponent],
  imports: [BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ButtonModule,
    ConfirmDialogModule,
    DropdownModule,
    DynamicDialogModule,
    InputMaskModule,
    InputNumberModule,
    InputTextModule,
    InputTextareaModule,
    ListboxModule,
    ProgressSpinnerModule,
    OverlayPanelModule,
    TableModule,
    ToastModule,
    TooltipModule,
    AppRoutingModule
  ],
  providers: [
    ConfirmationService,
    DialogService,
    MessageService,
    ApplicationInsightsService,
    { provide: APP_INITIALIZER, useFactory: initialiseApp, deps: [ApplicationInsightsService], multi: true },
    { provide: ErrorHandler, useClass: ApplicationInsightsErrorHandler, deps: [APP_INITIALIZER] },
    provideHttpClient(withInterceptorsFromDi())
  ]
})
export class AppModule { }
