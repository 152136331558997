import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetPersonnelViewDto } from '../models/budget/budget-personnel-view-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class BudgetPersonnelViewService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient,
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _budgetPersonnelViewControllerUrl: string = "/api/BudgetPersonnelView";
  //#endregion

  //#region Public methods
  public getByQuotation$(quotationID: string): Observable<BudgetPersonnelViewDto> {
    return super.catchErrorResponse(this._httpClient.get<BudgetPersonnelViewDto>(`${this._budgetPersonnelViewControllerUrl}/quotation/${quotationID}`));
  }
  //#endregion
}
