import { Component, Input } from '@angular/core';

@Component({
  selector: 'xcess-about-box',
  templateUrl: './xcess-about-box.component.html',
  styleUrls: ['./xcess-about-box.component.scss']
})
export class XcessAboutBoxComponent {
  //#region Constructor
  constructor() { }
  //#endregion

  //#region Public properties
  @Input() public tagLine: string;
  @Input() public versionLabel: string;
  @Input() public versionString: string;
  @Input() public copyrightYear: number;
  @Input() public userLicense: string;

  public isOpen: boolean;
  //#endregion

  //#region Public methods
  public toggleAboutBox(): void {
    this.isOpen = !this.isOpen;
  }
  //#endregion
}
