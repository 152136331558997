import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'vila-load-button',
  templateUrl: './load-button.component.html',
  styleUrls: ['./load-button.component.scss']
})
export class LoadButtonComponent {
  @Input() public disabled: boolean;
  @Input() public isLoading: boolean;
  @Input() public label: string;

  @Output() public onClick: EventEmitter<void> = new EventEmitter<void>();

  public onClicked(): void {
    this.onClick.emit(undefined);
  }
}
