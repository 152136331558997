import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ProgressDto } from '../models/progress-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class ProgressService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _progressControllerUrl: string = "/api/Progress";
  //#endregion

  //#region Public methods
  public create(year: number): ProgressDto {
    return {
      id: undefined,
      name: undefined,
      year: year,
      withinBudgetProgressTypeID: undefined,
      withinBudgetRemarks: undefined,
      withinTimeProgressTypeID: undefined,
      withinTimeRemarks: undefined,
      progressProgressTypeID: undefined,
      progressRemarks: undefined
    }
  }

  public getByQuotation$(quotationID: string, year: number): Observable<ProgressDto> {
    const url: string = `${this._progressControllerUrl}/quotation/${quotationID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.get<ProgressDto>(url));
  }

  public upsert$(progress: ProgressDto, quotationID: string): Observable<void[]> {
    const url: string = `${this._progressControllerUrl}/quotation/${quotationID}`;

    return super.catchErrorResponse(this._httpClient.post<void[]>(url, progress));
  }
  //#endregion
}
