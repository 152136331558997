import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class VilaMessageService {
  constructor(private readonly messageService: MessageService) { }

  public showError(message: string) {
    this.messageService.add({
      severity: 'error',
      summary: $localize`:@@ToastHeaderError:ToastHeaderError text is missing`,
      detail: message
    });
  }
}