import { Injectable } from '@angular/core';
import { EmployeeLineBaseDto } from '../models/base/employee-line-base-dto.model';

@Injectable({ providedIn: 'root' })
export class EmployeeLineService {
  //#region Constructor
  constructor() { }
  //#endregion

  //#region Private properties
  private _employeeLineNameIsBlockedText: string = $localize`:@@EmployeeLineNameIsBlocked:EmployeeLineNameIsBlocked text is missing`;
  //#endregion

  //#region Public methods
  public getEmployeeLineName(employeeLine: EmployeeLineBaseDto): string {
    return employeeLine.employeeIsBlocked
      ? `${employeeLine.name} (${this._employeeLineNameIsBlockedText})`
      : employeeLine.name;
  }
  //#endregion
}
