import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BudgetCostsViewComponent } from './budget-view/budget-costs-view/budget-costs-view.component';
import { BudgetPersonnelViewComponent } from './budget-view/budget-personnel-view/budget-personnel-view.component';
import { BudgetViewComponent } from './budget-view/budget-view.component';
import { CreateBudgetByForecastComponent } from './create-budget-by-forecast/create-budget-by-forecast.component';
import { DeclarabilityViewComponent } from './declarability-view/declarability-view.component';
import { PrognosisCostsViewComponent } from './prognosis-view/prognosis-costs-view/prognosis-costs-view.component';
import { PrognosisPersonnelViewComponent } from './prognosis-view/prognosis-personnel-view/prognosis-personnel-view.component';
import { PrognosisViewComponent } from './prognosis-view/prognosis-view.component';
import { ProjectViewComponent } from './project-view/project-view.component';
import { RedirectComponent } from './shared-components/redirect/redirect.component';
import { RouteParameter } from './statics/route-parameter';
import { RouteSegment } from './statics/route-segment';

const budgetRoute: string = `${RouteSegment.BUDGET}/:${RouteParameter.QUOTATION_ID}`;
const createBudgetByForecastRoute: string = `${RouteSegment.CREATE_BUDGET_BY_FORECAST}/:${RouteParameter.FORECAST_ID}`;
const costsRoute: string = `${RouteSegment.COSTS}/:${RouteParameter.INTEGRATION_GROUP_ID}`;
const declarabilityRoute: string = `${RouteSegment.DECLARABILITY}`;
const prognosisRoute: string = `${RouteSegment.PROGNOSIS}/:${RouteParameter.QUOTATION_ID}`;
const projectRoute: string = `${RouteSegment.PROJECT}/:${RouteParameter.PROJECT_ID}`;

const routes: Routes = [
  { path: '', component: RedirectComponent },
  { path: declarabilityRoute, component: DeclarabilityViewComponent },
  { path: createBudgetByForecastRoute, component: CreateBudgetByForecastComponent },
  { path: projectRoute, component: ProjectViewComponent },
  { path: `${projectRoute}/${budgetRoute}`, component: BudgetViewComponent },
  { path: `${projectRoute}/${budgetRoute}/${RouteSegment.PERSONNEL}`, component: BudgetPersonnelViewComponent },
  { path: `${projectRoute}/${budgetRoute}/${costsRoute}`, component: BudgetCostsViewComponent },
  { path: `${projectRoute}/${prognosisRoute}`, component: PrognosisViewComponent },
  { path: `${projectRoute}/${prognosisRoute}/${RouteSegment.PERSONNEL}`, component: PrognosisPersonnelViewComponent },
  { path: `${projectRoute}/${prognosisRoute}/${costsRoute}`, component: PrognosisCostsViewComponent },
  { path: budgetRoute, component: BudgetViewComponent },
  { path: `${budgetRoute}/${RouteSegment.PERSONNEL}`, component: BudgetPersonnelViewComponent },
  { path: `${budgetRoute}/${costsRoute}`, component: BudgetCostsViewComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
