import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { DeclarabilityEmployeeLineDto } from "../models/declarability/declarability-employee-line-dto.model";
import { ApiServiceBase } from "./base/api-service-base";

@Injectable({ providedIn: 'root' })
export class DeclarabilityEmployeeLineService extends ApiServiceBase {
    //#region Constructor
    constructor(
        private readonly _httpClient: HttpClient
    ) {
        super();
    }
    //#endregion

    //#region Private properties
    private readonly _declarabilityViewControllerUrl: string = "/api/DeclarabilityEmployeeLine";
    //#endregion

    //#region Public methods
    public findActualByYearAndManager$(year: number, managerID: string): Observable<DeclarabilityEmployeeLineDto[]> {
      const url: string = `${this._declarabilityViewControllerUrl}/${managerID}/actual?year=${year}`;

      return super.catchErrorResponse(this._httpClient.get<DeclarabilityEmployeeLineDto[]>(url));
    }

    public findActualByYear$(year: number): Observable<DeclarabilityEmployeeLineDto[]> {
      const url: string = `${this._declarabilityViewControllerUrl}/actual?year=${year}`;

      return super.catchErrorResponse(this._httpClient.get<DeclarabilityEmployeeLineDto[]>(url));
    }

    public findByYearAndManager$(year: number, managerID: string): Observable<DeclarabilityEmployeeLineDto[]> {
      const url: string = `${this._declarabilityViewControllerUrl}/${managerID}/?year=${year}`;

      return super.catchErrorResponse(this._httpClient.get<DeclarabilityEmployeeLineDto[]>(url));
    }

    public findByYear$(year: number): Observable<DeclarabilityEmployeeLineDto[]> {
        const url: string = `${this._declarabilityViewControllerUrl}/?year=${year}`;

        return super.catchErrorResponse(this._httpClient.get<DeclarabilityEmployeeLineDto[]>(url));
    }

    public upsert$(declarabilityEmployeeLines: DeclarabilityEmployeeLineDto[]): Observable<void> {
        const url: string = `${this._declarabilityViewControllerUrl}`;

        return super.catchErrorResponse(this._httpClient.post<void>(url, declarabilityEmployeeLines));
    }
    //#endregion
  }