<div class="p-grid p-dir-col" *ngIf="(quotationID$ | async) as quotationID">
  <div class="p-col">
    <vila-project-header [quotationID]="quotationID"></vila-project-header>
  </div>

  <div class="p-col full-width" #budgetCostsHolder>
    <vila-loader i18n-message="@@MessageLoadingCosts" message="MessageLoadingCosts text is missing" *ngIf="isLoading">
    </vila-loader>

    <p-table *ngIf="(budgetCostsView$ | async) as budgetCostsView" [value]="budgetCostsView.budgetCostsItemGroupLines"
      [scrollable]="true" scrollHeight="35em">
      <ng-template pTemplate="header">
        <tr class="fixed-width-setter">
          <th class="item-id-holder"></th>
          <th class="row-name-holder medium-width-holder"></th>
          <th></th>
          <ng-container *ngFor="let year of budgetCostsView.years">
            <th class="amount-holder"></th>
            <th class="icon-button-holder single-icon"></th>
          </ng-container>
          <th class="icon-button-holder"></th>
          <th class="amount-holder"></th>
          <th class="icon-button-holder"></th>
        </tr>
        <tr>
          <th colspan="3"></th>
          <th *ngFor="let year of budgetCostsView.years" colspan="2" [ngClass]="{'input-cell' : isEditingYear}">
            <ng-container *ngIf="!isEditingYear">{{ year }}</ng-container>
            <p-inputNumber *ngIf="isEditingYear" [(ngModel)]="editingYear" [maxFractionDigits]="0" [useGrouping]="false"
              [min]="0"></p-inputNumber>
          </th>
          <th class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button" *ngIf="!isEditingYear"
              (onClick)="onYearEditInit(budgetCostsView)"
              [disabled]="disableYearEditInitButton(budgetCostsView)"></p-button>
            <p-button icon="pi pi-check" type="button" *ngIf="showYearEditConfirmButton(budgetCostsView)"
              (onClick)="onYearEditConfirm(budgetCostsView)"></p-button>
            <p-button icon="pi pi-plus" type="button" (onClick)="onAddYear(budgetCostsView)"
              [disabled]="isSubmitting || isEditingYear"></p-button>
          </th>
          <th i18n="@@HeaderTotal">HeaderTotal text is missing</th>
          <th class="icon-button-holder"></th>
        </tr>
        <tr>
          <th colspan="3"></th>
          <ng-container *ngFor="let year of budgetCostsView.years">
            <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
            <th></th>
          </ng-container>
          <th></th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th class="icon-button-holder"></th>
        </tr>
        <tr class="divider"></tr>
        <tr class="sub-header">
          <th colspan="2" class="name-holder medium-width-holder">{{ budgetCostsView.integrationGroupName }}</th>
          <th></th>
          <ng-container *ngFor="let year of budgetCostsView.years; let yearIndex = index">
            <th class="number-holder">
              {{ getFormattedNumber(getYearTotalAmount(budgetCostsView.budgetCostsItemGroupLines, yearIndex)) }}
            </th>
            <th></th>
          </ng-container>
          <th></th>
          <th class="amount-holder">
            {{ getFormattedNumber(getTotalAmount(budgetCostsView.budgetCostsItemGroupLines)) }}
          </th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-budgetCostsItemGroupLine>
        <tr class="fixed-width-setter">
          <td class="item-id-holder"></td>
          <td class="row-name-holder medium-width-holder"></td>
          <td></td>
          <ng-container *ngFor="let year of budgetCostsView.years">
            <td class="amount-holder"></td>
            <td class="icon-button-holder single-icon"></td>
          </ng-container>
          <td class="icon-button-holder"></td>
          <td class="amount-holder"></td>
          <td class="icon-button-holder"></td>
        </tr>
        <tr class="sub-sub-header" [ngClass]="{'dummy-row' : budgetCostsItemGroupLine.id == null}">
          <td class="item-id-holder">{{ budgetCostsItemGroupLine.id }}</td>
          <td class="row-name-holder medium-width-holder">{{ budgetCostsItemGroupLine.name }}</td>
          <td></td>
          <td [attr.colspan]="getTotalNumberOfColumns(budgetCostsView.years) - 2"></td>
        </tr>
        <tr class="sub-section" *ngFor="let budgetCostsItemLine of budgetCostsItemGroupLine.budgetCostsItemLines">
          <td [ngClass]="{'input-cell' : isEditingNewBudgetCostsItemLine(budgetCostsItemLine)}">
            <p-dropdown *ngIf="isEditingNewBudgetCostsItemLine(budgetCostsItemLine)" [options]="items" optionLabel="id"
              (onChange)="onItemChanged($event.value, budgetCostsItemLine)" [disabled]="isSubmitting"
              [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
          </td>
          <td class="row-name-holder medium-width-holder" [ngClass]="{'input-cell' : budgetCostsItemLine.isEditing}">
            <input type="text" pInputText *ngIf="budgetCostsItemLine.isEditing" [disabled]="isSubmitting"
              [(ngModel)]="budgetCostsItemLine.name" />
            <ng-container *ngIf="!budgetCostsItemLine.isEditing">{{ budgetCostsItemLine.name }}</ng-container>
          </td>
          <td></td>
          <ng-container *ngFor="let yearAmountAndNumber of budgetCostsItemLine.yearAmountAndNumbers">
            <td class="amount-holder" [ngClass]="{'input-cell' : budgetCostsItemLine.isEditing}">
              <p-inputNumber *ngIf="budgetCostsItemLine.isEditing" [disabled]="isSubmitting"
                [(ngModel)]="yearAmountAndNumber.amount" locale="nl-NL" [maxFractionDigits]="0" [step]="1000" [min]="0"
                (onInput)="onAmountInputChanged(yearAmountAndNumber, $event.value)">
              </p-inputNumber>
              <ng-container *ngIf="!budgetCostsItemLine.isEditing">{{ getFormattedNumber(yearAmountAndNumber.amount) }}
              </ng-container>
            </td>
            <td class="icon-button-holder single-icon">
              <p-button icon="pi pi-info" type="button" (onClick)="overlayPanelBudget.toggle($event)"
                [disabled]="!canOpenRemarks(yearAmountAndNumber, budgetCostsItemLine)"></p-button>
              <p-overlayPanel #overlayPanelBudget appendTo="body">
                <textarea *ngIf="budgetCostsItemLine.isEditing" pInputTextarea type="text"
                  [(ngModel)]="yearAmountAndNumber.remarks" maxlength="255" [disabled]="isSubmitting"></textarea>
                <ng-container *ngIf="!budgetCostsItemLine.isEditing">{{ yearAmountAndNumber.remarks }}</ng-container>
              </p-overlayPanel>
            </td>
          </ng-container>
          <td></td>
          <td class="amount-holder">{{ getFormattedNumber(getBudgetCostsItemLineAmount(budgetCostsItemLine)) }}</td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button" *ngIf="showRowEditInitButton(budgetCostsItemLine)"
              (onClick)="onRowEditInit(budgetCostsItemLine)" [disabled]="isSubmitting || isEditingYear"></p-button>
            <p-button icon="pi pi-check" type="button" *ngIf="showRowEditConfirmButton(budgetCostsItemLine)"
              (onClick)="onRowEditConfirm(budgetCostsItemLine, quotationID, budgetCostsView.budgetCostsItemGroupLines, budgetCostsItemGroupLine)"
              [disabled]="disableRowEditConfirmButton(budgetCostsItemLine, budgetCostsItemGroupLine.budgetCostsItemLines, budgetCostsView.budgetCostsItemGroupLines)">
            </p-button>
            <p-button icon="pi pi-spin pi-spinner" *ngIf="showRowEditSpinnerButton(budgetCostsItemLine)"
              class="no-pointer">
            </p-button>
            <p-button icon="pi pi-trash" type="button" *ngIf="showRowEditDeleteButton(budgetCostsItemLine)"
              (onClick)="onRowEditDelete(budgetCostsItemLine, budgetCostsItemGroupLine.budgetCostsItemLines, quotationID)"
              [disabled]="isSubmitting">
            </p-button>
            <p-button icon="pi pi-times" type="button" *ngIf="showRowEditCancelButton(budgetCostsItemLine)"
              (onClick)="onRowEditCancel(budgetCostsItemLine, budgetCostsItemGroupLine.budgetCostsItemLines)"
              [disabled]="isSubmitting">
            </p-button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr class="sub-section">
          <td [attr.colspan]="getTotalNumberOfColumns(budgetCostsView.years) - 1"></td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-plus" type="button" [disabled]="disableAddBudgetCostsItemLine()"
              (onClick)="onAddBudgetCostsItemLine(budgetCostsView)"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col">
    <div class="p-d-inline-flex">
      <p-button type="button" (onClick)="onReturnToBudget()" [disabled]="isSubmitting || isEditing"
                i18n-label="@@ButtonReturn" label="ButtonReturn text is missing">
      </p-button>
    </div>
  </div>
</div>
