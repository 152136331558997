import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap, tap } from 'rxjs/operators';
import { QuotationService } from 'src/web-api/services/quotation.service';
import { RouteParameter } from '../statics/route-parameter';
import { RouteSegment } from '../statics/route-segment';

@Component({
  selector: 'vila-create-budget-by-forecast',
  templateUrl: './create-budget-by-forecast.component.html',
  styleUrls: ['./create-budget-by-forecast.component.scss']
})
export class CreateBudgetByForecastComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private readonly _quotationService: QuotationService
  ) { }
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this._activatedRoute.paramMap
      .pipe(map(paramMap => paramMap.get(RouteParameter.FORECAST_ID)))
      .pipe(switchMap(forecastID => this._quotationService.createForForecast$(forecastID)
        .pipe(tap(quotationID => this._router.navigate([`../../${RouteSegment.BUDGET}/${quotationID}`], { relativeTo: this._activatedRoute })))
      ))
      .subscribe();
  }
  //#endregion
}
