import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetCostsViewDto } from '../models/budget/budget-costs-view-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class BudgetCostsViewService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient,
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _budgetCostsViewControllerUrl: string = "/api/BudgetCostsView";
  //#endregion

  //#region Public methods
  public getByQuotationAndIntegrationGroup$(quotationID: string, integrationGroupID: string): Observable<BudgetCostsViewDto> {
    return super.catchErrorResponse(this._httpClient
      .get<BudgetCostsViewDto>(`${this._budgetCostsViewControllerUrl}/quotation/${quotationID}/integrationgroup/${integrationGroupID}`));
  }
  //#endregion
}
