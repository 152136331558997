import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrognosisCostsViewDto } from '../models/prognosis/prognosis-costs-view-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class PrognosisCostsViewService extends ApiServiceBase {
  //#region Constructor
  constructor(private readonly _httpClient: HttpClient) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _prognosisCostsViewControllerUrl: string = "/api/PrognosisCostsView";
  //#endregion

  //#region Public methods
  public getByQuotationAndIntegrationGroupAndYear$(quotationID: string, year: number, integrationGroupID: string): Observable<PrognosisCostsViewDto> {
    const url: string = `${this._prognosisCostsViewControllerUrl}/quotation/${quotationID}/integrationgroup/${integrationGroupID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.get<PrognosisCostsViewDto>(url));
  }
  //#endregion
}
