import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BalanceDto } from '../models/balance-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class BalanceService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _balanceControllerUrl: string = "/api/Balance";
  //#endregion

  //#region Public methods
  public getByQuotation$(quotationID: string, year: number): Observable<BalanceDto> {
    const url: string = `${this._balanceControllerUrl}/quotation/${quotationID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.get<BalanceDto>(url));
  }
  //#endregion
}
