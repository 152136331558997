import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { catchError, finalize, shareReplay, tap } from 'rxjs/operators';
import { DropdownHelperService } from 'src/app/services/dropdown-helper.service';
import { VilaMessageService } from 'src/app/services/vila-message.service';
import { ApiError } from 'src/web-api/models/api-error.model';
import { DepartmentWithYearsDto } from 'src/web-api/models/declarability/department-with-years-dto.model';
import { ManagerDto } from 'src/web-api/models/manager-dto.model';
import { DepartmentWithYearsService } from 'src/web-api/services/department-with-years.service';
import { ManagerService } from 'src/web-api/services/manager.service';

@Component({
  selector: 'vila-department-header',
  templateUrl: './department-header.component.html',
  styleUrls: ['./department-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepartmentHeaderComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _departmentWithYearsService: DepartmentWithYearsService,
    private readonly _dropdownHelperService: DropdownHelperService,
    private readonly _managerService: ManagerService,
    private readonly _vilaMessageService: VilaMessageService
    ) { }
  //#endregion

  //#region Public properties
  @Input() public disableHeader: boolean;

  public departmentWithYears$: Observable<DepartmentWithYearsDto>;
  public selectedManager: ManagerDto;
  public selectedYear: number;
  public selectableYears: SelectItem[];
  public isDeclarabilityAdmin: boolean;
  public isLoading: boolean;
  public managers$: Observable<ManagerDto[]>;
  
  @Output() public onYearChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() public onManagerChanged: EventEmitter<string> = new EventEmitter<string>();
  //#endregion

  //#region Life cycle hooks
  ngOnInit(): void {
    this.isLoading = true;

    this.departmentWithYears$ = this._departmentWithYearsService.get$()
      .pipe(tap(departmentWithYears => {
        if (departmentWithYears.isDeclarabilityAdmin){
          this.isDeclarabilityAdmin = true;
          this._initManagers();
        } else {
          this._handleYears(departmentWithYears.years);
          this._setDefaultSelectedYear();
        }
      }))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => this._handleLoadingDepartmentWithYearsError(apiError)))
      .pipe(shareReplay(1));
  }
  //#endregion

  //#region Public Methods
  public onChangeSelectedYear(): void {
    this.onYearChanged.emit(this.selectedYear);
  }

  public onSelectedManagerChanged(): void {
    this.onManagerChanged.emit(this.selectedManager.userID);

    this.isLoading = true;

    this.departmentWithYears$ = this._departmentWithYearsService.getForManager$(this.selectedManager.userID)
      .pipe(tap(departmentWithYears => this._handleYears(departmentWithYears.years)))
      .pipe(tap(() => this._setDefaultSelectedYear()))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => this._handleLoadingDepartmentWithYearsError(apiError)))
      .pipe(shareReplay(1));
  }
  //#endregion

  //#region Private methods
  private _setDefaultSelectedYear(): void {
    this.selectedYear = new Date().getFullYear();

    this.onChangeSelectedYear();
  }

  private _initManagers(): void {
    this.managers$ = this._managerService
      .list$()
      .pipe(tap(managers => managers = managers.sort((managerA, managerB) => managerA.name?.localeCompare(managerB.name))))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingManagersError:ToastBodyLoadingManagersError text is missing`);

        return of(undefined);
      }));
  }

  private _handleLoadingDepartmentWithYearsError(apiError: ApiError): Observable<undefined> {
    this._vilaMessageService
      .showError(apiError.message ?? $localize`:@@ToastBodyLoadingDepartmentError:ToastBodyLoadingDepartmentError text is missing`);
    
    return of(undefined);
  }

  private _handleYears(years: number[]) {
    this.selectableYears = this._dropdownHelperService.getSelectItemArrayFromNumberArray(years);
  }
  //#endregion
}
