<p-toast></p-toast>

<p-confirmDialog i18n-acceptLabel="@@ButtonYes" acceptLabel="ButtonYes text is missing" i18n-rejectLabel="@@ButtonNo"
  rejectLabel="ButtonNo text is missing" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<router-outlet></router-outlet>

<xcess-about-box i18n-tagLine="@@AboutBoxTagLine" tagLine="AboutBoxTagLine text is missing"
  i18n-versionLabel="@@AboutBoxVersionLabel" versionLabel="AboutBoxVersionLabel text is missing"
  [versionString]="version$ | async" copyrightYear="2024" i18n-userLicense="@@AboutBoxUserLicense"
  userLicense="AboutBoxUserLicense text is missing"></xcess-about-box>
