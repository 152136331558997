import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsightsSdkService } from '../services/application-insights-sdk.service';

@Injectable({ providedIn: "root" })
export class ApplicationInsightsErrorHandler extends ErrorHandler {
  //#region Constructor
  constructor(private readonly _applicationInsightsSdkService: ApplicationInsightsSdkService) {
    super();
  }
  //#endregion

  //#region ErrorHandler
  public handleError(error: Error): void {
    // Note that if an error is thrown while our Application Insights is initialising, canLog will not be set at all.
    if (this._applicationInsightsSdkService.canLog && this._applicationInsightsSdkService.canLog()) {
      this._applicationInsightsSdkService.logException(error);
    } else {
      super.handleError(error);
    }
  }
  //#endregion
}
