import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DepartmentWithYearsDto } from "../models/declarability/department-with-years-dto.model";
import { ApiServiceBase } from "./base/api-service-base";

@Injectable({ providedIn: 'root' })
export class DepartmentWithYearsService extends ApiServiceBase {
    //#region Constructor
    constructor(
        private readonly _httpClient: HttpClient
    ) {
        super();
    }
    //#endregion

    //#region Private properties
    private readonly _departmentWithYearsControllerUrl: string = "/api/DepartmentWithYears";
    //#endregion

    //#region Public methods
    public get$(): Observable<DepartmentWithYearsDto> {
      // Returns the department of the currently logged in Profit user.
      const url: string = `${this._departmentWithYearsControllerUrl}`;

      return super.catchErrorResponse(this._httpClient.get<DepartmentWithYearsDto>(url));
    }

    public getForManager$(managerID: string): Observable<DepartmentWithYearsDto> {
      // Returns the department of the specified manager.
      const url: string = `${this._departmentWithYearsControllerUrl}/${managerID}`;

      return super.catchErrorResponse(this._httpClient.get<DepartmentWithYearsDto>(url));
    }
  //#endregion
}