import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BudgetViewDto } from '../models/budget/budget-view-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class BudgetViewService extends ApiServiceBase {
  //#region Constructor
  constructor(private readonly _httpClient: HttpClient) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _budgetViewControllerUrl: string = "/api/BudgetView";
  //#endregion

  //#region Public methods
  public getByQuotation$(quotationID: string): Observable<BudgetViewDto> {
    const url: string = `${this._budgetViewControllerUrl}/quotation/${quotationID}`;

    return super.catchErrorResponse(this._httpClient.get<BudgetViewDto>(url));
  }
  //#endregion
}
