import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { take, switchMap, finalize, shareReplay, catchError, tap, map } from 'rxjs/operators';
import { NumberService } from 'src/app/services/number.service';
import { ApiError } from 'src/web-api/models/api-error.model';
import { BudgetViewDto } from 'src/web-api/models/budget/budget-view-dto.model';
import { BudgetIntegrationGroupLineDto } from 'src/web-api/models/budget/budget-integration-group-line-dto.model';
import { YearAmountAndNumberDto } from 'src/web-api/models/year-amount-and-number-dto.model';
import { BudgetViewService } from 'src/web-api/services/budget-view.service';
import { YearAmountAndNumberService } from 'src/web-api/services/year-amount-and-number.service';
import { VilaMessageService } from '../services/vila-message.service';
import { RouteParameter } from '../statics/route-parameter';
import { RouteSegment } from '../statics/route-segment';

@Component({
  selector: 'vila-budget-view',
  templateUrl: './budget-view.component.html',
  styleUrls: ['./budget-view.component.scss']
})
export class BudgetViewComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _budgetViewService: BudgetViewService,
    private readonly _confirmationService: ConfirmationService,
    private readonly _numberService: NumberService,
    private readonly _router: Router,
    private readonly _vilaMessageService: VilaMessageService,
    private readonly _yearAmountAndNumberService: YearAmountAndNumberService
  ) { }
  //#endregion

  //#region Private properties
  private _headerCostsText: string = $localize`:@@HeaderCosts:HeaderCosts text is missing`;
  private _headerProceedsText: string = $localize`:@@HeaderProceeds:HeaderProceeds text is missing`;
  private _toastBodyUpdatingBudgetIntegrationGroupLineErrorText: string =
    $localize`:@@ToastBodyUpdatingBudgetIntegrationGroupLineError:ToastBodyUpdatingBudgetIntegrationGroupLineError text is missing`;
  //#endregion

  //#region Public properties
  public budgetView$: Observable<BudgetViewDto>;
  public canReturnToProject$: Observable<boolean>;
  public editingYear: number;
  public isEditing: boolean;
  public isEditingYear: boolean;
  public isLoading: boolean;
  public isSubmitting: boolean;
  public quotationID$: Observable<string>;
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.isLoading = true;

    this.quotationID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.QUOTATION_ID)))
      .pipe(shareReplay(1));

    this.budgetView$ = this.quotationID$
      .pipe(switchMap(quotationID => this._budgetViewService.getByQuotation$(quotationID)))
      .pipe(tap(budgetView => {
        budgetView.proceedsBudgetIntegrationGroupLines = this._getSortedBudgetIntegrationGroupLines(budgetView.proceedsBudgetIntegrationGroupLines);
        budgetView.costsBudgetIntegrationGroupLines = this._getSortedBudgetIntegrationGroupLines(budgetView.costsBudgetIntegrationGroupLines);
      }))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingBudgetError:ToastBodyLoadingBudgetError text is missing`);

        return of(undefined);
      }))
      .pipe(shareReplay(1));

    // If the budget view is opened from the project view, the URL will resemble 'project/1234/budget/5678'.
    // The url property of the activated route is an array containing the url segments.
    // Let's check whether there are at least four segments and the fourth from the end is actually the project segment!
    const projectSegmentIndexFromEnd = 3;

    this.canReturnToProject$ = this._activatedRoute.url
      .pipe(switchMap(url => of(url.length > projectSegmentIndexFromEnd
        && url.reverse()[projectSegmentIndexFromEnd].path === RouteSegment.PROJECT)));
  }
  //#endregion

  //#region General methods
  public getFormattedNumber(number: number): string {
    return this._numberService.getFormattedNumber(number);
  }

  public getSubSectionHeader(index: number) {
    return index == 0 ? this._headerProceedsText : this._headerCostsText;
  }

  public getTotalNumberOfColumns(years: number[]): number {
    const numberOfStartColumns: number = 1;
    const numberOfColumnsPerYear: number = 2;
    const numberOfEndColumns: number = 3;

    return numberOfStartColumns + numberOfColumnsPerYear * years.length + numberOfEndColumns;
  }

  public getTotalAmount(budgetIntegrationGroupLines: BudgetIntegrationGroupLineDto[]): number {
    return budgetIntegrationGroupLines
      .reduce((accumulator, budgetIntegrationGroupLine) => accumulator + this.getBudgetIntegrationGroupLineAmount(budgetIntegrationGroupLine), 0);
  }

  public onInputChanged(yearAmountAndNumber: YearAmountAndNumberDto, inputValue: number): void {
    yearAmountAndNumber.amount = inputValue;
  }

  public onReturnToProject(): void {
    this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
  }
  //#endregion

  //#region Year methods
  public getYearTotalAmount(budgetIntegrationGroupLines: BudgetIntegrationGroupLineDto[], yearIndex: number): number {
    return budgetIntegrationGroupLines
      .reduce((accumulator, budgetIntegrationGroupLine) => accumulator + budgetIntegrationGroupLine.yearAmountAndNumbers[yearIndex].amount, 0);
  }

  public disableYearEditInitButton(budget: BudgetViewDto, years: number[]): boolean {
    // The year can only be edited when a single year is shown and no data has been entered.
    return this.isEditing
      || budget.years.length > 1
      || this._getAllBudgetIntegrationGroupLines(budget)
        .some(budgetIntegrationGroupLine => budgetIntegrationGroupLine.yearAmountAndNumbers[0].amount > 0);
  }

  public onAddYear(budget: BudgetViewDto) {
    const newYear: number = budget.years[budget.years.length - 1] + 1;

    budget.years.push(newYear);

    this._getAllBudgetIntegrationGroupLines(budget)
      .forEach(budgetIntegrationGroupLine => budgetIntegrationGroupLine.yearAmountAndNumbers
        .push(this._yearAmountAndNumberService.create(newYear, budgetIntegrationGroupLine.defaultItemID)));
  }

  public onYearEditInit(budget: BudgetViewDto): void {
    this.editingYear = budget.years[0];

    this.isEditingYear = true;
  }

  public onYearEditConfirm(budget: BudgetViewDto): void {
    // The year can only be edited when a single year is shown, so only the first entry of the respective arrays have to be edited.
    budget.years[0] = this.editingYear;

    this._getAllBudgetIntegrationGroupLines(budget)
      .forEach(budgetIntegrationGroupLine => budgetIntegrationGroupLine.yearAmountAndNumbers[0].year = this.editingYear);

    this.isEditingYear = false;
  }

  public showYearEditConfirmButton(budget: BudgetViewDto): boolean {
    return this.isEditingYear && budget.years.length === 1;
  }
  //#endregion

  //#region Integration group methods
  public getBudgetIntegrationGroupLineAmount(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): number {
    return budgetIntegrationGroupLine.yearAmountAndNumbers
      .reduce((accumulator, yearAmountAndNumber) => accumulator + yearAmountAndNumber.amount, 0);
  }

  public disableRowEditClearButton(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return this.isSubmitting
      || budgetIntegrationGroupLine.yearAmountAndNumbers.every(yearAmountAndNumber => yearAmountAndNumber.amount === 0);
  }

  public disableRowEditConfirmButton(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return this.isSubmitting
      || !this._hasChangedYearAmountAndNumbers(budgetIntegrationGroupLine);
  }

  public onRowEditCancel(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): void {
    budgetIntegrationGroupLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => {
      yearAmountAndNumber.amount = yearAmountAndNumber.original ? yearAmountAndNumber.original.amount : 0;

      yearAmountAndNumber.original = undefined;
    });

    this.isEditing = false;

    budgetIntegrationGroupLine.isEditing = false;
  }

  public onRowEditClear(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto, quotationID: string): void {
    this._confirmationService.confirm({
      header: $localize`:@@ConfirmHeaderDeleteData:ConfirmHeaderDeleteData text is missing`,
      message: $localize`:@@ConfirmBodyClearBudgetIntegrationGroupLine:ConfirmBodyClearBudgetIntegrationGroupLine text is missing`,
      accept: () => {
        this.isSubmitting = true;

        budgetIntegrationGroupLine.isSubmitting = true;

        const yearAmountAndNumberIDsToDelete: string[] = budgetIntegrationGroupLine.yearAmountAndNumbers
          .filter(yearAmountAndNumber => yearAmountAndNumber.id != null)
          .map(yearAmountAndNumber => yearAmountAndNumber.id)

        this._yearAmountAndNumberService
          .delete$(yearAmountAndNumberIDsToDelete, quotationID)
          .pipe(tap(() => {
            budgetIntegrationGroupLine.yearAmountAndNumbers.forEach(yearAmountAndNumber => {
              yearAmountAndNumber.amount = 0;
              yearAmountAndNumber.id = undefined;
            });
          }))
          .pipe(finalize(() => {
            this.isSubmitting = false;

            budgetIntegrationGroupLine.isSubmitting = false;
          }))
          .pipe(catchError((apiError: ApiError) => {
            this._vilaMessageService
              .showError(apiError.message ?? this._toastBodyUpdatingBudgetIntegrationGroupLineErrorText);

            return of(undefined);
          }))
          .subscribe();
      }
    });
  }

  public onRowEditConfirm(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto, quotationID: string): void {
    this.isSubmitting = true;

    budgetIntegrationGroupLine.isSubmitting = true;

    this._upsertYearAmountAndNumbers(budgetIntegrationGroupLine, quotationID);
  }

  public onRowEditInit(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): void {
    this.isEditing = true;

    budgetIntegrationGroupLine.isEditing = true;

    budgetIntegrationGroupLine.yearAmountAndNumbers
      .forEach(yearAmountAndNumber => yearAmountAndNumber.original = { ...yearAmountAndNumber });
  }

  public onShowBudgetCostsDetailsView(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): void {
    this._router.navigate([`${RouteSegment.COSTS}/${budgetIntegrationGroupLine.id}`], { relativeTo: this._activatedRoute });
  }

  public onShowBudgetPersonnelDetailsView(): void {
    this._router.navigate([RouteSegment.PERSONNEL], { relativeTo: this._activatedRoute });
  }

  public showRowEditCancelButton(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return !budgetIntegrationGroupLine.isCosts
      && budgetIntegrationGroupLine.isEditing;
  }

  public showRowEditClearButton(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return !budgetIntegrationGroupLine.isCosts
      && !budgetIntegrationGroupLine.isEditing
      && !budgetIntegrationGroupLine.isSubmitting;
  }

  public showRowEditConfirmButton(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return !budgetIntegrationGroupLine.isCosts
      && budgetIntegrationGroupLine.isEditing
      && !budgetIntegrationGroupLine.isSubmitting;
  }

  public showRowEditInitButton(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return !budgetIntegrationGroupLine.isCosts
      && !budgetIntegrationGroupLine.isEditing;
  }

  public showRowEditSpinnerButton(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return budgetIntegrationGroupLine.isSubmitting;
  }
  //#endregion

  //#region Private methods
  private _hasChangedYearAmountAndNumbers(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): boolean {
    return this._getChangedYearAmountAndNumbers(budgetIntegrationGroupLine).length > 0;
  }

  private _getChangedYearAmountAndNumbers(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto): YearAmountAndNumberDto[] {
    return budgetIntegrationGroupLine.yearAmountAndNumbers
      .filter(yearAmountAndNumber => yearAmountAndNumber.amount != yearAmountAndNumber.original?.amount);
  }

  private _upsertYearAmountAndNumbers(budgetIntegrationGroupLine: BudgetIntegrationGroupLineDto, quotationID: string): void {
    const yearAmountAndNumbersToUpdate: YearAmountAndNumberDto[] = this._getChangedYearAmountAndNumbers(budgetIntegrationGroupLine);

    this._yearAmountAndNumberService
      .upsert$(yearAmountAndNumbersToUpdate, quotationID)
      .pipe(tap(updatedYearAmountAndNumbers => {
        yearAmountAndNumbersToUpdate.forEach((yearAmountAndNumber, index) => {
          if (yearAmountAndNumber.id == null) {
            yearAmountAndNumber.id = updatedYearAmountAndNumbers[index].id;
          }
        });

        budgetIntegrationGroupLine.yearAmountAndNumbers
          .forEach(yearAmountAndNumber => yearAmountAndNumber.original = undefined);

        this.isEditing = false;

        budgetIntegrationGroupLine.isEditing = false;
      }))
      .pipe(finalize(() => {
        this.isSubmitting = false;

        budgetIntegrationGroupLine.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? this._toastBodyUpdatingBudgetIntegrationGroupLineErrorText);

        return of(undefined);
      }))
      .subscribe();
  }

  private _getAllBudgetIntegrationGroupLines(budget: BudgetViewDto): BudgetIntegrationGroupLineDto[] {
    return budget.proceedsBudgetIntegrationGroupLines.concat(budget.costsBudgetIntegrationGroupLines);
  }

  private _getSortedBudgetIntegrationGroupLines(budgetIntegrationGroupLines: BudgetIntegrationGroupLineDto[]): BudgetIntegrationGroupLineDto[] {
    return budgetIntegrationGroupLines
      .sort((budgetIntegrationGroupLineA, budgetIntegrationGroupLineB) => budgetIntegrationGroupLineA.order - budgetIntegrationGroupLineB.order);
  }
  //#endregion
}
