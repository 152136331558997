<i (click)="toggleAboutBox()" class="pi pi-info-circle"></i>

<div *ngIf="isOpen" (click)="toggleAboutBox()" class="about-box">
    <div class="version-box">
        <div class="tag-line">{{ tagLine }}</div>
        <div class="version">{{ versionLabel }} {{ versionString }}</div>
        <div class="copyright">Copyright © {{ copyrightYear }} Irixs B.V.</div>
        <div class="license">{{ userLicense }}</div>
    </div>
</div>
