import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmployeeDto } from '../models/employee-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class EmployeeService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _employeeControllerUrl: string = "/api/Employee";
  //#endregion

  //#region Public methods
  public list$(): Observable<EmployeeDto[]> {
    return super.catchErrorResponse(this._httpClient.get<EmployeeDto[]>(this._employeeControllerUrl));
  }
  //#endregion
}
