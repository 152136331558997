<div class="p-grid p-dir-col" *ngIf="(quotationID$ | async) as quotationID">
  <div class="p-col">
    <vila-project-header [quotationID]="quotationID"></vila-project-header>
  </div>

  <div class="p-col">
    <vila-loader i18n-message="@@MessageLoadingBudget" message="MessageLoadingBudget text is missing" *ngIf="isLoading">
    </vila-loader>

    <p-table *ngIf="(budgetView$ | async) as budgetView"
      [value]="[budgetView.proceedsBudgetIntegrationGroupLines, budgetView.costsBudgetIntegrationGroupLines]">
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th *ngFor="let year of budgetView.years" colspan="2" [ngClass]="{'input-cell' : isEditingYear}">
            <ng-container *ngIf="!isEditingYear"> {{ year }} </ng-container>
            <p-inputNumber *ngIf="isEditingYear" [(ngModel)]="editingYear" [maxFractionDigits]="0" [useGrouping]="false"
              [min]="0">
            </p-inputNumber>
          </th>
          <th class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button" *ngIf="!isEditingYear" (onClick)="onYearEditInit(budgetView)"
              [disabled]="disableYearEditInitButton(budgetView)"></p-button>
            <p-button icon="pi pi-check" type="button" *ngIf="showYearEditConfirmButton(budgetView)"
              (onClick)="onYearEditConfirm(budgetView)"></p-button>
            <p-button icon="pi pi-plus" type="button" (onClick)="onAddYear(budgetView)"
              [disabled]="isSubmitting || isEditingYear"></p-button>
          </th>
          <th i18n="@@HeaderTotal">HeaderTotal text is missing</th>
          <th></th>
        </tr>
        <tr>
          <th></th>
          <ng-container *ngFor="let year of budgetView.years">
            <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
            <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          </ng-container>
          <th></th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-budgetIntegrationGroupLines let-subSectionIndex="rowIndex">
        <tr class="divider"></tr>

        <tr class="sub-header">
          <td>{{ getSubSectionHeader(subSectionIndex) }}</td>
          <td [attr.colspan]="getTotalNumberOfColumns(budgetView.years) - 1"></td>
        </tr>

        <tr *ngFor="let budgetIntegrationGroupLine of budgetIntegrationGroupLines" class="sub-section">
          <td class="row-name-holder">{{ budgetIntegrationGroupLine.name }}</td>
          <ng-container *ngFor="let yearAmountAndNumber of budgetIntegrationGroupLine.yearAmountAndNumbers">
            <td class="number-holder">
              <ng-container *ngIf="budgetIntegrationGroupLine.isPersonnel">
                {{ getFormattedNumber(yearAmountAndNumber.number) }}
              </ng-container>
            </td>
            <td [ngClass]="{'input-cell' : budgetIntegrationGroupLine.isEditing}" class="amount-holder">
              <p-inputNumber *ngIf="budgetIntegrationGroupLine.isEditing" [(ngModel)]="yearAmountAndNumber.amount"
                locale="nl-NL" [maxFractionDigits]="0" [disabled]="isSubmitting"
                (onInput)="onInputChanged(yearAmountAndNumber, $event.value)" [step]="1000" [min]="0">
              </p-inputNumber>
              <ng-container *ngIf="!budgetIntegrationGroupLine.isEditing">
                {{ getFormattedNumber(yearAmountAndNumber.amount) }}
              </ng-container>
            </td>
          </ng-container>
          <td></td>
          <td class="amount-holder">
            {{ getFormattedNumber(getBudgetIntegrationGroupLineAmount(budgetIntegrationGroupLine)) }}
          </td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button" *ngIf="showRowEditInitButton(budgetIntegrationGroupLine)"
              (onClick)="onRowEditInit(budgetIntegrationGroupLine)" [disabled]="isSubmitting || isEditingYear">
            </p-button>
            <p-button icon="pi pi-check" type="button" *ngIf="showRowEditConfirmButton(budgetIntegrationGroupLine)"
              (onClick)="onRowEditConfirm(budgetIntegrationGroupLine, quotationID)"
              [disabled]="disableRowEditConfirmButton(budgetIntegrationGroupLine)">
            </p-button>
            <p-button icon="pi pi-spin pi-spinner" *ngIf="showRowEditSpinnerButton(budgetIntegrationGroupLine)"
              class="no-pointer">
            </p-button>
            <p-button icon="pi pi-trash" type="button" *ngIf="showRowEditClearButton(budgetIntegrationGroupLine)"
              (onClick)="onRowEditClear(budgetIntegrationGroupLine, quotationID)"
              [disabled]="disableRowEditClearButton(budgetIntegrationGroupLine)">
            </p-button>
            <p-button icon="pi pi-times" type="button" *ngIf="showRowEditCancelButton(budgetIntegrationGroupLine)"
              (onClick)="onRowEditCancel(budgetIntegrationGroupLine)" [disabled]="isSubmitting"></p-button>
            <p-button icon="pi pi-user-edit" type="button"
              *ngIf="budgetIntegrationGroupLine.isPersonnel"
              (onClick)="onShowBudgetPersonnelDetailsView()" [disabled]="isSubmitting || isEditing">
            </p-button>
            <p-button icon="pi pi-list" type="button"
              *ngIf="budgetIntegrationGroupLine.isCosts && !budgetIntegrationGroupLine.isPersonnel"
              (onClick)="onShowBudgetCostsDetailsView(budgetIntegrationGroupLine)" [disabled]="isSubmitting || isEditing">
            </p-button>
          </td>
        </tr>

        <tr class="sub-header">
          <td>{{ getSubSectionHeader(subSectionIndex) }}</td>
          <ng-container *ngFor="let year of budgetView.years; let yearIndex = index">
            <td></td>
            <td class="amount-holder">
              {{ getFormattedNumber(getYearTotalAmount(budgetIntegrationGroupLines, yearIndex)) }}</td>
          </ng-container>
          <td></td>
          <td class="amount-holder">{{ getFormattedNumber(getTotalAmount(budgetIntegrationGroupLines)) }}</td>
          <td></td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr class="divider"></tr>

        <tr class="sub-header">
          <td i18n="@@HeaderBalance">HeaderBalance text is missing</td>
          <ng-container *ngFor="let year of budgetView.years; let yearIndex = index">
            <td></td>
            <td class="amount-holder">
              {{ getFormattedNumber(getYearTotalAmount(budgetView.proceedsBudgetIntegrationGroupLines, yearIndex) -
              getYearTotalAmount(budgetView.costsBudgetIntegrationGroupLines, yearIndex)) }}
            </td>
          </ng-container>
          <td></td>
          <td class="amount-holder">
            {{ getFormattedNumber(getTotalAmount(budgetView.proceedsBudgetIntegrationGroupLines) -
            getTotalAmount(budgetView.costsBudgetIntegrationGroupLines)) }}
          </td>
          <td></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col" *ngIf="canReturnToProject$ | async">
    <p-button type="button" (onClick)="onReturnToProject()" [disabled]="isSubmitting || isEditing"
      i18n-label="@@ButtonReturn" label="ButtonReturn text is missing">
    </p-button>
  </div>
</div>