<div class="p-grid p-dir-col" *ngIf="(projectID$ | async) as projectID">
  <div class="p-col">
    <vila-project-header [projectID]="projectID"></vila-project-header>
  </div>

  <div class="p-col">
    <vila-loader i18n-message="@@MessageLoadingQuotations" message="MessageLoadingQuotations text is missing"
      *ngIf="isLoading">
    </vila-loader>

    <div class="p-grid" *ngIf="quotations">
      <div class="p-col-fixed">
        <div class="p-grid p-dir-col">
          <div class="p-col">
            <h3 i18n="@@HeaderChoose">HeaderChoose text is missing</h3>
          </div>

          <div class="p-col">
            <p-listbox [options]="listOptions" optionLabel="name" [(ngModel)]="selectedQuotation">
            </p-listbox>
          </div>
        </div>
      </div>

      <div class="p-col"></div>

      <div class="p-col-fixed">
        <div class="p-grid p-dir-col">
          <div class="p-col">
            <p-button label="ButtonEditQuotation text is missing" i18n-label="@@ButtonEditQuotation" type="button"
              [disabled]="!enableEditQuotationButton(quotations)" (onClick)="onEditQuotationClicked()"></p-button>
          </div>
          <div class="p-col" *ngIf="showEstablishBudgetButton(quotations)">
            <vila-load-button label="ButtonEstablishBudget text is missing" i18n-label="@@ButtonEstablishBudget"
              [isLoading]="isEstablishingBudget" (onClick)="onEstablishBudgetClicked(projectID)">
            </vila-load-button>
          </div>
          <div class="p-col">
            <vila-load-button label="CreateActual text is missing" i18n-label="@@ButtonCreateActual"
              [isLoading]="isCreatingActual" [disabled]="!enableCreateActualButton(quotations)"
              (onClick)="onCreateActualClicked(projectID)">
            </vila-load-button>
          </div>
          <div class="p-col">
            <p-button label="ButtonEditProgress text is missing" i18n-label="@@ButtonEditProgress" type="button"
              [disabled]="!enableEditProgressButton()" (onClick)="onEditProgressClicked()"></p-button>
          </div>
          <div class="p-col">
            <vila-load-button label="ButtonCreatePlayground text is missing" i18n-label="@@ButtonCreatePlayground"
              [isLoading]="isCreatingPlayground" [disabled]="!enableCreatePlaygroundButton(quotations)"
              (onClick)="onCreatePlaygroundClicked(projectID)">
            </vila-load-button>
          </div>
          <div class="p-col">
            <vila-load-button label="ButtonRemove text is missing" i18n-label="@@ButtonRemove"
              [isLoading]="isRemovingQuotation" [disabled]="!enableRemoveQuotationButton(quotations)"
              (onClick)="onRemoveQuotationClicked(projectID)">
            </vila-load-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>