import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { YearAmountAndNumberDto } from '../models/year-amount-and-number-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class YearAmountAndNumberService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient,
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _yearAmountAndNumberControllerUrl: string = "/api/YearAmountAndNumber";
  //#endregion

  //#region Public methods
  public create(year: number, itemID: string): YearAmountAndNumberDto {
    return {
      year: year,
      itemID: itemID,
      amount: 0,
      number: 0,
      rateGroupID: undefined,
      employeeID: undefined,
      id: undefined,
      name: undefined,
      original: undefined,
      isEditing: undefined,
      isSubmitting: undefined,
      remarks: undefined
    }
  }

  public delete$(yearAmountAndNumberIDs: string[], quotationID: string): Observable<void> {
    // Note that yearAmountAndNumberIDs here is converted into a comma separated list of ID's.
    const url: string = `${this._yearAmountAndNumberControllerUrl}/quotation/${quotationID}/${yearAmountAndNumberIDs}`;

    return super.catchErrorResponse(this._httpClient.delete<void>(url));
  }

  public upsert$(yearAmountAndNumbers: YearAmountAndNumberDto[], quotationID: string): Observable<YearAmountAndNumberDto[]> {
    const url: string = `${this._yearAmountAndNumberControllerUrl}/quotation/${quotationID}`;

    return super.catchErrorResponse(this._httpClient.post<YearAmountAndNumberDto[]>(url, yearAmountAndNumbers));
  }
  //#endregion
}
