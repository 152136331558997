import { Injectable } from '@angular/core';
import { BudgetEmployeeLineDto } from '../models/budget/budget-employee-line-dto.model';
import { YearAmountAndNumberService } from './year-amount-and-number.service';

@Injectable({ providedIn: 'root' })
export class BudgetEmployeeLineService {
  //#region Constructor
  constructor(
    private readonly _yearAmountAndNumberService: YearAmountAndNumberService
  ) { }
  //#endregion

  //#region Public methods
  public create(years: number[]): BudgetEmployeeLineDto {
    return {
      yearAmountAndNumbers: years.map(year => this._yearAmountAndNumberService.create(year, undefined)),
      defaultItemID: undefined,
      employeeIsBlocked: false,
      rateGroupID: undefined,
      original: undefined,
      isEditing: undefined,
      isSubmitting: undefined,
      name: undefined,
      id: undefined
    }
  }
  //#endregion
}
