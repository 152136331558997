<div class="p-d-flex manager-label" *ngIf="isDeclarabilityAdmin">
  <div i18n="@@TitleManager">TitleManager text is missing</div>
  <div class="p-ml-auto manager-selector">
    <p-dropdown [options]="(managers$ | async)" optionLabel="name" [autoDisplayFirst]="false"
      [(ngModel)]="selectedManager" (onChange)="onSelectedManagerChanged()" [disabled]="disableHeader"></p-dropdown>
  </div>
</div>

<vila-loader i18n-message="@@MessageLoadingDepartment" message="MessageLoadingDepartment text is missing"
  *ngIf="isLoading">
</vila-loader>

<ng-container *ngIf="(departmentWithYears$ | async) as departmentWithYears">
  <div class="p-d-flex year-label">
    <div i18n="@@TitleYear">TitleYear text is missing</div>
    <div class="p-ml-auto year-selector">
      <p-dropdown [options]="selectableYears" [(ngModel)]="selectedYear" (onChange)="onChangeSelectedYear()"
        [disabled]="disableHeader">
      </p-dropdown>
    </div>
  </div>

  <div class="p-d-flex">
    <div class="department-name">{{ departmentWithYears.name }}</div>
  </div>
</ng-container>
