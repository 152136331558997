<vila-loader i18n-message="@@MessageLoadingProgress" message="MessageLoadingProgress text is missing" *ngIf="isLoading">
</vila-loader>

<div class="p-grid p-dir-col" *ngIf="(progressAndProgressTypes$ | async) as progressAndProgressTypes">
  <div class="p-col">
    <div class="p-grid">
      <div class="p-col-fixed" i18n="@@LabelWithinBudget">LabelWithinBudget text is missing</div>
      <div class="p-col-fixed">
        <p-dropdown [(ngModel)]="withinBudgetProgressType" [options]="progressAndProgressTypes[1]" optionLabel="name"
          [autoDisplayFirst]="false" [disabled]="isSubmitting">
        </p-dropdown>
      </div>
      <div class="p-col"><textarea pInputTextarea [(ngModel)]="progressAndProgressTypes[0].withinBudgetRemarks"
          [disabled]="isSubmitting"></textarea></div>
    </div>
  </div>
  <div class="p-col">
    <div class="p-grid">
      <div class="p-col-fixed" i18n="@@LabelWithinTime">LabelWithinTime text is missing</div>
      <div class="p-col-fixed">
        <p-dropdown [(ngModel)]="withinTimeProgressType" [options]="progressAndProgressTypes[1]" optionLabel="name"
          [autoDisplayFirst]="false" [disabled]="isSubmitting">
        </p-dropdown>
      </div>
      <div class="p-col"><textarea pInputTextarea [(ngModel)]="progressAndProgressTypes[0].withinTimeRemarks"
          [disabled]="isSubmitting"></textarea></div>
    </div>
  </div>
  <div class="p-col">
    <div class="p-grid">
      <div class="p-col-fixed" i18n="@@LabelProgress">LabelWithinProgress text is missing</div>
      <div class="p-col-fixed">
        <p-dropdown [(ngModel)]="progressProgressType" [options]="progressAndProgressTypes[1]" optionLabel="name"
          [autoDisplayFirst]="false" [disabled]="isSubmitting">
        </p-dropdown>
      </div>
      <div class="p-col"><textarea pInputTextarea [(ngModel)]="progressAndProgressTypes[0].progressRemarks"
          [disabled]="isSubmitting"></textarea></div>
    </div>
  </div>
  <div class="p-col p-d-flex p-jc-between">
    <div>
      <p-button type="button" (onClick)="onCancel()" [disabled]="isSubmitting" i18n-label="@@ButtonCancel"
        label="ButtonCancel text is missing">
      </p-button>
    </div>
    <div>
      <vila-load-button label="ButtonApply text is missing" i18n-label="@@ButtonApply" [isLoading]="isSubmitting"
        [disabled]="!canSubmit()" (onClick)="onSave(progressAndProgressTypes[0])">
      </vila-load-button>
    </div>
  </div>
</div>