import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PrognosisEmployeeLineDto } from '../models/prognosis/prognosis-employee-line-dto.model';
import { ApiServiceBase } from './base/api-service-base';
import { QuarterAmountAndNumberService } from './quarter-amount-and-number.service';

@Injectable({ providedIn: 'root' })
export class PrognosisEmployeeLineService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient,
    private readonly _quarterAmountAndNumberService: QuarterAmountAndNumberService
  ) {
    super();
   }
  //#endregion

  //#region Private properties
  private readonly _prognosisEmployeeLineControllerUrl: string = "/api/PrognosisEmployeeLine";
  //#endregion

  //#region Public methods
  public create(year: number): PrognosisEmployeeLineDto {
    return {
      original: undefined,
      isEditing: undefined,
      isSubmitting: undefined,
      budgets: [
        this._quarterAmountAndNumberService.create(1, year),
        this._quarterAmountAndNumberService.create(2, year),
        this._quarterAmountAndNumberService.create(3, year),
        this._quarterAmountAndNumberService.create(4, year)
      ],
      realisations: [
        this._quarterAmountAndNumberService.create(1, year),
        this._quarterAmountAndNumberService.create(2, year),
        this._quarterAmountAndNumberService.create(3, year),
        this._quarterAmountAndNumberService.create(4, year)
      ],
      prognosises: [
        this._quarterAmountAndNumberService.create(1, year),
        this._quarterAmountAndNumberService.create(2, year),
        this._quarterAmountAndNumberService.create(3, year),
        this._quarterAmountAndNumberService.create(4, year)
      ],
      employeeIsBlocked: false,
      rateGroupID: undefined,
      defaultItemID: undefined,
      id: undefined,
      name: undefined
    }
  }

  public findByQuotation$(quotationID: string, year: number): Observable<PrognosisEmployeeLineDto[]> {
    const url: string = `${this._prognosisEmployeeLineControllerUrl}/quotation/${quotationID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.get<PrognosisEmployeeLineDto[]>(url));
  }
  //#endregion
}
