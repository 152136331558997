import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationInsightsSdkService } from '../services/application-insights-sdk.service';
import { VersionService } from '../web-api/services/version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  //#region Constructor
  public constructor(
    private readonly _versionService: VersionService,
    private readonly _applicationInsightsSdkService: ApplicationInsightsSdkService
  ) {
    this._applicationInsightsSdkService.initialise();
  }
  //#endregion

  //#region Public properties
  public version$: Observable<string>;
  //#endregoin

  //#region OnInit
  public ngOnInit(): void {
    this.version$ = this._versionService.get$();
  }
  //#endregion
}
