import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { NumberService } from 'src/app/services/number.service';
import { VilaMessageService } from 'src/app/services/vila-message.service';
import { RouteParameter } from 'src/app/statics/route-parameter';
import { ApiError } from 'src/web-api/models/api-error.model';
import { EmployeeDto } from 'src/web-api/models/employee-dto.model';
import { PrognosisEmployeeLineDto } from 'src/web-api/models/prognosis/prognosis-employee-line-dto.model';
import { QuarterAmountAndNumberDto } from 'src/web-api/models/quarter-amount-and-number-dto.model';
import { EmployeeService } from 'src/web-api/services/employee.service';
import { PrognosisEmployeeLineService } from 'src/web-api/services/prognosis-employee-line.service';
import { QuarterAmountAndNumberService } from 'src/web-api/services/quarter-amount-and-number.service';
import { EmployeeLineService } from '../../../web-api/services/employee-line.service';
import { ProjectHeaderComponent } from 'src/app/shared-components/project-header/project-header.component';

@Component({
  selector: 'vila-prognosis-personnel-view',
  templateUrl: './prognosis-personnel-view.component.html',
  styleUrls: ['./prognosis-personnel-view.component.scss']
})
export class PrognosisPersonnelViewComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _confirmationService: ConfirmationService,
    private readonly _employeeService: EmployeeService,
    private readonly _employeeLineService: EmployeeLineService,
    private readonly _numberService: NumberService,
    private readonly _prognosisEmployeeLineService: PrognosisEmployeeLineService,
    private readonly _router: Router,
    private readonly _vilaMessageService: VilaMessageService,
    private readonly _quarterAmountAndNumberService: QuarterAmountAndNumberService
  ) { }
  //#endregion

  //#region Private properties
  private _currentYear: number;
  private _employees: EmployeeDto[];
  private _employees$: Observable<EmployeeDto[]>;
  private _labelQuarter: string = $localize`:@@LabelQuarter:LabelQuarter text is missing`;
  private _toastBodyUpdatingPrognosisEmployeeLineErrorText: string =
    $localize`:@@ToastBodyUpdatingPrognosisEmployeeLineError:ToastBodyUpdatingPrognosisEmployeeLineError text is missing`;
  //#endregion

  //#region Public properties
  @ViewChild('prognosisPersonnelHolder') public prognosisPersonnelHolder: ElementRef;
  @ViewChild('vilaProjectHeader') private projectHeaderComponent: ProjectHeaderComponent;

  public isEditing: boolean;
  public isLoading: boolean;
  public isSubmitting: boolean;
  public prognosisEmployeeLines$: Observable<PrognosisEmployeeLineDto[]>;
  public quotationID$: Observable<string>;
  public unusedEmployees: EmployeeDto[];
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.quotationID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.QUOTATION_ID)))
      .pipe(shareReplay(1));

    this._employees$ = this._employeeService.list$()
      .pipe(tap(employees => this._employees = employees))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingEmployeesError:ToastBodyLoadingEmployeesError text is missing`);

        return of(undefined);
      }))
      .pipe(shareReplay(1));
  }
  //#endregion

  //#region General methods
  public disableExpandToggle(prognosisEmployeeLine: PrognosisEmployeeLineDto): boolean {
    return prognosisEmployeeLine.id == null
      || prognosisEmployeeLine.prognosises.some(prognosis => prognosis.isEditing || prognosis.isSubmitting)
  }

  public getBudgetTotalAmount(prognosisEmployeeLines: PrognosisEmployeeLineDto[]): number {
    return prognosisEmployeeLines
      .reduce((accumulator, prognosisEmployeeLine) => accumulator + this.getTotalAmount(prognosisEmployeeLine.budgets), 0);
  }
  public getBudgetTotalNumber(prognosisEmployeeLines: PrognosisEmployeeLineDto[]): number {
    return prognosisEmployeeLines
      .reduce((accumulator, prognosisEmployeeLine) => accumulator + this.getTotalNumber(prognosisEmployeeLine.budgets), 0);
  }

  public getRealisationTotalAmount(prognosisEmployeeLines: PrognosisEmployeeLineDto[]): number {
    return prognosisEmployeeLines
      .reduce((accumulator, prognosisEmployeeLine) => accumulator + this.getTotalAmount(prognosisEmployeeLine.realisations), 0);
  }
  public getRealisationTotalNumber(prognosisEmployeeLines: PrognosisEmployeeLineDto[]): number {
    return prognosisEmployeeLines
      .reduce((accumulator, prognosisEmployeeLine) => accumulator + this.getTotalNumber(prognosisEmployeeLine.realisations), 0);
  }

  public getPrognosisTotalAmount(prognosisEmployeeLines: PrognosisEmployeeLineDto[]): number {
    return prognosisEmployeeLines
      .reduce((accumulator, prognosisEmployeeLine) => accumulator + this.getTotalAmount(prognosisEmployeeLine.prognosises), 0);
  }

  public getPrognosisTotalNumber(prognosisEmployeeLines: PrognosisEmployeeLineDto[]): number {
    return prognosisEmployeeLines
      .reduce((accumulator, prognosisEmployeeLine) => accumulator + this.getTotalNumber(prognosisEmployeeLine.prognosises), 0);
  }

  public getTotalAmount(quarterAmountAndNumbers: QuarterAmountAndNumberDto[]): number {
    return quarterAmountAndNumbers
      .reduce((accumulator, quarterAmountAndNumber) => accumulator + quarterAmountAndNumber.amount, 0);
  }

  public getTotalNumber(quarterAmountAndNumbers: QuarterAmountAndNumberDto[]): number {
    return quarterAmountAndNumbers
      .reduce((accumulator, quarterAmountAndNumber) => accumulator + quarterAmountAndNumber.number, 0);
  }

  public getFormattedNumber(number: number): string {
    return this._numberService.getFormattedNumber(number);
  }

  public getPrognosisEmployeeLineName(prognosisEmployeeLine: PrognosisEmployeeLineDto): string {
    return this._employeeLineService.getEmployeeLineName(prognosisEmployeeLine);
  }

  public onAddPrognosisEmployeeLine(prognosisEmployeeLines: PrognosisEmployeeLineDto[]): void {
    const newPrognosisEmployeeLine: PrognosisEmployeeLineDto = this._prognosisEmployeeLineService.create(this._currentYear);

    newPrognosisEmployeeLine.isEditing = true;

    prognosisEmployeeLines.push(newPrognosisEmployeeLine);

    // Go to the bottom of the table.
    const tableScrollableBody: Element = this.prognosisPersonnelHolder.nativeElement.getElementsByClassName("p-datatable-scrollable-body")[0];

    setTimeout(() => tableScrollableBody.scrollTop = tableScrollableBody.scrollHeight);
  }

  public onEmployeeChanged(employee: EmployeeDto, prognosisEmployeeLine: PrognosisEmployeeLineDto): void {
    prognosisEmployeeLine.id = employee.id;
    prognosisEmployeeLine.name = employee.name;
    prognosisEmployeeLine.rateGroupID = employee.rateGroupID;
    prognosisEmployeeLine.defaultItemID = employee.defaultItemID;
  }

  public onExpand(prognosisEmployeeLine: PrognosisEmployeeLineDto, prognosisEmployeeLines: PrognosisEmployeeLineDto[]): void {
    if (prognosisEmployeeLine.isEditing) {
      prognosisEmployeeLine.isEditing = false;

      prognosisEmployeeLine.prognosises.forEach(prognosis => {
        prognosis.employeeID = prognosisEmployeeLine.id;
        prognosis.rateGroupID = prognosisEmployeeLine.rateGroupID;
        prognosis.itemID = prognosisEmployeeLine.defaultItemID;
      });

      this.unusedEmployees = this._getUnusedEmployees(this._employees, prognosisEmployeeLines);

      prognosisEmployeeLines.forEach(comparePrognosisEmployeeLine => {
        if (comparePrognosisEmployeeLine.isEditing && comparePrognosisEmployeeLine.id === prognosisEmployeeLine.id) {
          comparePrognosisEmployeeLine.id = undefined;
          comparePrognosisEmployeeLine.name = undefined;
          comparePrognosisEmployeeLine.rateGroupID = undefined;
          comparePrognosisEmployeeLine.defaultItemID = undefined;
        }
      });
    }
  }

  public onReturnToPrognosis(): void {
    this._router.navigate(['../'], { relativeTo: this._activatedRoute });
  }

  public onYearChanged(year: number): void {
    this.isLoading = true;

    this._currentYear = year;

    this.prognosisEmployeeLines$ = this.quotationID$
      .pipe(switchMap(quotationID => this._prognosisEmployeeLineService.findByQuotation$(quotationID, year)))
      .pipe(tap(employees => employees = this._getSortedEmployees(employees)))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingPersonnelError:ToastBodyLoadingPersonnelError text is missing`);

        return of(undefined);
      }))
      .pipe(shareReplay(1));

    forkJoin([this._employees$, this.prognosisEmployeeLines$])
      .subscribe(result => {
        const employees: EmployeeDto[] = result[0];
        const prognosisEmployeeLines: PrognosisEmployeeLineDto[] = result[1];

        if (employees != null && prognosisEmployeeLines != null) {
          this.unusedEmployees = this._getUnusedEmployees(employees, prognosisEmployeeLines);
        }
      });
  }
  //#endregion

  //#region Quarter row methods
  public canOpenRemarks(quarterAmountAndNumber: QuarterAmountAndNumberDto): boolean {
    return quarterAmountAndNumber.isEditing || (quarterAmountAndNumber.remarks && quarterAmountAndNumber.remarks.length > 0);
  }

  public disableRowEditClearButton(prognosis: QuarterAmountAndNumberDto): boolean {
    return this.isSubmitting || prognosis.number === 0;
  }

  public disableRowEditConfirmButton(prognosis: QuarterAmountAndNumberDto): boolean {
    return this.isSubmitting
      || (prognosis.number === prognosis.original.number && prognosis.remarks == prognosis.original.remarks);
  }

  public getQuarterName(quarterAmountAndNumber: QuarterAmountAndNumberDto): string {
    return `${this._labelQuarter} ${quarterAmountAndNumber.quarter}`;
  }

  public onNumberInputChanged(prognosis: QuarterAmountAndNumberDto, inputValue: number): void {
    prognosis.number = inputValue;
  }

  public onRowEditCancel(prognosis: QuarterAmountAndNumberDto): void {
    prognosis.number = prognosis.original.number;
    prognosis.remarks = prognosis.original.remarks;

    this.isEditing = false;

    prognosis.isEditing = false;
  }

  public onRowEditClear(prognosis: QuarterAmountAndNumberDto, quotationID: string): void {
    this._confirmationService.confirm({
      header: $localize`:@@ConfirmHeaderDeleteData:ConfirmHeaderDeleteData text is missing`,
      message: $localize`:@@ConfirmBodyClearPrognosisEmployeeLine:ConfirmBodyClearPrognosisEmployeeLine text is missing`,
      accept: () => {
        this.isSubmitting = true;
        prognosis.isSubmitting = true;

        this._quarterAmountAndNumberService.delete$(prognosis.id, quotationID)
          .pipe(tap(() => {
            this.projectHeaderComponent.updateShownBalance(prognosis.amount, true)
            prognosis.number = 0;
            prognosis.amount = 0;
            prognosis.id = undefined;
          }))
          .pipe(finalize(() => {
            this.isSubmitting = false;
            prognosis.isSubmitting = false;
          }))
          .pipe(catchError((apiError: ApiError) => {
            this._vilaMessageService
              .showError(apiError.message ?? this._toastBodyUpdatingPrognosisEmployeeLineErrorText);

            return of(undefined);
          }))
          .subscribe();
      }
    });
  }

  public onRowEditConfirm(prognosis: QuarterAmountAndNumberDto, quotationID: string): void {
    this.isSubmitting = true;
    prognosis.isSubmitting = true;

    this._quarterAmountAndNumberService.upsert$(prognosis, quotationID)
      .pipe(tap(updatedQuarterAmountAndNumber => {
        if (prognosis.id == null) {
          prognosis.id = updatedQuarterAmountAndNumber.id;
        }

        this.projectHeaderComponent.updateShownBalance(prognosis.original.amount - updatedQuarterAmountAndNumber.amount, true)

        prognosis.amount = updatedQuarterAmountAndNumber.amount;

        prognosis.original = undefined;

        this.isEditing = false;
        prognosis.isEditing = false;
      }))
      .pipe(finalize(() => {
        this.isSubmitting = false;
        prognosis.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? this._toastBodyUpdatingPrognosisEmployeeLineErrorText);

        return of(undefined);
      }))
      .subscribe();
  }

  public onRowEditInit(prognosis: QuarterAmountAndNumberDto): void {
    this.isEditing = true;
    prognosis.isEditing = true;

    prognosis.original = { ...prognosis };
  }

  public showRowEditCancelButton(prognosis: QuarterAmountAndNumberDto): boolean {
    return prognosis.isEditing;
  }

  public showRowEditClearButton(prognosis: QuarterAmountAndNumberDto): boolean {
    return !prognosis.isEditing && !prognosis.isSubmitting;
  }

  public showRowEditConfirmButton(prognosis: QuarterAmountAndNumberDto): boolean {
    return prognosis.isEditing && !prognosis.isSubmitting;
  }

  public showRowEditInitButton(prognosis: QuarterAmountAndNumberDto): boolean {
    return !prognosis.isEditing;
  }

  public showRowEditSpinnerButton(prognosis: QuarterAmountAndNumberDto): boolean {
    return prognosis.isSubmitting;
  }
  //#endregion

  //#region Private methods
  private _getSortedEmployees(employees: PrognosisEmployeeLineDto[]): PrognosisEmployeeLineDto[] {
    return employees.sort((employeeA, employeeB) => employeeA.name.localeCompare(employeeB.name));
  }

  private _getUnusedEmployees(employees: EmployeeDto[], prognosisEmployeeLines: PrognosisEmployeeLineDto[]): EmployeeDto[] {
    const existingEmployeeIDs: string[] = prognosisEmployeeLines
      .filter(prognosisEmployeeLine => !prognosisEmployeeLine.isEditing)
      .map(prognosisEmployeeLine => prognosisEmployeeLine.id);

    return employees
      .filter(employee => !existingEmployeeIDs.includes(employee.id))
      .sort((employeeA, employeeB) => employeeA.name.localeCompare(employeeB.name));
  }
  //#endregion
}
