import { Component, ViewEncapsulation } from '@angular/core';
import { empty, Observable, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { DeclarabilityEmployeeLineDto } from 'src/web-api/models/declarability/declarability-employee-line-dto.model';
import { NumberService } from '../services/number.service';
import { ApiError } from 'src/web-api/models/api-error.model';
import { VilaMessageService } from '../services/vila-message.service';
import { DeclarabilityEmployeeLineService } from 'src/web-api/services/declarability-employee-line.service';
import * as FileSaver from 'file-saver';
import * as xlsx from 'xlsx';
import { DeclarabilityService } from '../services/declarability.service';

@Component({
  selector: 'vila-declarability-view',
  templateUrl: './declarability-view.component.html',
  styleUrls: ['./declarability-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeclarabilityViewComponent {
  //#region Constructor
  constructor(
    private readonly _declarabilityEmployeeLineService: DeclarabilityEmployeeLineService,
    private readonly _declarabilityService: DeclarabilityService,
    private readonly _numberService: NumberService,
    private readonly _vilaMessageService: VilaMessageService
  ) { }
  //#endregion

  //#region Private properties
  private readonly _labelEmploymentPercentageText: string =
    $localize`:@@LabelEmploymentPercentage:LabelEmploymentPercentage text is missing`;
  private readonly _labelEmploymentEndDateText: string =
    $localize`:@@LabelEmploymentEndDate:LabelEmploymentEndDate text is missing`;
  private readonly _labelRateGroupNameText: string = $localize`:@@LabelRateGroupName:LabelRateGroupName text is missing`;
  private readonly _labelEmploymentStartDateText: string =
    $localize`:@@LabelEmploymentStartDate:LabelEmploymentStartDate text is missing`;
  private readonly _labelScheduleHoursText: string =
    $localize`:@@LabelScheduleHours:LabelScheduleHours text is missing`;
  private _selectedManagerUserID: string;
  private _selectedYear: number;
  //#endregion

  //#region Public properties
  public currentPageReportTemplate: string =
    $localize`:@@CurrentPageReportTemplate:CurrentPageReportTemplate with parameters ${"{first}"}:indexFirstCurrentPage:, ${"{last}"}:indexLastCurrentPage:, and ${"{totalRecords}"}:totalEmployees: text is missing`;
  public declarabilityEmployeeLines$: Observable<DeclarabilityEmployeeLineDto[]>;
  public isActual: boolean;
  public isLoading: boolean;
  public isSubmitting: boolean;
  //#endregion

  //#region General methods
  public exportExcel(declarabilityEmployeeLines: DeclarabilityEmployeeLineDto[]) {
    const objectsForExport: object[] = this._declarabilityService.createObjectsForExport(declarabilityEmployeeLines, this.isActual);
    const worksheet: xlsx.WorkSheet = xlsx.utils.json_to_sheet(objectsForExport);
    const numberOfRows: number = objectsForExport.length;

    this._formatNumericCells(worksheet, numberOfRows);
    this._formatPercentageCells(worksheet, numberOfRows);

    const workbook: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Declarabiliteit");
    
    const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });

    this._saveAsExcelFile(excelBuffer, this._buildExcelExportFileName());
  }

  public getEmployeeDeclarabilityPercentage(employee: DeclarabilityEmployeeLineDto): string {
    return this._declarabilityService.getCleanDeclarabilityPercentage(employee, this.isActual);
  }

  public getEmployeePossibleRevenue(employee: DeclarabilityEmployeeLineDto): number {
    return this._declarabilityService.getPossibleRevenue(employee, this.isActual);
  }

  public getEmployeeRealisationHours(employee: DeclarabilityEmployeeLineDto): number {
    return this._declarabilityService.getRealisationHours(employee, this.isActual);
  }

  public getEmployeeToBePlannedHours(employee: DeclarabilityEmployeeLineDto): number {
    return this._declarabilityService.getToBePlannedHours(employee, this.isActual);
  }

  public getEmployeeWorkingHours(employee: DeclarabilityEmployeeLineDto): number {
    return this._declarabilityService.getWorkingHours(employee, this.isActual);
  }

  public getFormattedNumber(number: number): string {
    return this._numberService.getFormattedNumberWithZeroes(number);
  }

  public getTooltipText(employee: DeclarabilityEmployeeLineDto): string {
    return `${this._labelRateGroupNameText} ${employee.rateGroupName}
    ${this._labelEmploymentPercentageText} ${employee.employmentPercentage}%
    ${this._labelScheduleHoursText} ${employee.scheduleHours}
    ${this._labelEmploymentStartDateText} ${employee.employmentStartDate ? new Date(employee.employmentStartDate).toLocaleDateString() : ""}
    ${this._labelEmploymentEndDateText} ${employee.employmentEndDate ? new Date(employee.employmentEndDate).toLocaleDateString() : ""}`;
  }

  public getTotalContractHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.contractHours, 0);
  }

  public getTotalYearContractHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.yearContractHours, 0);
  }

  public getTotalExtraDirectHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.extraDirectHours, 0);
  }

  public getTotalExtraLeaveHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.extraLeaveHours, 0);
  }

  public getTotalPregnancyLeaveHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.pregnancyLeaveHours, 0);
  }

  public getTotalParentalLeaveHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.parentalLeaveHours, 0);
  }

  public getTotalIllnessHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.illnessHours, 0);
  }

  public getTotalWorkingHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + this._declarabilityService.getWorkingHours(employee, this.isActual), 0);
  }

  public getTotalIndirectHours1(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.indirectHours1, 0);
  }

  public getTotalIndirectHours2(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.indirectHours2, 0);
  }

  public getTotalIndirectHours3(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.indirectHours3, 0);
  }

  public getTotalRealisationHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + this._declarabilityService.getRealisationHours(employee, this.isActual), 0);
  }

  public getTotalDeclarability(employeeLines: DeclarabilityEmployeeLineDto[]): string {
    const totalEmployeeWorkingHours: number = this.getTotalWorkingHours(employeeLines);

    const totalEmployeeDeclarability: number = totalEmployeeWorkingHours > 0
      ? this.getTotalRealisationHours(employeeLines) / totalEmployeeWorkingHours
      : 0;

    return `${this._numberService.getPercentage(totalEmployeeDeclarability)}%`;
  }

  public getTotalPrognosis(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + this._declarabilityService.getPossibleRevenue(employee, this.isActual), 0);
  }

  public getTotalPlannedHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.plannedHours, 0);
  }

  public getTotalToBePlannedHours(employees: DeclarabilityEmployeeLineDto[]): number {
    return this.getTotalRealisationHours(employees) - employees.reduce((accumulator, employee) => accumulator + employee.plannedHours, 0);
  }

  public getTotalHoursTimesRate(employees: DeclarabilityEmployeeLineDto[]): number {
    return employees.reduce((accumulator, employee) => accumulator + employee.hoursTimesRate, 0);
  }
  //#endregion

  //#region Public Methods
  public onManagerChanged(managerUserID: string): void {
    this._selectedManagerUserID = managerUserID;
    this.declarabilityEmployeeLines$ = empty();
  }

  public onYearChanged(year: number): void {
    this._selectedYear = year;
    this._loadDeclarability();
  }

  public onCancel(): void {
    this._loadDeclarability();
  }

  public onLoadActual(): void {
    this._loadActualDeclarability();
  }

  public onSave(): void {
    this.isSubmitting = true;

    this.declarabilityEmployeeLines$
      .pipe(tap(employees => this._updateEmployeeLineCalculationFields(employees)))
      .pipe(switchMap(employees => this._declarabilityEmployeeLineService
        .upsert$(employees)
      ))
      .pipe(tap(() => this.isActual = false))
      .pipe(finalize(() => {
        this.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyUpdatingDeclarabilityError:ToastBodyUpdatingDeclarabilityError text is missing`);
        return of(undefined);
      }))
      .subscribe();
  }
  //#endregion

  //#region Private methods
  private _buildExcelExportFileName(): string {
    const fileNamePrefix: string = $localize`:@@EmployeeLineExportFileNamePrefix:EmployeeLineExportFileNamePrefix text is missing`;

    let result: string = `${fileNamePrefix}_${this._selectedYear}`;

    if (this._selectedManagerUserID) {
      result += `_${this._selectedManagerUserID}`;
    }

    return result;
  }

  private _formatNumericCells(worksheet: xlsx.WorkSheet, numberOfRows: number): void {
    const numberFormat: string = "#,##0.00";

    const rangesToFormat: CellRange[] = [
      // B2 - O<number of rows>
      { start: { row: 1, column: 1 }, end: { row: numberOfRows, column: 14 } },
      // Q2 - R<number of rows>
      { start: { row: 1, column: 16 }, end: { row: numberOfRows, column: 17 } }
    ];

    for (let rangeIndex: number = 0; rangeIndex < rangesToFormat.length; rangeIndex++) {
      this._formatNumericCellRange(worksheet, rangesToFormat[rangeIndex], numberFormat);
    }
  }

  private _formatNumericCellRange(worksheet: xlsx.WorkSheet, range: CellRange, format: string): void {
    const cellType: string = "n";

    for (let row = range.start.row; row <= range.end.row; ++row) {
      for (let column = range.start.column; column <= range.end.column; ++column) {
        const cell = worksheet[xlsx.utils.encode_cell({ r: row, c: column })];

        cell.t = cellType;
        cell.z = format;
      }
    }
  }

  private _formatPercentageCells(worksheet: xlsx.WorkSheet, numberOfRows: number): void {
    const percentageFormat: string = "0.00%";

    // P2 - P<number of rows>
    const rangeToFormat: CellRange = { start: { row: 1, column: 15 }, end: { row: numberOfRows, column: 15 } };

    this._formatNumericCellRange(worksheet, rangeToFormat, percentageFormat);
  }

  private _loadDeclarability(): void {
    this.isLoading = true;

    let declarabilityCall$: Observable<DeclarabilityEmployeeLineDto[]>;

    if (this._selectedManagerUserID != null) {
      declarabilityCall$ = this._declarabilityEmployeeLineService.findByYearAndManager$(this._selectedYear, this._selectedManagerUserID);
    } else {
      declarabilityCall$ = this._declarabilityEmployeeLineService.findByYear$(this._selectedYear);
    }

    this.declarabilityEmployeeLines$ = declarabilityCall$
      .pipe(map(employeeDeclarabilityLines => this._sortDeclarabilityEmployeeLines(employeeDeclarabilityLines)))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(finalize(() => this.isActual = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingDeclarabilityError:ToastBodyLoadingDeclarabilityError text is missing`);
        return of(undefined);
      }))
      .pipe(shareReplay(1));
  }

  private _saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE: string = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION: string = ".xlsx";

    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });

    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private _sortDeclarabilityEmployeeLines(declarabilityEmployeeLines: DeclarabilityEmployeeLineDto[]): DeclarabilityEmployeeLineDto[] {
    return declarabilityEmployeeLines
      .sort((declarabilityEmployeeA, declarabilityEmployeeB) => declarabilityEmployeeA.name
        .localeCompare(declarabilityEmployeeB.name));
  }

  private _loadActualDeclarability(): void {
    this.isLoading = true;

    let actualDeclarabilityCall$: Observable<DeclarabilityEmployeeLineDto[]>;

    if (this._selectedManagerUserID != null) {
      actualDeclarabilityCall$ = this._declarabilityEmployeeLineService.findActualByYearAndManager$(this._selectedYear, this._selectedManagerUserID);
    } else {
      actualDeclarabilityCall$ = this._declarabilityEmployeeLineService.findActualByYear$(this._selectedYear);
    }

    this.declarabilityEmployeeLines$ = actualDeclarabilityCall$
      .pipe(map(employeeDeclarabilityLines => this._sortDeclarabilityEmployeeLines(employeeDeclarabilityLines)))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(finalize(() => this.isActual = true))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingDeclarabilityError:ToastBodyLoadingDeclarabilityError text is missing`);
        return of(undefined);
      }))
      .pipe(shareReplay(1));
  }

  private _updateEmployeeLineCalculationFields(declarabilityEmployeeLines: DeclarabilityEmployeeLineDto[]): void {
    declarabilityEmployeeLines.forEach(employee => {
      employee.declarabilityPercentage = this._declarabilityService.getCalculatedDeclarabilityPercentage(employee, this.isActual);
      employee.possibleRevenue = this._declarabilityService.getPossibleRevenue(employee, this.isActual);
      employee.realisationHours = this._declarabilityService.getRealisationHours(employee, this.isActual);
      employee.toBePlannedHours = this._declarabilityService.getToBePlannedHours(employee, this.isActual);
      employee.workingHours = this._declarabilityService.getWorkingHours(employee, this.isActual);
    });
  }
  //#endregion
}

interface CellPoint {
  row: number,
  column: number
}

interface CellRange {
  start: CellPoint,
  end: CellPoint
}
