import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class DropdownHelperService {
  //#region Constructor
  constructor() { }
  //#endregion

  //#region Public Methods
  public getSelectItemArrayFromNumberArray(numberArray: number[]): SelectItem[] {
    // Since the default p-dropdown does not support number arrays, this method maps the array into one that can be parsed into a p-dropdown.
    return numberArray.map(number => ({ label: number.toString(), value: number }) as SelectItem);
  }
  //#endregion
}