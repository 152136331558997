import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiError } from '../../models/api-error.model';
import { HttpStatus } from '../../structs/http-status';
import { ErrorType } from '../../enums/error-type.enum';

export class ApiServiceBase {
  protected catchErrorResponse<T>(observable: Observable<T>): Observable<T> {
    return observable.pipe(catchError(error => {
      let result: Observable<never>;

      if (error instanceof HttpErrorResponse) {
        const httpErrorResponse: HttpErrorResponse = error as HttpErrorResponse;

        const message = httpErrorResponse.error.type && httpErrorResponse.error.type === ErrorType.Infrastructure
          ? `${$localize`:@@ErrorMessageProfit:ErrorMessageProfit text is missing:`} ${httpErrorResponse.message}`
          : undefined;

        const apiError: ApiError = {
          data: httpErrorResponse.error,
          isNotFoundError: httpErrorResponse.status === HttpStatus.NotFound,
          message: message
        };

        result = throwError(apiError);
      } else {
        result = throwError(error);
      }

      return result;
    }));
  }
}
