import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProjectViewDto } from "../models/project/project-view-dto.model";
import { ApiServiceBase } from "./base/api-service-base";

@Injectable({ providedIn: 'root' })
export class ProjectViewService extends ApiServiceBase {
    //#region Constructor
    constructor(private readonly _httpClient: HttpClient) {
        super();
    }
    //#endregion

    //#region Private properties
    private readonly _projectViewControllerUrl: string = "/api/ProjectView";
    //#endregion

    //#region Public methods
    public get$(projectID: string): Observable<ProjectViewDto> {
        const url: string = `${this._projectViewControllerUrl}/${projectID}`;

        return super.catchErrorResponse(this._httpClient.get<ProjectViewDto>(url));
    }
    //#endregion
}