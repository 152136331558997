import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, of } from 'rxjs';
import { catchError, filter, finalize, map, shareReplay, take, tap } from 'rxjs/operators';
import { QuotationType } from 'src/web-api/enums/quotation-type.enum';
import { ApiError } from 'src/web-api/models/api-error.model';
import { ProjectDto } from 'src/web-api/models/project-dto.model';
import { QuotationDto } from 'src/web-api/models/quotation-dto.model';
import { ProjectViewService } from 'src/web-api/services/project-view.service';
import { QuotationService } from 'src/web-api/services/quotation.service';
import { VilaMessageService } from '../services/vila-message.service';
import { ProgressDialogComponent } from '../shared-components/progress-dialog/progress-dialog.component';
import { RouteParameter } from '../statics/route-parameter';
import { RouteSegment } from '../statics/route-segment';

@Component({
  selector: 'vila-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectViewComponent implements OnInit {

  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _dialogService: DialogService,
    private readonly _quotationService: QuotationService,
    private readonly _projectViewService: ProjectViewService,
    private readonly _router: Router,
    private readonly _vilaMessageService: VilaMessageService
  ) { }
  //#endregion

  //#region Private properties
  private _headerEnterProgressText: string = $localize`:@@HeaderEnterProgressFor:HeaderEnterProgressFor text is missing`;
  //#endregion

  //#region Public properties
  public isCreatingActual: boolean;
  public isCreatingPlayground: boolean;
  public isEstablishingBudget: boolean;
  public isLoading: boolean;
  public isRemovingQuotation: boolean;
  public isSubmitting: boolean;
  public listOptions: QuotationDto[];
  public projectID$: Observable<string>;
  public project$: Observable<ProjectDto>;
  public quotations: QuotationDto[];
  public selectedQuotation: QuotationDto;
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.projectID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.PROJECT_ID)))
      .pipe(tap(projectID => this._refreshQuotations(projectID)))
      .pipe(shareReplay(1));
  }
  //#endregion

  //#region Public methods
  public enableCreateActualButton(quotations: QuotationDto[]): boolean {
    return !this.isSubmitting
      && ((this._isSelectedQuotationOfAny([QuotationType.Playground])
        || this._isSelectedQuotationOfAny([QuotationType.EstablishedBudget]))
        && quotations.every(quotation => quotation.quotationType !== QuotationType.Actual));
  }

  public enableCreatePlaygroundButton(quotations: QuotationDto[]): boolean {
    return !this.isSubmitting
      && this._isSelectedQuotationOfAny([QuotationType.Actual])
      && quotations.every(quotation => quotation.quotationType !== QuotationType.Playground);
  }

  public enableEditQuotationButton(quotations: QuotationDto[]): boolean {
    return !this.isSubmitting
      && (this._isSelectedQuotationOfAny([QuotationType.Budget])
        && quotations.every(quotation => quotation.quotationType !== QuotationType.EstablishedBudget)
        && !quotations.every(quotation => quotation.quotationType === QuotationType.Budget))
      || (this._isSelectedQuotationOfAny([QuotationType.EstablishedBudget])
        && quotations.every(quotation => quotation.quotationType !== QuotationType.Actual))
      || this._isSelectedQuotationOfAny([QuotationType.Actual, QuotationType.Playground]);
  }

  public enableEditProgressButton(): boolean {
    return !this.isSubmitting
      && this._isSelectedQuotationOfAny([QuotationType.PrognosisAutumn, QuotationType.PrognosisSpring])
      && this.selectedQuotation.hasProgressForCurrentYear;
  }

  public enableRemoveQuotationButton(quotations: QuotationDto[]): boolean {
    return !this.isSubmitting
      && (this._isSelectedQuotationOfAny([QuotationType.Actual])
        && quotations.some(quotation => quotation.quotationType === QuotationType.Playground))
      || this._isSelectedQuotationOfAny([QuotationType.Playground])
      && quotations.some(quotation => quotation.quotationType === QuotationType.Actual);
  }

  public onCreateActualClicked(projectID: string): void {
    this.isSubmitting = true;
    this.isCreatingActual = true;

    let creatingActual: Observable<void> = this.selectedQuotation.quotationType === QuotationType.Playground
      ? this._quotationService
        .createActualFromPlayground$(this.selectedQuotation.id)
      : this._quotationService
        .createActual$(this.selectedQuotation.id);

    creatingActual.pipe(finalize(() => {
      this.isSubmitting = false;
      this.isCreatingActual = false;
      this._refreshQuotations(projectID);
    }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyCreatingActualError:ToastBOdyCreatingActualError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }

  public onCreatePlaygroundClicked(projectID: string): void {
    this.isSubmitting = true;
    this.isCreatingPlayground = true;

    this._quotationService
      .createPlayground$(this.selectedQuotation.id)
      .pipe(finalize(() => {
        this.isSubmitting = false;
        this.isCreatingPlayground = false;
        this._refreshQuotations(projectID);
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyCreatingPlaygroundError:ToastBodyCreatingPlaygroundError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }

  public onEstablishBudgetClicked(projectID: string): void {
    this._establishBudget(projectID, this.selectedQuotation.id)
  }

  public onEditQuotationClicked(): void {
    let routeSegment: RouteSegment;

    const openBudget: boolean = this.selectedQuotation.quotationType === QuotationType.Budget
      || this.selectedQuotation.quotationType === QuotationType.EstablishedBudget;

    routeSegment = openBudget
      ? RouteSegment.BUDGET
      : RouteSegment.PROGNOSIS;

    this._router.navigate([`${routeSegment}/${this.selectedQuotation.id}`], { relativeTo: this._activatedRoute });
  }

  public onEditProgressClicked(): void {
    const currentYear: number = new Date().getFullYear();

    this._dialogService.open(ProgressDialogComponent, {
      header: `${this._headerEnterProgressText} ${currentYear}`,
      width: '50em',
      data: {
        year: currentYear,
        retrieveExistingProgress: true,
        quotationID: this.selectedQuotation.id
      }
    });
  }

  public onRemoveQuotationClicked(projectID: string): void {
    this.isSubmitting = true;
    this.isRemovingQuotation = true;

    this._quotationService.delete$(this.selectedQuotation.id, projectID)
      .pipe(finalize(() => {
        this.isSubmitting = false;
        this.isRemovingQuotation = false;
        this._refreshQuotations(projectID);
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyRemovingQuotationError:ToastBodyRemovingQuotationError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }

  public showEstablishBudgetButton(quotations: QuotationDto[]): boolean {
    return !this.isSubmitting
      && this._isSelectedQuotationOfAny([QuotationType.Budget])
      && quotations.every(quotation => quotation.quotationType !== QuotationType.EstablishedBudget);
  }
  
  //#endregion

  //#region Private methods
  private _establishBudget(projectID: string, quotationID: string) {
    this.isSubmitting = true;
    this.isEstablishingBudget = true;

    this._quotationService
      .establishBudget$(quotationID)
      .pipe(finalize(() => {
        this.isSubmitting = false;
        this.isEstablishingBudget = false;
        this._refreshQuotations(projectID);
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyEstablishingBudgetError:ToastBodyEstablishingBudgetError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }

  private _isSelectedQuotationOfAny(quotationTypes: QuotationType[]): boolean {
    return this.selectedQuotation != null
      && quotationTypes.some(quotationType => quotationType === this.selectedQuotation.quotationType);
  }

  private _refreshQuotations(projectID: string): void {
    this.selectedQuotation = undefined;

    this.isLoading = true;

    this._projectViewService.get$(projectID)
      .pipe(tap(projectView => this.quotations = this._sortQuotations(projectView.quotations)))
      .pipe(tap(projectView => this.listOptions = this._updateListOptions(projectView.quotations)))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingQuotationsError:ToastBodyLoadingQuotationsError text is missing`);

        return of(undefined);
      }))
      // If we need to create an Established Budget, do so.
      .pipe(filter(projectView => projectView.createEstablishedBudget))
      .pipe(tap(projectView => this._establishBudget(projectID, projectView.quotations[0].id)))
      .subscribe();
  }

  private _sortQuotations(quotations: QuotationDto[]): QuotationDto[] {
    return quotations.sort((quotationA, quotationB) => quotationA.order - quotationB.order);
  }

  private _updateListOptions(quotations: QuotationDto[]): QuotationDto[] {
    // When there are an Actual, or Playground, filter out the Budget. And if there is an EstablishedBudget, filter out the Budget.
    return quotations.some(quotation => (quotation.quotationType === QuotationType.Actual
      || quotation.quotationType === QuotationType.Playground))
      ? quotations.filter(quotation => (quotation.quotationType !== QuotationType.Budget
        && quotation.quotationType !== QuotationType.EstablishedBudget))
      : quotations.some(quotation => quotation.quotationType === QuotationType.EstablishedBudget)
        ? quotations.filter(quotation => quotation.quotationType !== QuotationType.Budget)
        : quotations;
  }
  //#endregion
}
