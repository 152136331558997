import { formatNumber } from '@angular/common';
import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class NumberService {
  public getFormattedNumber(number: number) {
    return number === 0 ? "-" : formatNumber(number, "nl");
  }

  public getFormattedNumberWithZeroes(number: number) {
    return formatNumber(number, "nl");
  }

  public getFormattedNumberWithDecimals(number: number) {
    return formatNumber(number, "nl", "1.2-2");
  }

  public getPercentage(number: number): number {
    return Math.round(number * 100);
  }
}