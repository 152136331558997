import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PrognosisViewDto } from '../models/prognosis/prognosis-view-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class PrognosisViewService extends ApiServiceBase {
  //#region Constructor
  constructor(private readonly _httpClient: HttpClient) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _prognosisViewControllerUrl: string = "/api/PrognosisView";
  //#endregion

  //#region Public methods
  public getByQuotation$(quotationID: string, year: number): Observable<PrognosisViewDto> {
    const url: string = `${this._prognosisViewControllerUrl}/quotation/${quotationID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.get<PrognosisViewDto>(url));
  }
  //#endregion
}
