import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { tap, shareReplay, finalize, catchError, map } from 'rxjs/operators';
import { NumberService } from 'src/app/services/number.service';
import { VilaMessageService } from 'src/app/services/vila-message.service';
import { SelectedDataService } from 'src/app/services/selected-data.service';
import { ApiError } from 'src/web-api/models/api-error.model';
import { BalanceDto } from 'src/web-api/models/balance-dto.model';
import { ProjectDto } from 'src/web-api/models/project-dto.model';
import { ProjectWithYearsDto } from 'src/web-api/models/project-with-years-dto.model';
import { BalanceService } from 'src/web-api/services/balance.service';
import { ProjectWithYearsService } from 'src/web-api/services/project-with-years.service';
import { ProjectService } from 'src/web-api/services/project.service';

@Component({
  selector: 'vila-project-header',
  templateUrl: './project-header.component.html',
  styleUrls: ['./project-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectHeaderComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _balanceService: BalanceService,
    private readonly _numberService: NumberService,
    private readonly _projectService: ProjectService,
    private readonly _projectWithYearsService: ProjectWithYearsService,
    private readonly _vilaMessageService: VilaMessageService,
    private readonly _selectedDataService: SelectedDataService
  ) { }
  //#endregion

  //#region Private properties
  private _labelBalance: string = $localize`:@@LabelBalance:LabelBalance text is missing`;
  private _offsetChange: BehaviorSubject<void> = new BehaviorSubject<void>(undefined);
  private _offsetShownProjectBalanceValue: number = 0;
  private _offsetShownYearBalanceValue: number = 0;
  //#endregion

  //#region Public properties
  @Input() public disableYearsNavigation: boolean;
  @Input() public displayYearsNavigation: boolean;
  @Input() public projectID: string;
  @Input() public quotationID: string;

  public balance$: Observable<BalanceDto>;
  public isLoading: boolean;
  public isLoadingBalance: boolean;
  public project$: Observable<ProjectDto>;
  public selectedYear: number;
  public titleProject: string = $localize`:@@TitleProject:TitleProject text is missing`;

  @Output() public onYearChanged: EventEmitter<number> = new EventEmitter<number>();
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.isLoading = true;

    let project$: Observable<ProjectDto>;

    if (this.projectID != null) {
      project$ = this._projectService.get$(this.projectID);
    } else if (this.displayYearsNavigation) {
      project$ = this._projectWithYearsService.getByQuotation$(this.quotationID)
        .pipe(tap(project => {
          const projectWithYears: ProjectWithYearsDto = project as ProjectWithYearsDto;

          if (projectWithYears.years != null && projectWithYears.years.length > 0) {
            const yearToMatch: number = this._selectedDataService.year ?? new Date().getFullYear();

            this.selectedYear = projectWithYears.years.includes(yearToMatch)
              ? yearToMatch
              : projectWithYears.years[0];

            this._onYearChanged();
          }
        }));
    } else {
      project$ = this._projectService.getByQuotation$(this.quotationID);
    }

    this.project$ = project$
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingProjectError:ToastBodyLoadingProjectError text is missing`);

        return of(undefined);
      }))
      .pipe(shareReplay(1));
  }
  //#endregion

  //#region Public methods
  public getFormattedBalance(number: number): string {
    return this._numberService.getFormattedNumberWithDecimals(number);
  }

  public getLabelBalanceYear(): string {
    return `${this._labelBalance} ${this.selectedYear}`;
  }

  public navigationIsDisabled(years: number[]): boolean {
    return this.disableYearsNavigation || years == null || years.length <= 1;
  }

  public selectNextYear(years: number[]): void {
    this.selectedYear = years[years.findIndex(year => year === this.selectedYear) + 1] ?? years[0];

    this._onYearChanged();
  }

  public selectPreviousYear(years: number[]): void {
    this.selectedYear = years[years.indexOf(this.selectedYear) - 1] ?? years[years.length - 1];

    this._onYearChanged();
  }

  public updateShownBalance(value: number, offsetSelectedYear: boolean): void {
    this._offsetShownProjectBalanceValue += value;
    if (offsetSelectedYear) {
      this._offsetShownYearBalanceValue += value;
    }

    this._offsetChange.next()
  }

  public resetShownBalanceOffset(): void {
    this._offsetShownProjectBalanceValue = 0;
    this._offsetShownYearBalanceValue = 0;

    this._offsetChange.next();
  }
  //#endregion

  //#region Private methods
  private _onYearChanged(): void {
    this._selectedDataService.year = this.selectedYear;

    this.isLoadingBalance = true;

    const balanceDto: Observable<BalanceDto> = this._balanceService.getByQuotation$(this.quotationID, this.selectedYear)
      .pipe(finalize(() => this.isLoadingBalance = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingBalanceError:ToastBodyLoadingBalanceError text is missing`);

        return of(<BalanceDto>undefined);
      }))
      .pipe(shareReplay(1))

    this.balance$ = combineLatest([balanceDto, this._offsetChange])
      .pipe(map(([balance]) => {
        return <BalanceDto>{
          projectBalance: balance.projectBalance + this._offsetShownProjectBalanceValue,
          yearBalance: balance.yearBalance + this._offsetShownYearBalanceValue
        }
      }));

    this.onYearChanged.emit(this.selectedYear);
  }
  //#endregion
}
