<vila-loader i18n-message="@@MessageLoadingProject" message="MessageLoadingProject text is missing" *ngIf="isLoading">
</vila-loader>

<ng-container *ngIf="(project$ | async) as project">
  <ng-container *ngIf="project.name">
    <div class="p-d-flex">
      <div class="project-title">{{ titleProject }} {{ project.name }}</div>
      <ng-container *ngIf="displayYearsNavigation">
        <div class="p-ml-auto balance-label" i18n="@@LabelBalanceProject">LabelBalanceProject text is missing</div>
        <div class="p-ml-2 balance-amount" [ngClass]="{ 'balance-loading' : isLoadingBalance }">
          <ng-container *ngIf="(balance$ | async) as balance">{{ getFormattedBalance(balance.projectBalance) }}
          </ng-container>
          <p-button icon="pi pi-spin pi-spinner" class="no-pointer regular-button" *ngIf="isLoadingBalance"></p-button>
        </div>
      </ng-container>
    </div>

    <div class="p-d-flex" *ngIf="displayYearsNavigation">
      <div class="p-d-inline-flex navigation">
        <p-button icon="pi pi-chevron-left" type="button" (onClick)="selectPreviousYear(project.years)"
          [disabled]="navigationIsDisabled(project.years)"></p-button>

        <div class="year">{{ selectedYear }}</div>

        <p-button icon="pi pi-chevron-right" type="button" (onClick)="selectNextYear(project.years)"
          [disabled]="navigationIsDisabled(project.years)"></p-button>
      </div>

      <div class="p-ml-auto balance-label">{{ getLabelBalanceYear() }}</div>

      <div class="p-ml-2 balance-amount" [ngClass]="{ 'balance-loading' : isLoadingBalance }">
        <ng-container *ngIf="(balance$ | async) as balance">{{ getFormattedBalance(balance.yearBalance) }}
        </ng-container>
        <p-button icon="pi pi-spin pi-spinner" class="no-pointer regular-button" *ngIf="isLoadingBalance"></p-button>
      </div>
    </div>
  </ng-container>
</ng-container>
