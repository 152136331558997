import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuotationDto } from '../models/quotation-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class QuotationService extends ApiServiceBase {
  //#region Constructor
  constructor(private readonly _httpClient: HttpClient) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _quotationControllerUrl: string = "/api/Quotation";

  private static readonly PROGNOSIS_AUTUMN_RESOURCE: string = "PrognosisAutumn";
  private static readonly PROGNOSIS_SPRING_RESOURCE: string = "PrognosisSpring";
  private static readonly STARTING_YEAR_RESOURCE: string = "StartingYear";
  //#endregion

  //#region Public methods
  public appendToPrognosisAutumn$(quotationID: string, targetQuotationID: string, year: number): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/${QuotationService.PROGNOSIS_AUTUMN_RESOURCE}/${targetQuotationID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public appendToPrognosisSpring$(quotationID: string, targetQuotationID: string, year: number): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/${QuotationService.PROGNOSIS_SPRING_RESOURCE}/${targetQuotationID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public appendToStartingYear$(quotationID: string, targetQuotationID: string, year: number): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/${QuotationService.STARTING_YEAR_RESOURCE}/${targetQuotationID}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public createActual$(quotationID: string): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/Actual`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public createActualFromPlayground$(quotationID: string): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/Playground/${quotationID}/Actual`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public createPlayground$(quotationID: string): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/Playground`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public createPrognosisAutumn$(quotationID: string, year: number): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/${QuotationService.PROGNOSIS_AUTUMN_RESOURCE}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public createPrognosisSpring$(quotationID: string, year: number): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/${QuotationService.PROGNOSIS_SPRING_RESOURCE}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public createStartingYear$(quotationID: string, year: number): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/${QuotationService.STARTING_YEAR_RESOURCE}?year=${year}`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public establishBudget$(quotationID: string): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/EstablishedBudget`;

    return super.catchErrorResponse(this._httpClient.post<void>(url, undefined));
  }

  public createForForecast$(forecastID: string): Observable<string> {
    const url: string = `${this._quotationControllerUrl}/forecast/${forecastID}`;

    return super.catchErrorResponse(this._httpClient.post<string>(url, undefined, { responseType: 'text' as 'json' }));
  }

  public delete$(quotationID: string, projectID: string): Observable<void> {
    const url: string = `${this._quotationControllerUrl}/${quotationID}/project/${projectID}`;

    return super.catchErrorResponse(this._httpClient.delete<void>(url));
  }
  //#endregion
}
