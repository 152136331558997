import { Injectable } from '@angular/core';
import { DeclarabilityEmployeeLineDto } from '../../web-api/models/declarability/declarability-employee-line-dto.model';
import { NumberService } from './number.service';

@Injectable({providedIn: 'root'})
export class DeclarabilityService {
  constructor(private _numberService: NumberService) { }

  private readonly _headerEmployeeName: string =
    $localize`:@@HeaderEmployeeName:HeaderEmployeeName text is missing`;
  private readonly _headerContractHours: string =
    $localize`:@@HeaderContractHours:HeaderContractHours text is missing`;
  private readonly _headerYearContractHours: string =
    $localize`:@@HeaderYearContractHours:HeaderYearContractHours text is missing`;
  private readonly _headerExtraDirectHours: string =
    $localize`:@@HeaderExtraDirectHours:HeaderExtraDirectHours text is missing`;
  private readonly _headerExtraLeaveHours: string =
    $localize`:@@HeaderExtraLeaveHours:HeaderExtraLeaveHours text is missing`;
  private readonly _headerPregnancyLeaveHours: string =
    $localize`:@@HeaderPregnancyLeaveHours:HeaderPregnancyLeaveHours text is missing`;
  private readonly _headerParentalLeaveHours: string =
    $localize`:@@HeaderParentalLeaveHours:HeaderParentalLeaveHours text is missing`;
  private readonly _headerIllnessHours: string =
    $localize`:@@HeaderIllnessHours:HeaderIllnessHours text is missing`;
  private readonly _headerWorkingHours: string =
    $localize`:@@HeaderWorkingHours:HeaderWorkingHours text is missing`;
  private readonly _headerIndirectHoursWorkCouncil: string =
    $localize`:@@HeaderIndirectHoursWorkCouncil:HeaderIndirectHoursWorkCouncil text is missing`;
  private readonly _headerIndirectHoursInternal: string =
    $localize`:@@HeaderIndirectHoursInternal:HeaderIndirectHoursInternal text is missing`;
  private readonly _headerIndirectHoursOther: string =
    $localize`:@@HeaderIndirectHoursOther:HeaderIndirectHoursOther text is missing`;
  private readonly _headerRealisationHours: string =
    $localize`:@@HeaderRealisationHours:HeaderRealisationHours text is missing`;
  private readonly _headerPlannedHours: string =
    $localize`:@@HeaderPlannedHours:HeaderPlannedHours text is missing`;
  private readonly _headerToBePlannedHours: string =
    $localize`:@@HeaderToBePlannedHours:HeaderToBePlannedHours text is missing`;
  private readonly _headerDeclarability: string =
    $localize`:@@HeaderDeclarability:HeaderDeclarability text is missing`;
  private readonly _headerProceed: string =
    $localize`:@@HeaderProceed:HeaderProceed text is missing`;
  private readonly _headerHoursTimesRate: string =
    $localize`:@@HeaderHoursTimesRate:HeaderHoursTimesRate text is missing`;
  private readonly _headerRemarks: string = $localize`:@@EmployeeLineExportHeaderRemarks:EmployeeLineExportHeaderRemarks text is missing`;

  public createObjectsForExport(declarabilityEmployeeLines: DeclarabilityEmployeeLineDto[], isActual: boolean): object[] {
    return declarabilityEmployeeLines.map(line => this._createObjectForExport(line, isActual));
  }

  public getCalculatedDeclarabilityPercentage(declarabilityEmployeeLine: DeclarabilityEmployeeLineDto, isActual: boolean): number {
    const employeeWorkingHours = this.getWorkingHours(declarabilityEmployeeLine, isActual);

    const employeeDeclarability: number = employeeWorkingHours > 0
      ? this.getRealisationHours(declarabilityEmployeeLine, isActual) / this.getWorkingHours(declarabilityEmployeeLine, isActual)
      : 0;

    return this._numberService.getPercentage(employeeDeclarability);
  }

  public getCleanDeclarabilityPercentage(declarabilityEmployeeLine: DeclarabilityEmployeeLineDto, isActual: boolean): string {
    return `${this._getDeclarabilityPercentage(declarabilityEmployeeLine, isActual)}%`;
  }

  public getPossibleRevenue(declarabilityEmployeeLine: DeclarabilityEmployeeLineDto, isActual: boolean): number {
    let result: number;

    if (isActual) {
      const realisationHours: number = this.getRealisationHours(declarabilityEmployeeLine, isActual);

      result = realisationHours === 0
        ? 0
        : declarabilityEmployeeLine.hoursTimesRate + (realisationHours - declarabilityEmployeeLine.plannedHours) * declarabilityEmployeeLine.rate
    } else {
      result = declarabilityEmployeeLine.possibleRevenue;
    }
    return result;
  }

  public getRealisationHours(employee: DeclarabilityEmployeeLineDto, isActual: boolean): number {
    return isActual
      ? this.getWorkingHours(employee, isActual) - (employee.indirectHours1 + employee.indirectHours2 + employee.indirectHours3)
      : employee.realisationHours;
  }

  public getToBePlannedHours(employee: DeclarabilityEmployeeLineDto, isActual: boolean): number {
    return isActual
      ? this.getRealisationHours(employee, true) - employee.plannedHours
      : employee.toBePlannedHours;
  }

  public getWorkingHours(employee: DeclarabilityEmployeeLineDto, isActual: boolean): number {
    return isActual
      ? employee.yearContractHours + employee.extraDirectHours - employee.extraLeaveHours - employee.pregnancyLeaveHours - employee.parentalLeaveHours - employee.illnessHours
      : employee.workingHours;
  }

  private _createObjectForExport(declarabilityEmployeeLine: DeclarabilityEmployeeLineDto, isActual: boolean): object {
    let result: object = {};

    result[this._headerEmployeeName] = declarabilityEmployeeLine.name;
    result[this._headerContractHours] = declarabilityEmployeeLine.contractHours;
    result[this._headerYearContractHours] = declarabilityEmployeeLine.yearContractHours;
    result[this._headerExtraDirectHours] = declarabilityEmployeeLine.extraDirectHours;
    result[this._headerExtraLeaveHours] = declarabilityEmployeeLine.extraLeaveHours;
    result[this._headerPregnancyLeaveHours] = declarabilityEmployeeLine.pregnancyLeaveHours;
    result[this._headerParentalLeaveHours] = declarabilityEmployeeLine.parentalLeaveHours;
    result[this._headerIllnessHours] = declarabilityEmployeeLine.illnessHours;
    result[this._headerWorkingHours] = this.getWorkingHours(declarabilityEmployeeLine, isActual);

    result[this._headerIndirectHoursWorkCouncil] = declarabilityEmployeeLine.indirectHours1;
    result[this._headerIndirectHoursInternal] = declarabilityEmployeeLine.indirectHours2;
    result[this._headerIndirectHoursOther] = declarabilityEmployeeLine.indirectHours3;

    result[this._headerRealisationHours] = this.getRealisationHours(declarabilityEmployeeLine, isActual);
    result[this._headerPlannedHours] = declarabilityEmployeeLine.plannedHours;
    result[this._headerToBePlannedHours] = declarabilityEmployeeLine.toBePlannedHours;
    result[this._headerDeclarability] = this._getDeclarabilityPercentage(declarabilityEmployeeLine, isActual) / 100;
    result[this._headerProceed] = this.getPossibleRevenue(declarabilityEmployeeLine, isActual);
    result[this._headerHoursTimesRate] = declarabilityEmployeeLine.hoursTimesRate;

    result[this._headerRemarks] = declarabilityEmployeeLine.remarks;

    return result;
  }

  private _getDeclarabilityPercentage(declarabilityEmployeeLine: DeclarabilityEmployeeLineDto, isActual: boolean): number {
    return isActual
      ? this.getCalculatedDeclarabilityPercentage(declarabilityEmployeeLine, isActual)
      : declarabilityEmployeeLine.declarabilityPercentage;
  }
}
