<div class="p-grid p-dir-col" *ngIf="(quotationID$ | async) as quotationID">
  <div class="p-col">
    <vila-project-header [quotationID]="quotationID"></vila-project-header>
  </div>

  <div class="p-col full-width" #budgetPersonnelHolder>
    <vila-loader i18n-message="@@MessageLoadingPersonnel" message="MessageLoadingPersonnel text is missing"
      *ngIf="isLoading"></vila-loader>

    <p-table *ngIf="(budgetPersonnelView$ | async) as budgetPersonnelView"
      [value]="budgetPersonnelView.budgetEmployeeLines" [scrollable]="true" scrollHeight="35em">
      <ng-template pTemplate="header">
        <tr class="fixed-width-setter">
          <th class="row-name-holder medium-width-holder"></th>
          <th></th>
          <ng-container *ngFor="let year of budgetPersonnelView.years">
            <th class="number-holder"></th>
            <th class="amount-holder"></th>
          </ng-container>
          <th class="icon-button-holder"></th>
          <th class="amount-holder"></th>
          <th class="icon-button-holder"></th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <th *ngFor="let year of budgetPersonnelView.years" colspan="2">
            <ng-container *ngIf="!isEditingYear"> {{ year }} </ng-container>
            <p-inputNumber *ngIf="isEditingYear" [(ngModel)]="editingYear" [maxFractionDigits]="0" [useGrouping]="false"
              [min]="0">
            </p-inputNumber>
          </th>
          <th class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button" *ngIf="!isEditingYear"
              (onClick)="onYearEditInit(budgetPersonnelView)"
              [disabled]="disableYearEditInitButton(budgetPersonnelView)"></p-button>
            <p-button icon="pi pi-check" type="button" *ngIf="showYearEditConfirmButton(budgetPersonnelView)"
              (onClick)="onYearEditConfirm(budgetPersonnelView)"></p-button>
            <p-button icon="pi pi-plus" type="button" (onClick)="onAddYear(budgetPersonnelView)"
              [disabled]="isSubmitting || isEditingYear"></p-button>
          </th>
          <th i18n="@@HeaderTotal">HeaderTotal text is missing</th>
          <th></th>
        </tr>
        <tr>
          <th></th>
          <th></th>
          <ng-container *ngFor="let year of budgetPersonnelView.years">
            <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
            <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          </ng-container>

          <th></th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th></th>
        </tr>
        <tr class="divider"></tr>
        <tr class="sub-header">
          <th class="row-name-holder medium-width-holder" i18n="@@HeaderPersonnel">HeaderPersonnel text is missing</th>
          <th></th>
          <ng-container *ngFor="let year of budgetPersonnelView.years; let yearIndex = index">
            <th class="number-holder">
              {{ getFormattedNumber(getYearTotalNumber(budgetPersonnelView.budgetEmployeeLines, yearIndex)) }}
            </th>
            <th class="amount-holder">
              {{ getFormattedNumber(getYearTotalAmount(budgetPersonnelView.budgetEmployeeLines, yearIndex)) }}
            </th>
          </ng-container>
          <th></th>
          <th class="amount-holder">
            {{ getFormattedNumber(getTotalAmount(budgetPersonnelView.budgetEmployeeLines)) }}
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-budgetEmployeeLine>
        <tr class="sub-section">
          <td
            [ngClass]="{'input-cell' : isEditingNewBudgetEmployeeLine(budgetEmployeeLine), 'blocked' : budgetEmployeeLine.employeeIsBlocked }"
            class="row-name-holder medium-width-holder">
            <ng-container *ngIf="!isEditingNewBudgetEmployeeLine(budgetEmployeeLine)">
              {{ getBudgetEmployeeLineName(budgetEmployeeLine) }}
            </ng-container>
            <p-dropdown *ngIf="isEditingNewBudgetEmployeeLine(budgetEmployeeLine)" [options]="unusedEmployees"
              optionLabel="name" (onChange)="onEmployeeChanged($event.value, budgetEmployeeLine)"
              [disabled]="isSubmitting" [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
          </td>
          <td></td>
          <ng-container *ngFor="let yearAmountAndNumber of budgetEmployeeLine.yearAmountAndNumbers">
            <td [ngClass]="{'input-cell' : budgetEmployeeLine.isEditing}" class="number-holder">
              <p-inputNumber *ngIf="budgetEmployeeLine.isEditing" [(ngModel)]="yearAmountAndNumber.number"
                locale="nl-NL" [maxFractionDigits]="0" [disabled]="isSubmitting"
                (onInput)="onInputChanged(yearAmountAndNumber, $event.value)" [step]="10" [min]="0">
              </p-inputNumber>
              <ng-container *ngIf="!budgetEmployeeLine.isEditing">{{ getFormattedNumber(yearAmountAndNumber.number) }}
              </ng-container>
            </td>
            <td class="amount-holder">
              <ng-container> {{ getFormattedNumber(yearAmountAndNumber.amount) }} </ng-container>
            </td>
          </ng-container>
          <td class="icon-button-holder"></td>
          <td class="amount-holder">{{ getFormattedNumber(getBudgetEmployeeLineAmount(budgetEmployeeLine)) }}</td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button" *ngIf="showRowEditInitButton(budgetEmployeeLine)"
              (onClick)="onRowEditInit(budgetEmployeeLine)" [disabled]="isSubmitting || isEditingYear"></p-button>
            <p-button icon="pi pi-check" type="button" *ngIf="showRowEditConfirmButton(budgetEmployeeLine)"
              (onClick)="onRowEditConfirm(budgetEmployeeLine, budgetPersonnelView, quotationID)"
              [disabled]="disableRowEditConfirmButton(budgetEmployeeLine)">
            </p-button>
            <p-button icon="pi pi-spin pi-spinner" *ngIf="showRowEditSpinnerButton(budgetEmployeeLine)"
              class="no-pointer">
            </p-button>
            <p-button icon="pi pi-trash" type="button" *ngIf="showRowEditDeleteButton(budgetEmployeeLine)"
              (onClick)="onRowEditDelete(budgetEmployeeLine, budgetPersonnelView, quotationID)"
              [disabled]="disableRowEditDeleteButton(budgetEmployeeLine)">
            </p-button>
            <p-button icon="pi pi-times" type="button" *ngIf="showRowEditCancelButton(budgetEmployeeLine)"
              (onClick)="onRowEditCancel(budgetEmployeeLine, budgetPersonnelView.budgetEmployeeLines)"
              [disabled]="isSubmitting">
            </p-button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr class="sub-section">
          <td [attr.colspan]="getTotalNumberOfColumns(budgetPersonnelView.years) - 1"></td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-plus" type="button" (onClick)="onAddBudgetEmployeeLine(budgetPersonnelView)"
              [disabled]="isSubmitting || unusedEmployees.length === 0">
            </p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col">
    <p-button type="button" (onClick)="onReturnToBudget()" [disabled]="isSubmitting || isEditing"
      i18n-label="@@ButtonReturn" label="ButtonReturn text is missing">
    </p-button>
  </div>
</div>
