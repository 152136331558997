/**
 * PropertiesPlugin.ts
 * @copyright Microsoft 2018
 */
import { CoreUtils, hasWindow, _InternalLogMessage } from '@microsoft/applicationinsights-core-js';
import { Session, _SessionManager } from './Context/Session';
import { Extensions, CtxTagKeys, PageView } from '@microsoft/applicationinsights-common';
import { Application } from './Context/Application';
import { Device } from './Context/Device';
import { Internal } from './Context/Internal';
import { User } from './Context/User';
import { Location } from './Context/Location';
import { TelemetryTrace } from './Context/TelemetryTrace';
var TelemetryContext = /** @class */function () {
  function TelemetryContext(logger, defaultConfig) {
    var _self = this;
    _self.application = new Application();
    _self.internal = new Internal(defaultConfig);
    if (hasWindow()) {
      _self.sessionManager = new _SessionManager(defaultConfig, logger);
      _self.device = new Device();
      _self.location = new Location();
      _self.user = new User(defaultConfig, logger);
      _self.telemetryTrace = new TelemetryTrace(undefined, undefined, undefined, logger);
      _self.session = new Session();
    }
    _self.appId = function () {
      return null;
    };
  }
  TelemetryContext.prototype.applySessionContext = function (event, itemCtx) {
    var session = this.session;
    var sessionManager = this.sessionManager;
    var sessionContext = session || sessionManager && sessionManager.automaticSession;
    if (sessionContext) {
      if (CoreUtils.isString(sessionContext.id)) {
        event.ext.app.sesId = sessionContext.id;
      }
    }
    if (session) {
      // If customer set session info, apply his context; otherwise apply context automatically generated
      if (CoreUtils.isString(session.id)) {
        event.ext.app.sesId = session.id;
      } else {
        event.ext.app.sesId = sessionManager.automaticSession.id;
      }
    }
  };
  TelemetryContext.prototype.applyOperatingSystemContxt = function (event, itemCtx) {
    var os = this.os;
    if (os && os.name) {
      event.ext.os = os;
    }
  };
  TelemetryContext.prototype.applyApplicationContext = function (event, itemCtx) {
    var application = this.application;
    if (application) {
      if (CoreUtils.isString(application.ver)) {
        event.tags[CtxTagKeys.applicationVersion] = application.ver;
      }
      if (CoreUtils.isString(application.build)) {
        event.tags[CtxTagKeys.applicationBuild] = application.build;
      }
    }
  };
  TelemetryContext.prototype.applyDeviceContext = function (event, itemCtx) {
    var device = this.device;
    if (device) {
      if (CoreUtils.isString(device.id)) {
        event.ext.device.localId = device.id;
      }
      if (CoreUtils.isString(device.ip)) {
        event.ext.device.ip = device.ip;
      }
      if (CoreUtils.isString(device.model)) {
        event.ext.device.model = device.model;
      }
      if (CoreUtils.isString(device.deviceClass)) {
        event.ext.device.deviceClass = device.deviceClass;
      }
    }
  };
  TelemetryContext.prototype.applyInternalContext = function (event, itemCtx) {
    var internal = this.internal;
    if (internal) {
      if (CoreUtils.isString(internal.agentVersion)) {
        event.tags[CtxTagKeys.internalAgentVersion] = internal.agentVersion; // not mapped in CS 4.0
      }
      if (CoreUtils.isString(internal.sdkVersion)) {
        event.tags[CtxTagKeys.internalSdkVersion] = internal.sdkVersion;
      }
      if (event.baseType === _InternalLogMessage.dataType || event.baseType === PageView.dataType) {
        if (CoreUtils.isString(internal.snippetVer)) {
          event.tags[CtxTagKeys.internalSnippet] = internal.snippetVer;
        }
        if (CoreUtils.isString(internal.sdkSrc)) {
          event.tags[CtxTagKeys.internalSdkSrc] = internal.sdkSrc;
        }
      }
    }
  };
  TelemetryContext.prototype.applyLocationContext = function (event, itemCtx) {
    var location = this.location;
    if (location) {
      if (CoreUtils.isString(location.ip)) {
        event.tags[CtxTagKeys.locationIp] = location.ip;
      }
    }
  };
  TelemetryContext.prototype.applyOperationContext = function (event, itemCtx) {
    var telemetryTrace = this.telemetryTrace;
    if (telemetryTrace) {
      var trace = event.ext.trace || {
        traceID: undefined,
        parentID: undefined
      };
      if (CoreUtils.isString(telemetryTrace.traceID)) {
        trace.traceID = telemetryTrace.traceID;
      }
      if (CoreUtils.isString(telemetryTrace.name)) {
        trace.name = telemetryTrace.name;
      }
      if (CoreUtils.isString(telemetryTrace.parentID)) {
        trace.parentID = telemetryTrace.parentID;
      }
      event.ext.trace = trace;
    }
  };
  TelemetryContext.prototype.applyWebContext = function (event, itemCtx) {
    var web = this.web;
    if (web) {
      event.ext.web = event.ext.web || {};
      event.ext.web = web;
    }
  };
  TelemetryContext.prototype.applyUserContext = function (event, itemCtx) {
    var user = this.user;
    if (user) {
      if (!event.tags) {
        event.tags = [];
      }
      // stays in tags
      if (CoreUtils.isString(user.accountId)) {
        event.tags[CtxTagKeys.userAccountId] = user.accountId;
      }
      // CS 4.0
      if (CoreUtils.isString(user.id)) {
        event.ext.user.id = user.id;
      }
      if (CoreUtils.isString(user.authenticatedId)) {
        event.ext.user.authId = user.authenticatedId;
      }
    }
  };
  TelemetryContext.prototype.cleanUp = function (event, itemCtx) {
    if (event.ext[Extensions.DeviceExt] && CoreUtils.objKeys(event.ext[Extensions.DeviceExt]).length === 0) {
      delete event.ext[Extensions.DeviceExt];
    }
    if (event.ext[Extensions.UserExt] && CoreUtils.objKeys(event.ext[Extensions.UserExt]).length === 0) {
      delete event.ext[Extensions.UserExt];
    }
    if (event.ext[Extensions.WebExt] && CoreUtils.objKeys(event.ext[Extensions.WebExt]).length === 0) {
      delete event.ext[Extensions.WebExt];
    }
    if (event.ext[Extensions.OSExt] && CoreUtils.objKeys(event.ext[Extensions.OSExt]).length === 0) {
      delete event.ext[Extensions.OSExt];
    }
    if (event.ext[Extensions.AppExt] && CoreUtils.objKeys(event.ext[Extensions.AppExt]).length === 0) {
      delete event.ext[Extensions.AppExt];
    }
    if (event.ext[Extensions.TraceExt] && CoreUtils.objKeys(event.ext[Extensions.TraceExt]).length === 0) {
      delete event.ext[Extensions.TraceExt];
    }
  };
  return TelemetryContext;
}();
export { TelemetryContext };
