import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VersionService {
  //#region Constructor
  public constructor(
    private readonly _httpClient: HttpClient
  ) { }
  //#endregion

  //#region Private properties
  private readonly _versionControllerUrl: string = "/api/version";
  //#endregoin

  //#region Public methods
  public get$(): Observable<string> {
    return this._httpClient.get(this._versionControllerUrl, { responseType: 'text' });
  }
  //#endregion
}
