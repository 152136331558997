<div class="p-grid p-dir-col" *ngIf="(quotationID$ | async) as quotationID">
  <div class="p-col">
    <vila-project-header #vilaProjectHeader [quotationID]="quotationID" displayYearsNavigation="true"
                         [disableYearsNavigation]="isEditing || isSubmitting" (onYearChanged)="onYearChanged($event)">
    </vila-project-header>
  </div>

  <div class="p-col">
    <vila-loader i18n-message="@@MessageLoadingPrognosis" message="MessageLoadingPrognosis text is missing"
                 *ngIf="isLoading">
    </vila-loader>

    <p-table *ngIf="(prognosisView$ | async) as prognosisView"
             [value]="[prognosisView.proceedsPrognosisIntegrationGroupLines, prognosisView.costsPrognosisIntegrationGroupLines]">
      <ng-template pTemplate="header">
        <tr>
          <th></th>
          <th colspan="3" i18n="@@HeaderBudget">HeaderBudget text is missing</th>
          <th colspan="2" i18n="@@HeaderRealisationUntil">HeaderRealisationUntil text is missing</th>
          <th colspan="3" i18n="@@HeaderPrognosis">HeaderPrognosis text is missing</th>
          <th></th>
        </tr>
        <tr>
          <th></th>
          <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th></th>
          <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th i18n="@@HeaderNumber">HeaderNumber text is missing</th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th colspan="2"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-prognosisIntegrationGroupLines>
        <tr class="divider"></tr>

        <tr class="sub-header">
          <td>{{ getSubSectionHeader(prognosisIntegrationGroupLines) }}</td>
          <td></td>
          <td class="amount-holder">
            {{ getFormattedNumber(getBudgetTotalAmount(prognosisIntegrationGroupLines)) }}
          </td>
          <td colspan="2"></td>
          <td class="amount-holder">
            {{ getFormattedNumber(getRealisationTotalAmount(prognosisIntegrationGroupLines)) }}
          </td>
          <td></td>
          <td class="amount-holder">
            {{ getFormattedNumber(getPrognosisTotalAmount(prognosisIntegrationGroupLines)) }}
          </td>
          <td colspan="2"></td>
        </tr>

        <tr *ngFor="let prognosisIntegrationGroupLine of prognosisIntegrationGroupLines" class="sub-section">
          <td class="row-name-holder">{{ prognosisIntegrationGroupLine.name }}</td>
          <td class="number-holder">
            <ng-container *ngIf="prognosisIntegrationGroupLine.isPersonnel">
              {{ getFormattedNumber(prognosisIntegrationGroupLine.budget.number) }}
            </ng-container>
          </td>
          <td class="amount-holder"> {{ getFormattedNumber(prognosisIntegrationGroupLine.budget.amount) }} </td>
          <td class="icon-button-holder single-icon">
            <p-button icon="pi pi-info" type="button" (onClick)="overlayPanelBudget.toggle($event)"
                      [disabled]="!canOpenRemarks(prognosisIntegrationGroupLine.budget)"
                      *ngIf="!prognosisIntegrationGroupLine.isCosts">
            </p-button>
            <p-overlayPanel #overlayPanelBudget appendTo="body">
              {{ prognosisIntegrationGroupLine.budget.remarks }}
            </p-overlayPanel>
          </td>
          <td class="number-holder">
            <ng-container *ngIf="prognosisIntegrationGroupLine.isPersonnel">
              {{ getFormattedNumber(prognosisIntegrationGroupLine.realisation.number) }}
            </ng-container>
          </td>
          <td class="amount-holder">
            {{ getFormattedNumber(prognosisIntegrationGroupLine.realisation.amount) }}
          </td>
          <td class="number-holder">
            <ng-container *ngIf="prognosisIntegrationGroupLine.isPersonnel">
              {{ getFormattedNumber(prognosisIntegrationGroupLine.prognosis.number) }}
            </ng-container>
          </td>
          <td [ngClass]="{'input-cell' : prognosisIntegrationGroupLine.prognosis.isEditing}" class="amount-holder">
            <p-inputNumber *ngIf="prognosisIntegrationGroupLine.prognosis.isEditing"
                           [(ngModel)]="prognosisIntegrationGroupLine.prognosis.amount" locale="nl-NL" [maxFractionDigits]="0"
                           [disabled]="isSubmitting"
                           (onInput)="onAmountInputChanged(prognosisIntegrationGroupLine.prognosis, $event.value)" [step]="1000"
                           [min]="0">
            </p-inputNumber>
            <ng-container *ngIf="!prognosisIntegrationGroupLine.prognosis.isEditing">
              {{ getFormattedNumber(prognosisIntegrationGroupLine.prognosis.amount) }}
            </ng-container>
          </td>
          <td class="icon-button-holder single-icon">
            <p-button icon="pi pi-info" type="button" (onClick)="overlayPanelPrognosis.toggle($event)"
                      [disabled]="!canOpenRemarks(prognosisIntegrationGroupLine.prognosis)"
                      *ngIf="!prognosisIntegrationGroupLine.isCosts">
            </p-button>
            <p-overlayPanel #overlayPanelPrognosis appendTo="body">
              <textarea *ngIf="prognosisIntegrationGroupLine.prognosis.isEditing" pInputTextarea type="text"
                        [(ngModel)]="prognosisIntegrationGroupLine.prognosis.remarks" maxlength="255"></textarea>
              <ng-container *ngIf="!prognosisIntegrationGroupLine.prognosis.isEditing">
                {{ prognosisIntegrationGroupLine.prognosis.remarks }}
              </ng-container>
            </p-overlayPanel>
          </td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-user-edit" type="button" *ngIf="prognosisIntegrationGroupLine.isPersonnel"
                      (onClick)="onShowPrognosisPersonnelDetailsView()" [disabled]="isSubmitting || isEditing">
            </p-button>
            <p-button icon="pi pi-list" type="button"
                      *ngIf="prognosisIntegrationGroupLine.isCosts && !prognosisIntegrationGroupLine.isPersonnel"
                      (onClick)="onShowPrognosisCostsDetailsView(prognosisIntegrationGroupLine)"
                      [disabled]="isSubmitting || isEditing">
            </p-button>
            <p-button icon="pi pi-pencil" type="button" *ngIf="showRowEditInitButton(prognosisIntegrationGroupLine)"
                      (onClick)="onRowEditInit(prognosisIntegrationGroupLine.prognosis)" [disabled]="isSubmitting">
            </p-button>
            <p-button icon="pi pi-check" type="button"
                      *ngIf="showRowEditConfirmButton(prognosisIntegrationGroupLine.prognosis)"
                      (onClick)="onRowEditConfirm(prognosisIntegrationGroupLine.prognosis, quotationID)"
                      [disabled]="disableRowEditConfirmButton(prognosisIntegrationGroupLine.prognosis)">
            </p-button>
            <p-button icon="pi pi-spin pi-spinner"
                      *ngIf="showRowEditSpinnerButton(prognosisIntegrationGroupLine.prognosis)" class="no-pointer">
            </p-button>
            <p-button icon="pi pi-trash" type="button" *ngIf="showRowEditClearButton(prognosisIntegrationGroupLine)"
                      (onClick)="onRowEditClear(prognosisIntegrationGroupLine.prognosis, quotationID)"
                      [disabled]="disableRowEditClearButton(prognosisIntegrationGroupLine.prognosis)">
            </p-button>
            <p-button icon="pi pi-times" type="button"
                      *ngIf="showRowEditCancelButton(prognosisIntegrationGroupLine.prognosis)"
                      (onClick)="onRowEditCancel(prognosisIntegrationGroupLine.prognosis)" [disabled]="isSubmitting"></p-button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr class="divider"></tr>

        <tr class="sub-header">
          <td i18n="@@HeaderBalance">HeaderBalance text is missing</td>
          <td></td>
          <td class="amount-holder">
            {{
 getFormattedNumber(getBudgetTotalAmount(prognosisView.proceedsPrognosisIntegrationGroupLines) -
            getBudgetTotalAmount(prognosisView.costsPrognosisIntegrationGroupLines))
            }}
          </td>
          <td colspan="2"></td>
          <td class="amount-holder">
            {{
 getFormattedNumber(getRealisationTotalAmount(prognosisView.proceedsPrognosisIntegrationGroupLines) -
            getRealisationTotalAmount(prognosisView.costsPrognosisIntegrationGroupLines))
            }}
          </td>
          <td></td>
          <td class="amount-holder">
            {{
 getFormattedNumber(getBalancePrognosisTotalAmount(prognosisView.proceedsPrognosisIntegrationGroupLines
              , prognosisView.costsPrognosisIntegrationGroupLines))
            }}
          </td>
          <td colspan="2"></td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col">
    <div class="p-d-inline-flex">
      <p-button type="button" (onClick)="onReturnToProject()" [disabled]="isSubmitting || isEditing"
                i18n-label="@@ButtonReturn" label="ButtonReturn text is missing">
      </p-button>
    </div>

    <ng-container *ngIf="(prognosisView$ | async) as prognosisView">
      <ng-container *ngIf="quotationIsActual(prognosisView)">
        <div class="p-d-inline-flex">
          <vila-load-button label="ButtonCreateStartingYear text is missing" i18n-label="@@ButtonCreateStartingYear"
                            [isLoading]="isCreatingStartingYear" [disabled]="disableCreateStartingYearButton(prognosisView)"
                            (onClick)="onCreateStartingYear(prognosisView, quotationID)">
          </vila-load-button>
        </div>
        <div class="p-d-inline-flex">
          <vila-load-button label="ButtonCreateSpring text is missing" i18n-label="@@ButtonCreateSpring"
                            [isLoading]="isCreatingPrognosisSpring" [disabled]="disableCreateSpringButton(prognosisView)"
                            (onClick)="onCreateSpring(prognosisView, quotationID)">
          </vila-load-button>
        </div>
        <div class="p-d-inline-flex">
          <vila-load-button label="ButtonCreateAutumn text is missing" i18n-label="@@ButtonCreateAutumn"
                            [isLoading]="isCreatingPrognosisAutumn" [disabled]="disableCreateAutumnButton(prognosisView)"
                            (onClick)="onCreateAutumn(prognosisView, quotationID)">
          </vila-load-button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
