import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProjectDto } from '../models/project-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class ProjectService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _projectControllerUrl: string = "/api/Project";
  //#endregion

  //#region Public methods
  public getByQuotation$(quotationID: string): Observable<ProjectDto> {
    const url: string = `${this._projectControllerUrl}/quotation/${quotationID}`;

    return super.catchErrorResponse(this._httpClient.get<ProjectDto>(url));
  }

  public get$(projectID: string): Observable<ProjectDto> {
    const url: string = `${this._projectControllerUrl}/${projectID}`;

    return super.catchErrorResponse(this._httpClient.get<ProjectDto>(url));
  }
  //#endregion
}
