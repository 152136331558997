<div class="p-grid p-dir-col" *ngIf="(quotationID$ | async) as quotationID">
  <div class="p-col">
    <vila-project-header #vilaProjectHeader [quotationID]="quotationID" displayYearsNavigation="true"
      [disableYearsNavigation]="isEditing || isSubmitting" (onYearChanged)="onYearChanged($event)">
    </vila-project-header>
  </div>

  <div class="p-col" #prognosisCostsHolder>
    <vila-loader i18n-message="@@MessageLoadingCosts" message="MessageLoadingCosts text is missing" *ngIf="isLoading">
    </vila-loader>

    <p-table *ngIf="(prognosisCostsView$ | async) as prognosisCostsView"
      [value]="prognosisCostsView.prognosisCostsItemGroupLines" [scrollable]="true" scrollHeight="35em">
      <ng-template pTemplate="header">
        <tr class="fixed-width-setter">
          <th class="item-id-holder"></th>
          <th class="row-name-holder"></th>
          <th class="amount-holder"></th>
          <th class="icon-button-holder single-icon"></th>
          <th class="amount-holder"></th>
          <th class="amount-holder"></th>
          <th class="icon-button-holder single-icon"></th>
          <th class="icon-button-holder"></th>
        </tr>
        <tr>
          <th colspan="2"></th>
          <th i18n="@@HeaderBudget">HeaderBudget text is missing</th>
          <th></th>
          <th i18n="@@HeaderRealisationUntil">HeaderRealisationUntil text is missing</th>
          <th i18n="@@HeaderPrognosis">HeaderPrognosis text is missing</th>
          <th colspan="2"></th>
        </tr>
        <tr>
          <th colspan="2"></th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th></th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th i18n="@@HeaderAmount">HeaderAmount text is missing</th>
          <th colspan="2"></th>
        </tr>
        <tr class="divider"></tr>
        <tr class="sub-header">
          <th colspan="2" class="row-name-holder">{{ prognosisCostsView.integrationGroupName }}</th>
          <th class="amount-holder">{{
            getFormattedNumber(getBudgetTotalAmount(prognosisCostsView.prognosisCostsItemGroupLines)) }}</th>
          <th></th>
          <th class="amount-holder">{{
            getFormattedNumber(getRealisationTotalAmount(prognosisCostsView.prognosisCostsItemGroupLines)) }}</th>
          <th class="amount-holder">{{
            getFormattedNumber(getPrognosisTotalAmount(prognosisCostsView.prognosisCostsItemGroupLines)) }}</th>
          <th colspan="2"></th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-prognosisCostsItemGroupLine>
        <tr class="sub-sub-header" [ngClass]="{'dummy-row' : prognosisCostsItemGroupLine.id == null}">
          <td class="item-id-holder">{{ prognosisCostsItemGroupLine.id }}</td>
          <td class="row-name-holder">{{ prognosisCostsItemGroupLine.name }}</td>
          <td class="amount-holder"></td>
          <td class="icon-button-holder single-icon"></td>
          <td class="amount-holder">{{ getFormattedNumber(prognosisCostsItemGroupLine.realisationAmount) }}</td>
          <td class="amount-holder"></td>
          <td class="icon-button-holder single-icon"></td>
          <td class="icon-button-holder"></td>
        </tr>

        <tr class="sub-section"
          *ngFor="let prognosisCostsItemLine of prognosisCostsItemGroupLine.prognosisCostsItemLines">
          <td [ngClass]="{'input-cell' : prognosisCostsItemLine.prognosis.isEditing}">
            <p-dropdown *ngIf="isEditingNewPrognosisCostsItemLine(prognosisCostsItemLine)" [options]="items"
              optionLabel="id" (onChange)="onItemChanged($event.value, prognosisCostsItemLine)"
              [disabled]="isSubmitting" [autoDisplayFirst]="false" appendTo="body"></p-dropdown>
          </td>
          <td class="name-holder" [ngClass]="{'input-cell' : canEditPrognosisName(prognosisCostsItemLine)}">
            <input type="text" pInputText *ngIf="canEditPrognosisName(prognosisCostsItemLine)" [disabled]="isSubmitting"
              [(ngModel)]="prognosisCostsItemLine.prognosis.name" />
            <ng-container *ngIf="!canEditPrognosisName(prognosisCostsItemLine)">
              {{ prognosisCostsItemLine.prognosis.name }}
            </ng-container>
          </td>
          <td class="amount-holder">{{ getFormattedNumber(prognosisCostsItemLine.budget.amount) }}</td>
          <td class="icon-button-holder single-icon">
            <p-button icon="pi pi-info" type="button" (onClick)="overlayPanelBudget.toggle($event)"
              [disabled]="!canOpenRemarks(prognosisCostsItemLine.budget)"></p-button>
            <p-overlayPanel #overlayPanelBudget appendTo="body">
              {{ prognosisCostsItemLine.budget.remarks }}
            </p-overlayPanel>
          </td>
          <td class="amount-holder"></td>
          <td class="amount-holder" [ngClass]="{'input-cell' : prognosisCostsItemLine.prognosis.isEditing}">
            <p-inputNumber *ngIf="prognosisCostsItemLine.prognosis.isEditing" [disabled]="isSubmitting"
              [(ngModel)]="prognosisCostsItemLine.prognosis.amount" locale="nl-NL"
              (onInput)="onAmountInputChanged(prognosisCostsItemLine.prognosis, $event.value)" [maxFractionDigits]="0"
              [step]="1000" [min]="0">
            </p-inputNumber>
            <ng-container *ngIf="!prognosisCostsItemLine.prognosis.isEditing">
              {{ getFormattedNumber(prognosisCostsItemLine.prognosis.amount) }}
            </ng-container>
          </td>
          <td class="icon-button-holder single-icon">
            <p-button icon="pi pi-info" type="button" (onClick)="overlayPanelPrognosis.toggle($event)"
              [disabled]="!canOpenRemarks(prognosisCostsItemLine.prognosis)"></p-button>
            <p-overlayPanel #overlayPanelPrognosis appendTo="body">
              <textarea *ngIf="prognosisCostsItemLine.prognosis.isEditing" pInputTextarea type="text"
                [(ngModel)]="prognosisCostsItemLine.prognosis.remarks" maxlength="255"></textarea>
              <ng-container *ngIf="!prognosisCostsItemLine.prognosis.isEditing">
                {{ prognosisCostsItemLine.prognosis.remarks }}
              </ng-container>
            </p-overlayPanel>
          </td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-pencil" type="button" *ngIf="showRowEditInitButton(prognosisCostsItemLine.prognosis)"
              (onClick)="onRowEditInit(prognosisCostsItemLine.prognosis)" [disabled]="isSubmitting">
            </p-button>
            <p-button icon="pi pi-check" type="button"
              *ngIf="showRowEditConfirmButton(prognosisCostsItemLine.prognosis)"
              (onClick)="onRowEditConfirm(prognosisCostsItemLine, quotationID, prognosisCostsView.prognosisCostsItemGroupLines, prognosisCostsItemGroupLine)"
              [disabled]="disableRowEditConfirmButton(prognosisCostsItemLine, prognosisCostsView.prognosisCostsItemGroupLines)">
            </p-button>
            <p-button icon="pi pi-spin pi-spinner" *ngIf="showRowEditSpinnerButton(prognosisCostsItemLine.prognosis)"
              class="no-pointer">
            </p-button>
            <p-button icon="pi pi-trash" type="button" *ngIf="showRowEditClearButton(prognosisCostsItemLine.prognosis)"
              (onClick)="onRowEditClear(prognosisCostsItemLine, prognosisCostsItemGroupLine, quotationID)"
              [disabled]="isSubmitting">
            </p-button>
            <p-button icon="pi pi-times" type="button" *ngIf="showRowEditCancelButton(prognosisCostsItemLine.prognosis)"
              (onClick)="onRowEditCancel(prognosisCostsItemLine, prognosisCostsItemGroupLine)"
              [disabled]="isSubmitting">
            </p-button>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr class="sub-section">
          <td colspan="7"></td>
          <td class="icon-button-holder">
            <p-button icon="pi pi-plus" type="button"
              (onClick)="onAddPrognosisCostsItemLine(prognosisCostsView.prognosisCostsItemGroupLines)"
              [disabled]="disableAddPrognosisCostsItemLine()"></p-button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <div class="p-col">
    <div class="p-d-inline-flex">
      <p-button type="button" (onClick)="onReturnToPrognosis()" [disabled]="isSubmitting || isEditing"
        i18n-label="@@ButtonReturn" label="ButtonReturn text is missing">
      </p-button>
    </div>
  </div>
</div>
