import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, tap } from 'rxjs/operators';
import { VilaMessageService } from 'src/app/services/vila-message.service';
import { ApiError } from 'src/web-api/models/api-error.model';
import { ProgressDto } from 'src/web-api/models/progress-dto.model';
import { ProgressTypeDto } from 'src/web-api/models/progress-type-dto.model';
import { ProgressTypeService } from 'src/web-api/services/progress-type.service';
import { ProgressService } from 'src/web-api/services/progress.service';

@Component({
  selector: 'vila-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgressDialogComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _dynamicDialogRef: DynamicDialogRef,
    private readonly _dynamicDialogConfig: DynamicDialogConfig,
    private readonly _progressService: ProgressService,
    private readonly _progressTypeService: ProgressTypeService,
    private readonly _vilaMessageService: VilaMessageService
  ) { }
  //#endregion

  //#region Public properties
  public progressAndProgressTypes$: Observable<any[]>;
  public isLoading: boolean;
  public isSubmitting: boolean;
  public withinBudgetProgressType: ProgressTypeDto;
  public withinTimeProgressType: ProgressTypeDto;
  public progressProgressType: ProgressTypeDto;
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.initProgressAndProgressTypes();
  }
  //#endregion

  //#region Public methods
  public canSubmit(): boolean {
    return this.withinTimeProgressType != null
      && this.withinBudgetProgressType != null
      && this.progressProgressType != null;
  }

  public onCancel(): void {
    this._dynamicDialogRef.close(false);
  }

  public onSave(progress: ProgressDto): void {
    this.isSubmitting = true;

    progress.progressProgressTypeID = this.progressProgressType.id;
    progress.withinTimeProgressTypeID = this.withinTimeProgressType.id;
    progress.withinBudgetProgressTypeID = this.withinBudgetProgressType.id;

    this._progressService.upsert$(progress, this._dynamicDialogConfig.data.quotationID)
      .pipe(tap(() => this._dynamicDialogRef.close(true)))
      .pipe(finalize(() => this.isSubmitting = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyUpdatingProgressError:ToastBodyUpdatingProgressError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }
  //#endregion

  //#region Private methods
  private getProgress$(): Observable<ProgressDto> {
    let result$: Observable<ProgressDto>;

    if (this._dynamicDialogConfig.data.retrieveExistingProgress) {
      result$ = this._progressService
        .getByQuotation$(this._dynamicDialogConfig.data.quotationID, this._dynamicDialogConfig.data.year)
        .pipe(catchError((apiError: ApiError) => {
          this._vilaMessageService
            .showError(apiError.message ?? $localize`:@@ToastBodyLoadingProgressError:ToastBodyLoadingProgressError text is missing`);

          return of(undefined);
        }));
    } else {
      result$ = of(this._progressService.create(this._dynamicDialogConfig.data.year));
    }

    return result$;
  }

  private getProgressTypes$(): Observable<ProgressTypeDto[]> {
    return this._progressTypeService.list$()
      .pipe(map(progressTypes => progressTypes.sort((progressTypeA, progressTypeB) => progressTypeA.id.localeCompare(progressTypeB.id))))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingProgressTypesError:ToastBodyLoadingProgressTypesError text is missing`);

        return of(undefined);
      }));
  }

  private initProgressAndProgressTypes(): void {
    this.isLoading = true;
    
    this.progressAndProgressTypes$ = forkJoin([this.getProgress$(), this.getProgressTypes$()])
      .pipe(finalize(() => this.isLoading = false))
      .pipe(tap(result => {
        const progress: ProgressDto = result[0];
        const progressTypes: ProgressTypeDto[] = result[1];

        if (progressTypes != null && progress != null) {
          this.withinBudgetProgressType = progressTypes.find(progressType => progressType.id === progress.withinBudgetProgressTypeID);
          this.withinTimeProgressType = progressTypes.find(progressType => progressType.id === progress.withinTimeProgressTypeID);
          this.progressProgressType = progressTypes.find(progressType => progressType.id === progress.progressProgressTypeID);
        }
      }))
      .pipe(shareReplay(1));
  }
  //#endregion
}