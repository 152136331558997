<div class="p-grid p-dir-col">
  <div class="p-col">
    <vila-department-header [disableHeader]="isActual" (onYearChanged)="onYearChanged($event)" (onManagerChanged)="onManagerChanged($event)">
    </vila-department-header>
  </div>

  <vila-loader i18n-message="@@MessageLoadingDeclarability" message="MessageLoadingDeclarability text is missing"
               *ngIf="isLoading">
  </vila-loader>

  <ng-container *ngIf="(declarabilityEmployeeLines$ | async) as employeeLines">
    <div class="p-col">
      <p-table [value]="employeeLines" [paginator]="true"
               [rows]="10" [showCurrentPageReport]="true" [currentPageReportTemplate]="currentPageReportTemplate" tableStyleClass="smaller-font-size">
          <ng-template pTemplate="caption">
              <div class="p-d-flex">
                  <button type="button" pButton pRipple icon="pi pi-file-excel" (click)="exportExcel(employeeLines)" class="p-button-success p-mr-2" pTooltip="Excel" tooltipPosition="bottom"></button>
              </div>
          </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th i18n="@@HeaderEmployeeName">HeaderEmployeeName text is missing</th>
            <th i18n="@@HeaderContractHours">HeaderContractHours text is missing</th>
            <th i18n="@@HeaderYearContractHours">HeaderYearContractHours text is missing</th>
            <th i18n="@@HeaderExtraDirectHours">HeaderExtraDirectHours text is missing</th>
            <th i18n="@@HeaderExtraLeaveHours">HeaderExtraLeaveHours text is missing</th>
            <th i18n="@@HeaderPregnancyLeaveHours">HeaderPregnancyLeaveHours text is missing</th>
            <th i18n="@@HeaderParentalLeaveHours">HeaderParentalLeaveHours text is missing</th>
            <th i18n="@@HeaderIllnessHours">HeaderIllnessHours text is missing</th>
            <th i18n="@@HeaderWorkingHours">HeaderWorkingHours text is missing</th>
            <th i18n="@@HeaderIndirectHoursWorkCouncil">HeaderIndirectHoursWorkCouncil text is missing</th>
            <th i18n="@@HeaderIndirectHoursInternal">HeaderIndirectHoursInternal text is missing</th>
            <th i18n="@@HeaderIndirectHoursOther">HeaderIndirectHoursOther text is missing</th>
            <th i18n="@@HeaderRealisationHours">HeaderRealisationHours text is missing</th>
            <th i18n="@@HeaderPlannedHours">HeaderPlannedHours text is missing</th>
            <th i18n="@@HeaderToBePlannedHours">HeaderToBePlannedHours text is missing</th>
            <th i18n="@@HeaderDeclarability">HeaderDeclarability text is missing</th>
            <th i18n="@@HeaderProceed">HeaderProceed text is missing</th>
            <th i18n="@@HeaderHoursTimesRate">HeaderHoursTimesRate text is missing</th>
            <th></th>
          </tr>

          <tr class="divider"></tr>

          <tr class="sub-header">
            <th i18n="@@HeaderTotal">HeaderTotal text is missing</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalContractHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalYearContractHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalExtraDirectHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalExtraLeaveHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalPregnancyLeaveHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalParentalLeaveHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalIllnessHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalWorkingHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalIndirectHours1(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalIndirectHours2(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalIndirectHours3(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalRealisationHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalPlannedHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalToBePlannedHours(employeeLines)) }}</th>
            <th class="amount-holder">{{ getTotalDeclarability(employeeLines) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalPrognosis(employeeLines)) }}</th>
            <th class="amount-holder">{{ getFormattedNumber(getTotalHoursTimesRate(employeeLines)) }}</th>
            <th></th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-employee>
          <tr class="sub-section">
            <td pTooltip="{{ getTooltipText(employee) }}">{{ employee.name }}</td>
            <td class="amount-holder">{{ employee.contractHours }}</td>
            <td class="amount-holder">{{ getFormattedNumber(employee.yearContractHours) }}</td>
            <td [ngClass]="{'input-cell' : isActual}" class="amount-holder">
              <p-inputNumber *ngIf="isActual" [(ngModel)]="employee.extraDirectHours" locale="nl-NL"
                             [maxFractionDigits]="0" [disabled]="isSubmitting" [step]="8">
              </p-inputNumber>
              <ng-container class="amount-holder" *ngIf="!isActual">
                {{ employee.extraDirectHours }}
              </ng-container>
            </td>
            <td class="amount-holder">{{ employee.extraLeaveHours }}</td>
            <td [ngClass]="{'input-cell' : isActual}" class="amount-holder">
              <p-inputNumber *ngIf="isActual" [(ngModel)]="employee.pregnancyLeaveHours" locale="nl-NL"
                             [maxFractionDigits]="0" [disabled]="isSubmitting" [step]="8" [min]="0">
              </p-inputNumber>
              <ng-container class="amount-holder" *ngIf="!isActual">
                {{ employee.pregnancyLeaveHours }}
              </ng-container>
            </td>
            <td [ngClass]="{'input-cell' : isActual}" class="amount-holder">
              <p-inputNumber *ngIf="isActual" [(ngModel)]="employee.parentalLeaveHours" locale="nl-NL"
                             [maxFractionDigits]="0" [disabled]="isSubmitting" [step]="8" [min]="0">
              </p-inputNumber>
              <ng-container class="amount-holder" *ngIf="!isActual">
                {{ employee.parentalLeaveHours }}
              </ng-container>
            </td>
            <td [ngClass]="{'input-cell' : isActual}" class="amount-holder">
              <p-inputNumber *ngIf="isActual" [(ngModel)]="employee.illnessHours" locale="nl-NL" [maxFractionDigits]="0"
                             [disabled]="isSubmitting" [step]="8" [min]="0">
              </p-inputNumber>
              <ng-container class="amount-holder" *ngIf="!isActual">
                {{ employee.illnessHours }}
              </ng-container>
            </td>
            <td class="amount-holder">{{ getFormattedNumber(getEmployeeWorkingHours(employee)) }}</td>
            <td [ngClass]="{'input-cell' : isActual}" class="amount-holder">
              <p-inputNumber *ngIf="isActual" [(ngModel)]="employee.indirectHours1" locale="nl-NL"
                             [maxFractionDigits]="0" [disabled]="isSubmitting" [step]="8" [min]="0">
              </p-inputNumber>
              <ng-container class="amount-holder" *ngIf="!isActual">
                {{ getFormattedNumber(employee.indirectHours1) }}
              </ng-container>
            </td>
            <td [ngClass]="{'input-cell' : isActual}" class="amount-holder">
              <p-inputNumber *ngIf="isActual" [(ngModel)]="employee.indirectHours2" locale="nl-NL"
                             [maxFractionDigits]="0" [disabled]="isSubmitting" [step]="8" [min]="0">
              </p-inputNumber>
              <ng-container class="amount-holder" *ngIf="!isActual">
                {{ getFormattedNumber(employee.indirectHours2) }}
              </ng-container>
            </td>
            <td [ngClass]="{'input-cell' : isActual}" class="amount-holder">
              <p-inputNumber *ngIf="isActual" [(ngModel)]="employee.indirectHours3" locale="nl-NL"
                             [maxFractionDigits]="0" [disabled]="isSubmitting" [step]="8" [min]="0">
              </p-inputNumber>
              <ng-container class="amount-holder" *ngIf="!isActual">
                {{ getFormattedNumber(employee.indirectHours3) }}
              </ng-container>
            </td>
            <td class="amount-holder">{{ getFormattedNumber(getEmployeeRealisationHours(employee)) }}</td>
            <td class="amount-holder">{{ getFormattedNumber(employee.plannedHours) }}</td>
            <td class="amount-holder">{{ getFormattedNumber(getEmployeeToBePlannedHours(employee)) }}</td>
            <td class="amount-holder">{{ getEmployeeDeclarabilityPercentage(employee) }}</td>
            <td class="amount-holder">{{ getFormattedNumber(getEmployeePossibleRevenue(employee)) }}</td>
            <td class="amount-holder">{{ getFormattedNumber(employee.hoursTimesRate) }}</td>
            <td class="icon-button-holder single-icon">
              <p-button icon="pi pi-info" type="button" (onClick)="overlayEmployeeRemarks.toggle($event)">
              </p-button>
              <p-overlayPanel #overlayEmployeeRemarks appendTo="body">
                <textarea pInputTextarea *ngIf="isActual" type="text" [(ngModel)]="employee.remarks"
                          maxlength="255"></textarea>
                <ng-container class="amount-holder" *ngIf="!isActual">
                  {{ employee.remarks }}
                </ng-container>
              </p-overlayPanel>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>

    <div class="p-col">
      <div class="p-d-flex p-jc-between">
        <p-button type="button" [disabled]="!isActual || isSubmitting || isLoading" i18n-label="@@ButtonCancel"
                  label="ButtonCancel text is missing" (onClick)="onCancel()">
        </p-button>
        <p-button type="button" [disabled]="isActual || isSubmitting || isLoading" i18n-label="@@ButtonLoadActual"
                  label="ButtonLoadActual text is missing" (onClick)="onLoadActual()">
        </p-button>
        <vila-load-button [disabled]="!isActual ||  isLoading" i18n-label="@@ButtonSave" [isLoading]="isSubmitting"
                          label="ButtonSave text is missing" (onClick)="onSave()">
        </vila-load-button>
      </div>
    </div>
  </ng-container>
</div>
