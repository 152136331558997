import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, of } from 'rxjs';
import { take, map, shareReplay, catchError, finalize, switchMap, tap, filter } from 'rxjs/operators';
import { ApiError } from 'src/web-api/models/api-error.model';
import { PrognosisIntegrationGroupLineDto } from 'src/web-api/models/prognosis/prognosis-integration-group-line-dto.model';
import { PrognosisViewDto } from 'src/web-api/models/prognosis/prognosis-view-dto.model';
import { PrognosisViewService } from 'src/web-api/services/prognosis-view.service';
import { YearAmountAndNumberDto } from 'src/web-api/models/year-amount-and-number-dto.model';
import { YearAmountAndNumberService } from 'src/web-api/services/year-amount-and-number.service';
import { NumberService } from '../services/number.service';
import { VilaMessageService } from '../services/vila-message.service';
import { RouteParameter } from '../statics/route-parameter';
import { RouteSegment } from '../statics/route-segment';
import { QuotationService } from 'src/web-api/services/quotation.service';
import { QuotationType } from 'src/web-api/enums/quotation-type.enum';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProgressDialogComponent } from '../shared-components/progress-dialog/progress-dialog.component';
import { ProjectHeaderComponent } from '../shared-components/project-header/project-header.component';

@Component({
  selector: 'vila-prognosis-view',
  templateUrl: './prognosis-view.component.html',
  styleUrls: ['./prognosis-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PrognosisViewComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _dialogService: DialogService,
    private readonly _confirmationService: ConfirmationService,
    private readonly _numberService: NumberService,
    private readonly _prognosisViewService: PrognosisViewService,
    private readonly _quotationService: QuotationService,
    private readonly _router: Router,
    private readonly _vilaMessageService: VilaMessageService,
    private readonly _yearAmountAndNumberService: YearAmountAndNumberService
  ) { }
  //#endregion

  //#region Private properties
  private _headerCostsText: string = $localize`:@@HeaderCosts:HeaderCosts text is missing`;
  private _headerEnterProgressForText: string = $localize`:@@HeaderEnterProgressFor:HeaderEnterProgressFor text is missing`;
  private _headerProceedsText: string = $localize`:@@HeaderProceeds:HeaderProceeds text is missing`;
  private _toastBodyUpdatingPrognosisIntegrationGroupLineErrorText: string =
    $localize`:@@ToastBodyUpdatingPrognosisIntegrationGroupLineError:ToastBodyUpdatingBudgetPrognosisIntegrationGroupLineError text is missing`;
  private _year: number;
  private _originalTotalYearBalance: number;
  private _prognosisIntegrationGroupLines: { proceeds: PrognosisIntegrationGroupLineDto[], costs: PrognosisIntegrationGroupLineDto[] };
  //#endregion

  //#region Public properties
  @ViewChild('vilaProjectHeader') private projectHeaderComponent: ProjectHeaderComponent;

  public isCreatingPrognosisAutumn: boolean;
  public isCreatingPrognosisSpring: boolean;
  public isCreatingStartingYear: boolean;
  public isEditing: boolean;
  public isLoading: boolean;
  public isSubmitting: boolean;
  public prognosisView$: Observable<PrognosisViewDto>;
  public quotationID$: Observable<string>;
  //#endregion

  //#region Lifecycle hooks
  public ngOnInit(): void {
    this.quotationID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.QUOTATION_ID)))
      .pipe(shareReplay(1));
  }
  //#endregion

  //#region General methods
  public disableCreateAutumnButton(prognosisView: PrognosisViewDto): boolean {
    return this.isEditing || this.isSubmitting || !prognosisView.canCreateAutumnPrognosis;
  }

  public disableCreateSpringButton(prognosisView: PrognosisViewDto): boolean {
    return this.isEditing || this.isSubmitting || !prognosisView.canCreateSpringPrognosis;
  }

  public disableCreateStartingYearButton(prognosisView: PrognosisViewDto): boolean {
    return this.isEditing || this.isSubmitting || !prognosisView.canCreateStartingYear;
  }

  public getFormattedNumber(number: number): string {
    return this._numberService.getFormattedNumber(number);
  }

  public getBalancePrognosisTotalAmount(
    proceedsPrognosisIntegrationGroupLines: PrognosisIntegrationGroupLineDto[],
    costsPrognosisIntegrationGroupLines: PrognosisIntegrationGroupLineDto[]): number {

    return this.getPrognosisTotalAmount(proceedsPrognosisIntegrationGroupLines) - this.getPrognosisTotalAmount(costsPrognosisIntegrationGroupLines);
  }

  public getBudgetTotalAmount(prognosisIntegrationGroupLines: PrognosisIntegrationGroupLineDto[]): number {
    return prognosisIntegrationGroupLines
      .reduce((accumulator, integrationGroupPrognosis) => accumulator + integrationGroupPrognosis.budget.amount, 0);
  }

  public getPrognosisTotalAmount(prognosisIntegrationGroupLines: PrognosisIntegrationGroupLineDto[]): number {
    return prognosisIntegrationGroupLines
      .reduce((accumulator, integrationGroupPrognosis) => accumulator + integrationGroupPrognosis.prognosis.amount, 0);
  }

  public getRealisationTotalAmount(prognosisIntegrationGroupLines: PrognosisIntegrationGroupLineDto[]): number {
    return prognosisIntegrationGroupLines
      .reduce((accumulator, integrationGroupPrognosis) => accumulator + integrationGroupPrognosis.realisation.amount, 0);
  }

  public getSubSectionHeader(prognosisIntegrationGroupLines: PrognosisIntegrationGroupLineDto[]): string {
    return prognosisIntegrationGroupLines[0] != null && prognosisIntegrationGroupLines[0].isCosts
      ? this._headerCostsText
      : this._headerProceedsText;
  }

  public onCreateAutumn(prognosisView: PrognosisViewDto, quotationID: string): void {
    this._getProgressDialogRef(quotationID).onClose
      .pipe(filter((progressIsSaved: boolean) => progressIsSaved))
      .subscribe(() => this._createAutumn(prognosisView, quotationID));
  }

  public onCreateSpring(prognosisView: PrognosisViewDto, quotationID: string): void {
    this._getProgressDialogRef(quotationID).onClose
      .pipe(filter((progressIsSaved: boolean) => progressIsSaved))
      .subscribe(() => this._createSpring(prognosisView, quotationID));
  }

  public onCreateStartingYear(prognosisView: PrognosisViewDto, quotationID: string): void {
    this._confirmationService.confirm({
      header: $localize`:@@ConfirmHeaderCreateStartingYear:ConfirmHeaderCreateStartingYear text is missing`,
      message: $localize`:@@ConfirmBodyCreateStartingYear:ConfirmBodyCreateStartingYear text is missing`,
      accept: () => this._createStartingYear(prognosisView, quotationID)
    });
  }

  public onReturnToProject(): void {
    this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
  }

  public onYearChanged(year: number): void {
    this.isLoading = true;

    this._year = undefined;
    this.projectHeaderComponent.resetShownBalanceOffset();

    this.prognosisView$ = this.quotationID$
      .pipe(switchMap(quotationID => this._prognosisViewService.getByQuotation$(quotationID, year)))
      .pipe(tap(prognosisView => {
        prognosisView.proceedsPrognosisIntegrationGroupLines = this._getSortedPrognosisIntegrationGroupLines(prognosisView.proceedsPrognosisIntegrationGroupLines);
        prognosisView.costsPrognosisIntegrationGroupLines = this._getSortedPrognosisIntegrationGroupLines(prognosisView.costsPrognosisIntegrationGroupLines);
      }))
      .pipe(tap(prognosisView => {
        this._year = year;
        this._prognosisIntegrationGroupLines = {
          proceeds: prognosisView.proceedsPrognosisIntegrationGroupLines,
          costs: prognosisView.costsPrognosisIntegrationGroupLines
        };
        this._originalTotalYearBalance = this.getBalancePrognosisTotalAmount(this._prognosisIntegrationGroupLines.proceeds, this._prognosisIntegrationGroupLines.costs);
      }))
      .pipe(finalize(() => this.isLoading = false))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingPrognosisError:ToastBodyLoadingPrognosisError text is missing`);

        return of(undefined);
      }))
      .pipe(shareReplay(1));
  }

  public quotationIsActual(prognosisView: PrognosisViewDto): boolean {
    return prognosisView.quotationType === QuotationType.Actual;
  }
  //#endregion

  //#region Row methods
  public disableRowEditClearButton(prognosis: YearAmountAndNumberDto): boolean {
    return this.isSubmitting || prognosis.amount === 0;
  }

  public disableRowEditConfirmButton(prognosis: YearAmountAndNumberDto): boolean {
    return this.isSubmitting
      || (prognosis.amount === prognosis.original.amount
        && (prognosis.remarks === prognosis.original.remarks
          || prognosis.remarks.length === 0 && prognosis.original.remarks == null));
  }

  public canOpenRemarks(yearAmountAndNumber: YearAmountAndNumberDto): boolean {
    return yearAmountAndNumber.isEditing || (yearAmountAndNumber.remarks && yearAmountAndNumber.remarks.length > 0);
  }

  public onRowEditCancel(prognosis: YearAmountAndNumberDto): void {
    prognosis.amount = prognosis.original.amount;
    prognosis.remarks = prognosis.original.remarks;

    this._updateHeader();

    this.isEditing = false;

    prognosis.isEditing = false;
  }

  public onRowEditClear(prognosis: YearAmountAndNumberDto, quotationID: string): void {
    this._confirmationService.confirm({
      header: $localize`:@@ConfirmHeaderDeleteData:ConfirmHeaderDeleteData text is missing`,
      message: $localize`:@@ConfirmBodyClearPrognosisIntegrationGroupLine:ConfirmBodyClearPrognosisIntegrationGroupLine text is missing`,
      accept: () => {
        this.isSubmitting = true;
        prognosis.isSubmitting = true;

        this._yearAmountAndNumberService.delete$([prognosis.id], quotationID)
          .pipe(tap(() => {
            prognosis.amount = 0;
            prognosis.id = undefined;
            this._updateHeader();
          }))
          .pipe(finalize(() => {
            this.isSubmitting = false;
            prognosis.isSubmitting = false;
          }))
          .pipe(catchError((apiError: ApiError) => {
            this._vilaMessageService
              .showError(apiError.message ?? this._toastBodyUpdatingPrognosisIntegrationGroupLineErrorText);

            return of(undefined);
          }))
          .subscribe();
      }
    });
  }

  public onRowEditConfirm(prognosis: YearAmountAndNumberDto, quotationID: string): void {
    this.isSubmitting = true;
    prognosis.isSubmitting = true;

    this._yearAmountAndNumberService.upsert$([prognosis], quotationID)
      .pipe(tap(updatedYearAmountAndNumbers => {
        if (prognosis.id == null) {
          prognosis.id = updatedYearAmountAndNumbers[0].id;
        }

        prognosis.original = undefined;

        this.isEditing = false;
        prognosis.isEditing = false;
      }))
      .pipe(finalize(() => {
        this.isSubmitting = false;
        prognosis.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? this._toastBodyUpdatingPrognosisIntegrationGroupLineErrorText);

        return of(undefined);
      }))
      .subscribe();
  }

  public onRowEditInit(prognosis: YearAmountAndNumberDto): void {
    this.isEditing = true;
    prognosis.isEditing = true;

    prognosis.original = { ...prognosis };
  }

  public onAmountInputChanged(prognosis: YearAmountAndNumberDto, inputValue: number): void {
    prognosis.amount = inputValue;
    this._updateHeader();
  }

  public onShowPrognosisCostsDetailsView(prognosisIntegrationGroupLine: PrognosisIntegrationGroupLineDto): void {
    this._router.navigate([`${RouteSegment.COSTS}/${prognosisIntegrationGroupLine.id}`], { relativeTo: this._activatedRoute });
  }

  public onShowPrognosisPersonnelDetailsView(): void {
    this._router.navigate([RouteSegment.PERSONNEL], { relativeTo: this._activatedRoute });
  }

  public showRowEditCancelButton(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.isEditing;
  }

  public showRowEditClearButton(prognosisIntegrationGroupLine: PrognosisIntegrationGroupLineDto): boolean {
    return !prognosisIntegrationGroupLine.isCosts
      && !prognosisIntegrationGroupLine.prognosis.isEditing
      && !prognosisIntegrationGroupLine.prognosis.isSubmitting;
  }

  public showRowEditConfirmButton(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.isEditing
      && !prognosis.isSubmitting;
  }

  public showRowEditInitButton(prognosisIntegrationGroupLine: PrognosisIntegrationGroupLineDto): boolean {
    return !prognosisIntegrationGroupLine.isCosts
      && !prognosisIntegrationGroupLine.prognosis.isEditing;
  }

  public showRowEditSpinnerButton(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.isSubmitting;
  }
  //#endregion

  //#region Private methods
  private _createAutumn(prognosisView: PrognosisViewDto, quotationID: string): void {
    this.isSubmitting = true;
    this.isCreatingPrognosisAutumn = true;

    let updateCall$: Observable<void>;

    updateCall$ = prognosisView.autumnPrognosisQuotationID == null
      ? this._quotationService.createPrognosisAutumn$(quotationID, this._year)
      : this._quotationService.appendToPrognosisAutumn$(quotationID, prognosisView.autumnPrognosisQuotationID, this._year);

    updateCall$
      .pipe(tap(() => prognosisView.canCreateAutumnPrognosis = false))
      .pipe(finalize(() => {
        this.isCreatingPrognosisAutumn = false;
        this.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyCreatingPrognosisAutumnError:ToastBodyCreatingPrognosisAutumnError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }

  private _createSpring(prognosisView: PrognosisViewDto, quotationID: string): void {
    this.isCreatingPrognosisSpring = true;
    this.isSubmitting = true;

    let updateCall$: Observable<void>;

    updateCall$ = prognosisView.springPrognosisQuotationID == null
      ? this._quotationService.createPrognosisSpring$(quotationID, this._year)
      : this._quotationService.appendToPrognosisSpring$(quotationID, prognosisView.springPrognosisQuotationID, this._year);

    updateCall$
      .pipe(tap(() => prognosisView.canCreateSpringPrognosis = false))
      .pipe(finalize(() => {
        this.isCreatingPrognosisSpring = false;
        this.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyCreatingPrognosisSpringError:ToastBodyCreatingPrognosisSpringError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }

  private _createStartingYear(prognosisView: PrognosisViewDto, quotationID: string): void {
    this.isCreatingStartingYear = true;
    this.isSubmitting = true;

    let updateCall$: Observable<void>;

    updateCall$ = prognosisView.startingYearQuotationID == null
      ? this._quotationService.createStartingYear$(quotationID, this._year)
      : this._quotationService.appendToStartingYear$(quotationID, prognosisView.startingYearQuotationID, this._year);

    updateCall$
      .pipe(tap(() => prognosisView.canCreateStartingYear = false))
      .pipe(finalize(() => {
        this.isCreatingStartingYear = false;
        this.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyCreatingStartingYearError:ToastBodyCreatingStartingYearError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }

  private _getProgressDialogRef(quotationID: string): DynamicDialogRef {
    return this._dialogService.open(ProgressDialogComponent, {
      header: `${this._headerEnterProgressForText} ${this._year}`,
      width: '50em',
      data: {
        year: this._year,
        retrieveExistingProgress: false,
        quotationID: quotationID
      }
    });
  }

  private _updateHeader(): void {
    const totalAmount: number = this.getBalancePrognosisTotalAmount(this._prognosisIntegrationGroupLines.proceeds, this._prognosisIntegrationGroupLines.costs);

    // Update the balance shown in the header and update the original.
    this.projectHeaderComponent.updateShownBalance(totalAmount - this._originalTotalYearBalance, true);
    this._originalTotalYearBalance = totalAmount;
  }
  
  private _getSortedPrognosisIntegrationGroupLines(prognosisIntegrationGroupLines: PrognosisIntegrationGroupLineDto[]): PrognosisIntegrationGroupLineDto[] {
    return prognosisIntegrationGroupLines
      .sort((prognosisIntegrationGroupLineA, prognosisIntegrationGroupLineB) => prognosisIntegrationGroupLineA.order - prognosisIntegrationGroupLineB.order);
  }
  //#endregion
}
