import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, of } from 'rxjs';
import { take, map, shareReplay, switchMap, finalize, catchError, tap } from 'rxjs/operators';
import { NumberService } from 'src/app/services/number.service';
import { VilaMessageService } from 'src/app/services/vila-message.service';
import { RouteParameter } from 'src/app/statics/route-parameter';
import { ApiError } from 'src/web-api/models/api-error.model';
import { PrognosisCostsViewDto } from 'src/web-api/models/prognosis/prognosis-costs-view-dto.model';
import { PrognosisCostsItemGroupLineDto } from 'src/web-api/models/prognosis/prognosis-costs-item-group-line-dto.model';
import { PrognosisCostsViewService } from 'src/web-api/services/prognosis-costs-view.service';
import { ItemService } from 'src/web-api/services/item.service';
import { ItemDto } from 'src/web-api/models/item-dto.model';
import { YearAmountAndNumberDto } from 'src/web-api/models/year-amount-and-number-dto.model';
import { ConfirmationService } from 'primeng/api';
import { YearAmountAndNumberService } from 'src/web-api/services/year-amount-and-number.service';
import { PrognosisCostsItemLineDto } from 'src/web-api/models/prognosis/prognosis-costs-item-line-dto.model';
import { PrognosisCostsItemGroupLineService } from 'src/web-api/services/prognosis-costs-item-group-line.service';
import { PrognosisCostsItemLineService } from 'src/web-api/services/prognosis-costs-item-line.service';
import { ProjectHeaderComponent } from 'src/app/shared-components/project-header/project-header.component';

@Component({
  selector: 'vila-prognosis-costs-view',
  templateUrl: './prognosis-costs-view.component.html',
  styleUrls: ['./prognosis-costs-view.component.scss']
})
export class PrognosisCostsViewComponent implements OnInit {
  //#region Constructor
  constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _confirmationService: ConfirmationService,
    private readonly _itemService: ItemService,
    private readonly _numberService: NumberService,
    private readonly _prognosisCostsItemGroupLineService: PrognosisCostsItemGroupLineService,
    private readonly _prognosisCostsItemLineService: PrognosisCostsItemLineService,
    private readonly _prognosisCostsViewService: PrognosisCostsViewService,
    private readonly _router: Router,
    private readonly _vilaMessageService: VilaMessageService,
    private readonly _yearAmountAndNumberService: YearAmountAndNumberService
  ) { }
  //#endregion

  //#region Private properties
  private _integrationGroupID$: Observable<string>;
  private _toastBodyUpdatingPrognosisCostsLineErrorText: string =
    $localize`:@@ToastBodyUpdatingPrognosisCostsLineError:ToastBodyUpdatingPrognosisCostsLineError text is missing`;
  private _currentYear: number;
  private _originalTotalYearBalance: number;
  private _prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[];
  //#endregion

  //#region Public properties
  @ViewChild('prognosisCostsHolder') public prognosisCostsHolder: ElementRef;
  @ViewChild('vilaProjectHeader') private projectHeaderComponent: ProjectHeaderComponent;

  public isEditing: boolean;
  public isLoading: boolean;
  public isSubmitting: boolean;
  public items: ItemDto[];
  public prognosisCostsView$: Observable<PrognosisCostsViewDto>;
  public quotationID$: Observable<string>;
  //#endregion

  //#region Life cycle hooks
  public ngOnInit(): void {
    this.quotationID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.QUOTATION_ID)))
      .pipe(shareReplay(1));

    this._integrationGroupID$ = this._activatedRoute.paramMap
      .pipe(take(1))
      .pipe(map(paramMap => paramMap.get(RouteParameter.INTEGRATION_GROUP_ID)))
      .pipe(shareReplay(1));

    this._integrationGroupID$
      .pipe(switchMap(integrationGroupID => this._itemService.findByIntegrationGroup$(integrationGroupID)))
      .pipe(tap(items => this.items = items))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? $localize`:@@ToastBodyLoadingItemsError:ToastBodyLoadingItemsError text is missing`);

        return of(undefined);
      }))
      .subscribe();
  }
  //#endregion

  //#region General methods
  public disableAddPrognosisCostsItemLine(): boolean {
    return this.isSubmitting || this.items == null || this.items.length === 0;
  }

  public getBudgetTotalAmount(prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[]): number {
    return prognosisCostsItemGroupLines
      .reduce((accumulator, prognosisCostsItemGroupLine) => accumulator + this._getGroupTotalBudgetAmount(prognosisCostsItemGroupLine), 0);
  }

  public getRealisationTotalAmount(prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[]): number {
    return prognosisCostsItemGroupLines
      .reduce((accumulator, prognosisCostsItemGroupLine) => accumulator + prognosisCostsItemGroupLine.realisationAmount, 0);
  }

  public getPrognosisTotalAmount(prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[]): number {
    return prognosisCostsItemGroupLines
      .reduce((accumulator, prognosisCostsItemGroupLine) => accumulator + this._getGroupTotalPrognosisAmount(prognosisCostsItemGroupLine), 0);
  }

  public getFormattedNumber(number: number): string {
    return this._numberService.getFormattedNumber(number);
  }

  public onAddPrognosisCostsItemLine(prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[]): void {
    const dummyPrognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto = this._prognosisCostsItemGroupLineService.create(undefined);

    const newPrognosisCostsItemLine: PrognosisCostsItemLineDto = this._prognosisCostsItemLineService.create(this._currentYear);

    newPrognosisCostsItemLine.prognosis.original = { ...newPrognosisCostsItemLine.prognosis };
    newPrognosisCostsItemLine.prognosis.isEditing = true;

    dummyPrognosisCostsItemGroupLine.prognosisCostsItemLines = [newPrognosisCostsItemLine];

    prognosisCostsItemGroupLines.push(dummyPrognosisCostsItemGroupLine);

    // Go to the bottom of the table.
    const tableScrollableBody: Element = this.prognosisCostsHolder.nativeElement.getElementsByClassName("p-datatable-scrollable-body")[0];

    setTimeout(() => tableScrollableBody.scrollTop = tableScrollableBody.scrollHeight);
  }

  public onReturnToPrognosis(): void {
    this._router.navigate(['../../'], { relativeTo: this._activatedRoute });
  }

  public onYearChanged(year: number): void {
    this.isLoading = true;

    this._currentYear = year;
    this.projectHeaderComponent.resetShownBalanceOffset();

    this.prognosisCostsView$ =
      forkJoin([this.quotationID$, this._integrationGroupID$])
        .pipe(switchMap(result => this._prognosisCostsViewService.getByQuotationAndIntegrationGroupAndYear$(result[0], year, result[1])))
        .pipe(tap(prognosisCostsView => {
          prognosisCostsView.prognosisCostsItemGroupLines = this._getSortedCostsItemGroupsAndLines(prognosisCostsView.prognosisCostsItemGroupLines);
          this._prognosisCostsItemGroupLines = prognosisCostsView.prognosisCostsItemGroupLines;
          this._originalTotalYearBalance = this.getPrognosisTotalAmount(this._prognosisCostsItemGroupLines);
        }))
        .pipe(finalize(() => this.isLoading = false))
        .pipe(catchError((apiError: ApiError) => {
          this._vilaMessageService
            .showError(apiError.message ?? $localize`:@@ToastBodyLoadingCostsError:ToastBodyLoadingCostsError text is missing`);

          return of(undefined);
        }))
        .pipe(shareReplay(1));
  }
  //#endregion

  //#region Row methods
  public canEditPrognosisName(prognosisCostsItemLine: PrognosisCostsItemLineDto): boolean {
    return prognosisCostsItemLine.prognosis.isEditing && prognosisCostsItemLine.budget.id == null;
  }

  public canOpenRemarks(yearAmountAndNumber: YearAmountAndNumberDto): boolean {
    return yearAmountAndNumber.isEditing || (yearAmountAndNumber.remarks && yearAmountAndNumber.remarks.length > 0);
  }

  public disableRowEditConfirmButton(
    prognosisCostsItemLine: PrognosisCostsItemLineDto,
    prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[]
  ): boolean {
    return this.isSubmitting
      || !this._prognosisIsChanged(prognosisCostsItemLine.prognosis)
      || !this._prognosisHasUniqueName(prognosisCostsItemLine.prognosis, prognosisCostsItemGroupLines)
      || this._isNewPrognosisCostsItemLine(prognosisCostsItemLine)
      && !this._prognosisBaseValuesAreFilled(prognosisCostsItemLine.prognosis);
  }

  public isEditingNewPrognosisCostsItemLine(prognosisCostsItemLine: PrognosisCostsItemLineDto): boolean {
    return prognosisCostsItemLine.prognosis.isEditing && this._isNewPrognosisCostsItemLine(prognosisCostsItemLine);
  }

  public onAmountInputChanged(prognosis: YearAmountAndNumberDto, inputValue: number): void {
    prognosis.amount = inputValue;
    this._updateHeader();
  }

  public onItemChanged(item: ItemDto, prognosisCostsItemLine: PrognosisCostsItemLineDto): void {
    prognosisCostsItemLine.prognosis.itemID = item ? item.id : undefined;
    prognosisCostsItemLine.prognosis.name = item ? item.name : undefined;
  }

  public onRowEditCancel(prognosisCostsItemLine: PrognosisCostsItemLineDto, prognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto): void {
    if (this._isNewPrognosisCostsItemLine(prognosisCostsItemLine)) {
      this._removeFromArray(prognosisCostsItemLine, prognosisCostsItemGroupLine.prognosisCostsItemLines);
    } else {
      prognosisCostsItemLine.prognosis.amount = prognosisCostsItemLine.prognosis.original.amount;
      prognosisCostsItemLine.prognosis.remarks = prognosisCostsItemLine.prognosis.original.remarks;

      prognosisCostsItemLine.prognosis.isEditing = false;
    }

    this._updateHeader();

    this.isEditing = false;
  }

  public onRowEditClear(
    prognosisCostsItemLine: PrognosisCostsItemLineDto,
    prognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto,
    quotationID: string
  ): void {
    this._confirmationService.confirm({
      header: $localize`:@@ConfirmHeaderDeleteData:ConfirmHeaderDeleteData text is missing`,
      message: $localize`:@@ConfirmBodyClearPrognosisCostsLine:ConfirmBodyClearPrognosisCostsLine text is missing`,
      accept: () => {
        this.isSubmitting = true;
        prognosisCostsItemLine.prognosis.isSubmitting = true;

        let apiAction: Observable<any>;

        if (prognosisCostsItemLine.budget.id == null) {
          apiAction =
            this._yearAmountAndNumberService.delete$([prognosisCostsItemLine.prognosis.id], quotationID)
              .pipe(tap(() => {
                this._removeFromArray(prognosisCostsItemLine, prognosisCostsItemGroupLine.prognosisCostsItemLines);
                this._updateHeader();
              }));
        } else {
          apiAction =
            this._yearAmountAndNumberService.upsert$([prognosisCostsItemLine.prognosis], quotationID)
              .pipe(tap(() => {
                prognosisCostsItemLine.prognosis.amount = 0;
                prognosisCostsItemLine.prognosis.id = undefined;
                this._updateHeader();
              }));
        }

        apiAction
          .pipe(finalize(() => {
            this.isSubmitting = false;
            prognosisCostsItemLine.prognosis.isSubmitting = false;
          }))
          .pipe(catchError((apiError: ApiError) => {
            this._vilaMessageService
              .showError(apiError.message ?? this._toastBodyUpdatingPrognosisCostsLineErrorText);

            return of(undefined);
          }))
          .subscribe();
      }
    });
  }

  public onRowEditConfirm(
    prognosisCostsItemLine: PrognosisCostsItemLineDto,
    quotationID: string,
    prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[],
    prognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto
  ): void {
    this.isSubmitting = true;
    prognosisCostsItemLine.prognosis.isSubmitting = true;

    this._yearAmountAndNumberService.upsert$([prognosisCostsItemLine.prognosis], quotationID)
      .pipe(tap(updatedYearAmountAndNumbers => {
        if (this.isEditingNewPrognosisCostsItemLine(prognosisCostsItemLine)) {
          const existingPrognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto = prognosisCostsItemGroupLines
            .find(prognosisCostsItemGroupLine => prognosisCostsItemGroupLine.id === prognosisCostsItemLine.prognosis.itemID);

          if (existingPrognosisCostsItemGroupLine != null) {
            existingPrognosisCostsItemGroupLine.prognosisCostsItemLines.push(prognosisCostsItemLine);
          } else {
            const newPrognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto =
              this._prognosisCostsItemGroupLineService.create(prognosisCostsItemLine.prognosis.itemID);

            newPrognosisCostsItemGroupLine.prognosisCostsItemLines = [prognosisCostsItemLine];

            prognosisCostsItemGroupLines.push(newPrognosisCostsItemGroupLine);
          }

          const indexToRemove: number = prognosisCostsItemGroupLines.indexOf(prognosisCostsItemGroupLine);

          prognosisCostsItemGroupLines.splice(indexToRemove, 1);
        }

        if (prognosisCostsItemLine.prognosis.id == null) {
          prognosisCostsItemLine.prognosis.id = updatedYearAmountAndNumbers[0].id;
        }

        prognosisCostsItemLine.prognosis.original = undefined;

        this.isEditing = false;
        prognosisCostsItemLine.prognosis.isEditing = false;
      }))
      .pipe(finalize(() => {
        this.isSubmitting = false;
        prognosisCostsItemLine.prognosis.isSubmitting = false;
      }))
      .pipe(catchError((apiError: ApiError) => {
        this._vilaMessageService
          .showError(apiError.message ?? this._toastBodyUpdatingPrognosisCostsLineErrorText);

        return of(undefined);
      }))
      .subscribe();
  }

  public onRowEditInit(prognosis: YearAmountAndNumberDto): void {
    this.isEditing = true;
    prognosis.isEditing = true;

    prognosis.original = { ...prognosis };
  }

  public showRowEditCancelButton(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.isEditing;
  }

  public showRowEditClearButton(prognosis: YearAmountAndNumberDto): boolean {
    return !prognosis.isEditing && !prognosis.isSubmitting;
  }

  public showRowEditConfirmButton(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.isEditing && !prognosis.isSubmitting;
  }

  public showRowEditInitButton(prognosis: YearAmountAndNumberDto): boolean {
    return !prognosis.isEditing;
  }

  public showRowEditSpinnerButton(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.isSubmitting;
  }
  //#endregion

  //#region Private methods
  public _getGroupTotalBudgetAmount(prognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto): number {
    return prognosisCostsItemGroupLine.prognosisCostsItemLines
      .reduce((accumulator, prognosisCostsItemLine) => accumulator + prognosisCostsItemLine.budget.amount, 0);
  }

  public _getGroupTotalPrognosisAmount(prognosisCostsItemGroupLine: PrognosisCostsItemGroupLineDto): number {
    return prognosisCostsItemGroupLine.prognosisCostsItemLines
      .reduce((accumulator, prognosisCostsLine) => accumulator + prognosisCostsLine.prognosis.amount, 0);
  }

  private _getSortedCostsItemGroupsAndLines(costsItemGroups: PrognosisCostsItemGroupLineDto[]): PrognosisCostsItemGroupLineDto[] {
    costsItemGroups
      .forEach(costsItemGroup => costsItemGroup.prognosisCostsItemLines = costsItemGroup.prognosisCostsItemLines
        .sort((costsItemA, costsItemB) => costsItemA.prognosis.name.localeCompare(costsItemB.prognosis.name)));

    return costsItemGroups.sort((costsItemGroupA, costsItemGroupB) => costsItemGroupA.id.localeCompare(costsItemGroupB.id));
  }

  private _isNewPrognosisCostsItemLine(prognosisCostsItemLine: PrognosisCostsItemLineDto): boolean {
    return prognosisCostsItemLine.budget.id == null && prognosisCostsItemLine.prognosis.id == null;
  }

  private _prognosisBaseValuesAreFilled(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.itemID != null
      && prognosis.amount > 0
      && prognosis.name != null
      && prognosis.name.length > 0;
  }

  private _prognosisHasUniqueName(prognosis: YearAmountAndNumberDto, prognosisCostsItemGroupLines: PrognosisCostsItemGroupLineDto[]): boolean {
    return prognosisCostsItemGroupLines
      .every(prognosisCostsItemGroupLine => prognosisCostsItemGroupLine.prognosisCostsItemLines
        .every(prognosisCostsItemLine => prognosisCostsItemLine.prognosis === prognosis
          || prognosisCostsItemLine.prognosis.name !== prognosis.name));
  }

  private _prognosisIsChanged(prognosis: YearAmountAndNumberDto): boolean {
    return prognosis.amount !== prognosis.original.amount
      || prognosis.remarks != prognosis.original.remarks
      || prognosis.name != prognosis.original.name
  }

  private _removeFromArray(itemToRemove: object, arrayToRemoveItemFrom: object[]): void {
    const indexToRemove: number = arrayToRemoveItemFrom.findIndex(item => item === itemToRemove);

    arrayToRemoveItemFrom.splice(indexToRemove, 1);
  }

  private _updateHeader(): void {
    const totalAmount: number = this.getPrognosisTotalAmount(this._prognosisCostsItemGroupLines);

    // Update the balance shown in the header and update the original.
    this.projectHeaderComponent.updateShownBalance(this._originalTotalYearBalance - totalAmount, true);
    this._originalTotalYearBalance = totalAmount;
  }
  //#endregion
}
