import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ManagerDto } from '../models/manager-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class ManagerService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _managerControllerUrl: string = "/api/Manager";
  //#endregion

  //#region Public methods
  public list$(): Observable<ManagerDto[]> {
    return super.catchErrorResponse(this._httpClient.get<ManagerDto[]>(this._managerControllerUrl));
  }
  //#endregion
}
