import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { QuarterAmountAndNumberDto } from '../models/quarter-amount-and-number-dto.model';
import { ApiServiceBase } from './base/api-service-base';

@Injectable({ providedIn: 'root' })
export class QuarterAmountAndNumberService extends ApiServiceBase {
  //#region Constructor
  constructor(
    private readonly _httpClient: HttpClient,
  ) {
    super();
  }
  //#endregion

  //#region Private properties
  private readonly _quarterAmountAndNumberControllerUrl: string = "/api/QuarterAmountAndNumber";
  //#endregion

  //#region Public methods
  public create(quarter: number, year: number): QuarterAmountAndNumberDto {
    return {
      quarter: quarter,
      year: year,
      itemID: undefined,
      amount: 0,
      number: 0,
      rateGroupID: undefined,
      employeeID: undefined,
      id: undefined,
      name: undefined,
      original: undefined,
      isEditing: undefined,
      isSubmitting: undefined,
      remarks: undefined
    }
  }

  public delete$(quarterAmountAndNumberID: string, quotationID: string): Observable<void> {
    const url: string = `${this._quarterAmountAndNumberControllerUrl}/quotation/${quotationID}/${quarterAmountAndNumberID}`;

    return super.catchErrorResponse(this._httpClient.delete<void>(url));
  }

  public upsert$(quarterAmountAndNumber: QuarterAmountAndNumberDto, quotationID: string): Observable<QuarterAmountAndNumberDto> {
    const url: string = `${this._quarterAmountAndNumberControllerUrl}/quotation/${quotationID}`;

    return super.catchErrorResponse(this._httpClient.post<QuarterAmountAndNumberDto>(url, quarterAmountAndNumber));
  }
  //#endregion
}
