import { Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationInsightsDto } from '../web-api/models/application-insights-dto.model';
import { ApplicationInsightsService } from '../web-api/services/application-insights.service';

@Injectable({ providedIn: "root" })
export class ApplicationInsightsSdkService {
  //#region Constructor
  constructor(private readonly _applicationInsightsService: ApplicationInsightsService) { }
  //#endregion

  //#region Private properties
  private _applicationInsights: ApplicationInsights;

  private _isInitialised: boolean;
  //#endregion

  //#region Public methods
  public initialise(): void {
    if (!this._isInitialised) {
      this.initApplicationInsights();

      this._isInitialised = true;
    }
  }

  public canLog(): boolean {
    return this._applicationInsights != null;
  }

  public logPageView(name?: string, url?: string): void {
    if (this.canLog()) {
      this._applicationInsights.trackPageView({
        name: name,
        uri: url
      });
    }
  }

  public logException(exception: Error, severityLevel?: number) {
    if (this.canLog()) {
      this._applicationInsights.trackException({ exception: exception, severityLevel: severityLevel });
    }
  }
  //#endregion

  //#region Private methods
  private initApplicationInsights(): void {
    const applicationInsights: ApplicationInsightsDto = this._applicationInsightsService.get();

    // Only create an ApplicationInsights instance if the required information is made available by the backend.
    if (applicationInsights != null) {
      this._applicationInsights = new ApplicationInsights({
        config: {
          enableAutoRouteTracking: true,
          instrumentationKey: applicationInsights.instrumentationKey
        }
      });

      // Need to load app insights first before continuing.
      this._applicationInsights.loadAppInsights();

      const userID: string = applicationInsights.userID;

      // Set authenticated user ID and account ID.
      this._applicationInsights.setAuthenticatedUserContext(userID, userID, false);

      this._applicationInsights.context.user.id = userID;

      // Store session ID as well so that frontend events (pageviews, mainly) are grouped together in the same session as the backend events.
      this._applicationInsights.context.session.id = applicationInsights.sessionID;
      this._applicationInsights.context.session.renewalDate = new Date().getTime();
    } else {
      this._applicationInsights = undefined;
    }
  }
  //#endregion
}
