import { Injectable } from '@angular/core';
import { PrognosisCostsItemGroupLineDto } from '../models/prognosis/prognosis-costs-item-group-line-dto.model';

@Injectable({ providedIn: 'root' })
export class PrognosisCostsItemGroupLineService {
  //#region Constructor
  constructor() { }
  //#endregion

  //#region Public methods
  public create(id: string): PrognosisCostsItemGroupLineDto {
    return {
      name: undefined,
      id: id,
      realisationAmount: 0,
      prognosisCostsItemLines: []
    };
  }
  //#endregion
}
